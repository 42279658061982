import { Loader } from "react-feather";
import { useState, useEffect } from "react";
import FancyButton from "./FancyButton";
import Inputs from "./Inputs";
import Input from "./Input";
import { useTranslation } from "react-i18next";
import Button from "./Button";

export default function Form({
  inputs = [],
  button,
  fancyButton = false,
  onSubmit,
  cancelButton = "",
  onCancel,
  className,
  grid,
  errors = [],
  heading,
  subheading,
  icon,
  iconConf,
  inputErrors = false,
  children,
  bottomLink,
  resetLoader = false,
  isButtonDisabled = false,
  variant = "",
  buttonTitle = "",
  buttonVariant = "primary_a",
}) {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  // Stop loading animation if errors are caught onSubmit
  useEffect(() => {
    if (errors.length > 0 || inputErrors || resetLoader) {
      setLoading(false);
    }
  }, [errors, inputErrors, resetLoader]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    onSubmit ? onSubmit() : alert("nothing to do");
  };

  const handleCancel = () => {
    onCancel ? onCancel() : alert("nothing to cancel");
  };

  return (
    <fieldset disabled={loading}>
      <form
        className={[
          "Form",
          className,
          !children ? "buttonOnlyForm" : "",
          grid ? "gridForm" : "",
          variant === "compact" ? "Form_compact" : "",
        ]
          .filter(Boolean)
          .join(" ")}
        required
        onSubmit={handleSubmit}
      >
        {errors.length > 0 ? <FormErrors errors={errors} /> : null}

        {/* inputs can be either children or props */}
        {children ? <div className="Form-inputs">{children}</div> : null}
        {inputs.length > 0 ? (
          <div className="Form-inputs">
            {inputs.map((inputGroup, index) => (
              <Inputs
                grid={inputGroup.display === "grid"}
                gridTemplateColumns={inputGroup.gridTemplateColumns}
                type={inputGroup.type}
                key={index}
              >
                {inputGroup.data.map((input, index) => (
                  <Input key={index} {...input} />
                ))}
              </Inputs>
            ))}
          </div>
        ) : null}

        <FormButton
          button={
            loading && variant !== "compact"
              ? t("common:loading")
              : onSubmit && !button
                ? t("common:form.submitButton")
                : button
          }
          fancyButton={fancyButton}
          heading={heading}
          subheading={subheading}
          icon={icon}
          iconConf={iconConf}
          onClick={handleSubmit}
          cancelButton={
            onCancel && !cancelButton
              ? t("common:form.cancelButton")
              : cancelButton
          }
          onCancel={handleCancel}
          bottomLink={bottomLink}
          isButtonDisabled={loading || isButtonDisabled}
          buttonTitle={buttonTitle}
          buttonVariant={buttonVariant}
        />
      </form>
    </fieldset>
  );
}

const FormErrors = ({ errors }) => {
  return (
    <ul className="Form-errors">
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  );
};

const FormButton = ({
  button,
  fancyButton,
  heading,
  subheading,
  icon,
  iconConf,
  onClick,
  bottomLink,
  cancelButton = "",
  onCancel,
  isButtonDisabled,
  buttonTitle,
  buttonVariant,
}) =>
  fancyButton ? (
    <FancyButton
      heading={heading}
      subheading={subheading}
      icon={icon}
      iconConf={iconConf}
      onClick={onClick}
    />
  ) : (
    <div className="Form-button-wrapper">
      <Button
        text={button}
        variant={buttonVariant}
        className="Form-button"
        type="submit"
        disabled={isButtonDisabled}
        title={buttonTitle}
      />
      {cancelButton ? (
        <Button
          text={cancelButton}
          variant="secondary_a"
          className="Form-button"
          onClick={onCancel}
          type="button"
          disabled={isButtonDisabled}
        />
      ) : null}
      {bottomLink ? <div className="Main-link">{bottomLink}</div> : null}
    </div>
  );

export const FormLoader = ({ size }) => {
  return (
    <div className="Form-button-loaderIcon rotate">
      <Loader size={size} />
    </div>
  );
};
