import airpme from "../../assets/partners/airpme.png";
import aria from "../../assets/partners/aria.png";
import avanseo from "../../assets/partners/avanseo.png";
import capec from "../../assets/partners/capec.jpg";
import defacto from "../../assets/partners/defacto.png";
import dimpl from "../../assets/partners/dimpl.png";
import edebex from "../../assets/partners/edebex.png";
import karmen from "../../assets/partners/karmen.png";
import memobank from "../../assets/partners/memobank.png";
import ocean from "../../assets/partners/ocean.png";
import riverbank from "../../assets/partners/riverbank.png";
import silvr from "../../assets/partners/silvr.png";
import youtrade from "../../assets/partners/youtrade.png";

export default function usePartners() {
  return [
    {
      id: "AirPMEClients",
      name: "AirPME",
      logo: airpme,
    },
    {
      id: "AirPMEVendors",
      name: "AirPME",
      logo: airpme,
    },
    {
      id: "Aria",
      name: "Aria",
      logo: aria,
    },
    {
      id: "Avanseo",
      name: "Avanseo",
      logo: avanseo,
    },
    {
      id: "CapEc",
      name: "CapEc",
      logo: capec,
    },
    {
      id: "Defacto",
      name: "Defacto",
      logo: defacto,
    },
    {
      id: "Dimpl",
      name: "Dimpl",
      logo: dimpl,
    },
    {
      id: "Edebex",
      name: "Edebex",
      logo: edebex,
    },
    {
      id: "Karmen",
      name: "Karmen",
      logo: karmen,
    },
    {
      id: "KarmenFactor",
      name: "Karmen Factor",
      logo: karmen,
    },
    {
      id: "MemoBank",
      name: "MemoBank",
      logo: memobank,
    },
    {
      id: "Ocean",
      name: "Ocean",
      logo: ocean,
    },
    {
      id: "Riverbank",
      name: "Riverbank",
      logo: riverbank,
    },
    {
      id: "RiverbankInstant",
      name: "Riverbank Instant",
      logo: riverbank,
    },
    {
      id: "Silvr",
      name: "Silvr",
      logo: silvr,
    },
    {
      id: "YouTrade",
      name: "YouTrade",
      logo: youtrade,
    },
  ];
}
