import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Content,
  Header,
  Heading,
  Main,
  SubHeading,
} from "../commons/Template";
import Form from "../commons/Form";
import Input from "../commons/Input";
import authAPI, { mapAuthErrors } from "../../api/authentication";
import routes from "../../routes/routes";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";

export default function PasswordResetForm() {
  const { t } = useTranslation(["common", "account"]);
  document.title = t("account:passwordResetForm.title");
  const navigate = useNavigate();
  const dispatchToast = useToastsDispatch();

  const { key } = useParams();
  const [isKeyValid, setKeyValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErrors, setNewPasswordErrors] = useState([]);

  useEffect(() => {
    const verifyKey = async (key) => {
      const response = await authAPI.verifyResetKey(key);
      if (response.ok) {
        setKeyValid(true);
      }
      setLoading(false);
    };
    verifyKey(key);
  }, [key]);

  const passwordInput = {
    label: t("account:passwordResetForm.validKey.form.password"),
    name: "password",
    type: "password",
    value: newPassword,
    autoComplete: "new-password",
    onChange: setNewPassword,
    errors: newPasswordErrors,
  };

  const handlePasswordReset = async () => {
    setNewPasswordErrors([]);

    const response = await authAPI.resetPassword(key, newPassword);

    if (response.status === 400) {
      const data = await response.json();
      const errorsObject = mapAuthErrors(data.errors);
      setNewPasswordErrors(errorsObject.password);
    } else {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("account:passwordResetForm.message.title"),
        subheading: t("account:passwordResetForm.message.content"),
      });
      navigate(routes.Account.Login);
    }
  };

  if (loading) {
    return <></>;
  }
  return (
    <Main>
      <Header>
        <Heading>
          {isKeyValid ? (
            <Trans
              t={t}
              i18nKey="account:passwordResetForm.validKey.heading"
              components={{
                emphasis: <span />,
              }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="account:passwordResetForm.unvalidKey.heading"
              components={{
                emphasis: <span />,
              }}
            />
          )}
        </Heading>
        <SubHeading>
          {isKeyValid ? null : (
            <span>
              <Trans
                t={t}
                i18nKey="account:passwordResetForm.unvalidKey.subheading"
                components={{
                  anchor: <Link to={routes.Account.PasswordReset} />,
                }}
              />
            </span>
          )}
        </SubHeading>
      </Header>
      <Content>
        {isKeyValid ? (
          <Form
            button={t("account:passwordResetForm.validKey.form.button")}
            onSubmit={handlePasswordReset}
            inputErrors={newPasswordErrors.length > 0}
          >
            <Input {...passwordInput} />
          </Form>
        ) : null}
      </Content>
    </Main>
  );
}
