import { Outlet } from "react-router";
import { Header, Main } from "../commons/Template";
import { useTranslation } from "react-i18next";
import routes from "../../routes/routes";
import useRootRedirect from "../../hooks/commons/useRootRedirect";

export default function Vault() {
  const { t } = useTranslation("common", "navigation", "vault");

  const tabKey = "vault";

  const header = {
    heading: t(`navigation:tabs.${tabKey}.title`),
    subheading: t(`navigation:tabs.${tabKey}.subheading`),
  };

  const tabs = [
    {
      title: t("vault:tabs.bank.title"),
      to: routes.Vault.bank,
    },
    {
      title: t("vault:tabs.finance.title"),
      to: routes.Vault.finance,
    },
    {
      title: t("vault:tabs.id.title"),
      to: routes.Vault.id,
    },
    {
      title: t("vault:tabs.other.title"),
      to: routes.Vault.other,
    },
  ];

  useRootRedirect(routes.Vault.root, routes.Vault.bank);

  return (
    <Main themeSwitch>
      <Header {...header} tabs={tabs} />
      <Outlet />
    </Main>
  );
}

export function VaultWrapper({ children, flex }) {
  const assignClassNames = () => {
    let classNames = ["VaultWrapper"];
    if (flex) {
      classNames = [...classNames, "flex"];
    }
    return classNames.join(" ");
  };
  return <div className={assignClassNames()}>{children}</div>;
}
