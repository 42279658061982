// import { useState } from "react";
// import api from "../../../api/resources";
// import Form from "../../commons/Form";
import { Setting, SettingDisplay, SettingLocker } from "../Settings";
import { useTranslation } from "react-i18next";

export default function CategorySet({ country, value }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t(`settings:items.company.category.${country}`);

  return (
    <Setting>
      <SettingDisplay label={label} value={value} />
      <SettingLocker />
    </Setting>
  );
}
