import { createContext, useContext, useEffect, useState } from "react";
import api from "../api/resources";
import authAPI from "../api/authentication";
import useUserActivity from "../hooks/commons/useUserActivity";

const autoLogoutTime = 5 * 60 * 1000; // 5 minutes in ms

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [logged, setLogged] = useState(false); // user log state
  const [loadingLogged, setLoadingLogged] = useState(true);
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [activeCompany, setActiveCompany] = useState(null);
  const [loadingActiveCompany, setLoadingActiveCompany] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [reseller, setReseller] = useState(null);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    const checkLogged = async () => {
      setLoadingLogged(true);
      const user = await authAPI.getUserInfo();
      setLogged(user.meta.is_authenticated);
      setLoadingLogged(false);
      setUser(user.data.user);
      setLoadingUser(false);
    };
    checkLogged();
  }, []);

  useEffect(() => {
    const getActiveCompany = async () => {
      const companyResponse = await api.getActiveCompany();
      if (companyResponse.ok) {
        let loadedCompany = await companyResponse.json();
        // API sends empty array if company is empty
        if (Array.isArray(loadedCompany) && loadedCompany.length === 0) {
          setActiveCompany(null);
        } else {
          setActiveCompany(loadedCompany);
        }

        setLoadingActiveCompany(false);
      }
    };
    if (logged) getActiveCompany();
  }, [logged]);

  const getNotifications = async () => {
    setLoadingNotifications(true);
    const response = await api.getNotifications();
    if (response.ok) {
      const notifications = await response.json();
      setUnreadNotifications(
        notifications.filter((notification) => !notification.read).length,
      );
      setLoadingNotifications(false);
    }
  };

  useEffect(() => {
    if (logged) getNotifications();
  }, [logged]);

  useEffect(() => {
    const getCompanies = async () => {
      const companiesRes = await api.listCompanies();
      if (companiesRes.ok) {
        let loadedCompanies = await companiesRes.json();
        setCompanies(loadedCompanies);
        if (
          activeCompany.reseller &&
          loadedCompanies.some(
            (company) => company.name === activeCompany.reseller.name,
          )
        ) {
          setReseller(activeCompany.reseller);
        }
        setLoadingCompanies(false);
      }
    };
    if (logged && activeCompany) getCompanies();
  }, [logged, activeCompany]);

  // Handle automatic log-out after 5 minutes of inactivity
  const active = useUserActivity();
  useEffect(() => {
    // If inactive and logged, log out after 5 minutes
    let timer;
    if (!active && logged && process.env.REACT_APP_ENV === "production") {
      timer = setTimeout(() => {
        handleLogout();
      }, autoLogoutTime);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [active, logged]);

  // Handle user log-in
  const handleLogin = async (loginCredentials) => {
    const response = await authAPI.signIn(loginCredentials);
    // `Headless` `AllAuth` returns 409 if user has already been logged in automatically
    if (response.status === 200 || response.status === 409) {
      setLogged(true);
    }
    return response;
  };

  // Handle user log-out
  const handleLogout = async () => {
    const response = await authAPI.signOut();
    if (response.ok) {
      setLogged(false);
    }
    return response;
  };

  const value = {
    loadingLogged,
    logged,
    onLogin: handleLogin,
    onLogout: handleLogout,
    user: user,
    loadingUser: loadingUser,
    loadingActiveCompany,
    activeCompany,
    companies,
    loadingCompanies,
    reseller,
    unreadNotifications: unreadNotifications,
    onNotificationRead: getNotifications,
    loadingNotifications: loadingNotifications,
    allowed: allowed,
    onAllow: setAllowed,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
