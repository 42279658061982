import { Close } from "@mui/icons-material";
import Button from "./Button";

export function DrawerHeader({ title, subtitle, onClose }) {
  return (
    <div className="PartnersDrawer-header">
      <div className="PartnersDrawer-header-title">
        {title && (
          <span className="PartnersDrawer-header-title-text">{title}</span>
        )}
        <div style={!title ? { marginLeft: "auto" } : {}}>
          <Button
            className="PartnersDrawer-header-title-button"
            icon={<Close />}
            onClick={onClose}
          />
        </div>
      </div>
      {subtitle && (
        <div className="PartnersDrawer-header-subtitle">{subtitle}</div>
      )}
    </div>
  );
}

export function DrawerContent({ children }) {
  return <div>{children}</div>;
}
