import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

export default function Tabs({
  tabs = [
    {
      id: "id1",
      title: "Banking",
      to: "/vault/banking",
      hidden: false,
    },
    {
      id: "id2",
      title: "Finance",
      to: "/vault/finance",
      hidden: false,
    },
  ],
  selectedID = "",
  onSelect,
  smallTabs = false,
}) {
  return (
    <div
      className={["Tabs", smallTabs ? "smallTabs" : ""]
        .filter(Boolean)
        .join(" ")}
    >
      {tabs.map(
        (tab, index) =>
          !tab.hidden && (
            <Tab
              key={index}
              {...tab}
              onSelect={onSelect}
              selectedID={selectedID}
            />
          ),
      )}
    </div>
  );
}

function Tab({
  id = "",
  title = "",
  to = "",
  selectedID,
  onSelect,
  initScroll = false,
}) {
  const divRef = useRef(0);
  const navRef = useRef(0);

  useEffect(() => {
    if (initScroll) {
      if (!to && selectedID === id) {
        divRef.current.scrollIntoView();
      }

      if (to) {
        if (navRef.current.children[0].className.includes("active")) {
          navRef.current.children[0].scrollIntoView({ block: "nearest" });
        }
      }
    }
  }, []);

  if (to)
    return (
      <div ref={navRef}>
        <NavLink className={"Tab"} to={to}>
          {title}
        </NavLink>
      </div>
    );
  return (
    <div
      ref={divRef}
      onClick={() => onSelect(id)}
      className={["Tab", selectedID === id ? "active" : ""]
        .filter(Boolean)
        .join(" ")}
    >
      {title}
    </div>
  );
}
