import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LockPerson } from "@mui/icons-material";
import Form from "../../commons/Form";
import { Setting, SettingDisplay, SettingModder } from "../Settings";
import authAPI from "../../../api/authentication";
import { mapAuthErrors } from "../../../api/authentication";
import routes from "../../../routes/routes";
import { useModalDispatch } from "../../commons/Modal/ModalContext";
import { useToastsDispatch } from "../../commons/Toasts/ToastsContext";

export default function PasswordSet() {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.password.label");

  const modal = {
    component: <PasswordForm />,
  };

  return (
    <Setting>
      <SettingDisplay label={label} value={"*****"} />
      <SettingModder icon={<LockPerson />} action={"modify"} modal={modal} />
    </Setting>
  );
}

function PasswordForm() {
  const { t } = useTranslation(["common", "settings", "account"]);
  const dispatchModal = useModalDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorsObj, setErrorsObj] = useState({});
  const dispatchToast = useToastsDispatch();

  const handlePasswordChange = async () => {
    setErrorsObj({});

    const response = await authAPI.changePassword(oldPassword, newPassword);

    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("settings:tabs.password.message.title"),
        subheading: t("settings:tabs.password.message.content"),
      });
      setNewPassword("");
      setOldPassword("");
      dispatchModal({ type: "success" });
    } else {
      const data = await response.json();
      const errorsObject = mapAuthErrors(data.errors);
      console.log(errorsObject);
      setErrorsObj(errorsObject);
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const passwordInputs = [
    {
      label: t("settings:tabs.password.form.oldPassword"),
      name: "oldPassword",
      autoComplete: "current-password",
      type: "password",
      value: oldPassword,
      onChange: setOldPassword,
      errors: getErrors("current_password"),
    },
    {
      label: t("settings:tabs.password.form.newPassword"),
      name: "newPassword",
      autoComplete: "new-password",
      type: "password",
      value: newPassword,
      onChange: setNewPassword,
      errors: getErrors("new_password"),
    },
  ];

  return (
    <Form
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handlePasswordChange}
      inputs={[{ data: passwordInputs }]}
      errors={getErrors("__all__")}
      bottomLink={
        <Link
          to={routes.Account.PasswordReset}
          onClick={() => {
            dispatchModal({ type: "success" });
          }}
        >
          {t("account:login.active.forgotPassword")}
        </Link>
      }
    />
  );
}
