import { useLocation, useNavigate } from "react-router-dom";
import {
  Content,
  Header,
  Heading,
  Main,
  SubHeading,
} from "../commons/Template";
import { Trans, useTranslation } from "react-i18next";
import { Pill, ProgressBar } from "./Assessment";
import { useEffect, useState } from "react";
import Button from "../commons/Button";
import {
  CheckCircleRounded,
  DocumentScanner,
  FileCopy,
} from "@mui/icons-material";
import routes from "../../routes/routes";
import api from "../../api/resources";
import Form, { FormLoader } from "../commons/Form";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { i18n } from "../../i18n";
import { useAuth } from "../../auth/AuthContext";
import { capitalize } from "../../utils";
import CreditLine from "./CreditLine";
import { useHelperDispatch } from "../commons/Helper/HelperContext";

export default function Unlock() {
  // Flow
  const location = useLocation();
  let need = "";
  if (location.state) {
    need = location.state.need;
  }
  const navigate = useNavigate();
  const goBack = () => {
    navigate(routes.Financing.assess);
  };
  useEffect(() => {
    if (!need) {
      goBack();
    }
  }, []);
  const goForward = (usecase) => {
    const target = routes.Financing.request;
    const state = {
      need: need,
      usecase: usecase,
    };
    navigate(target, { state: state });
  };
  // End flow

  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:unlock.title");

  const [loading, setLoading] = useState(true);
  const { activeCompany, loadingActiveCompany } = useAuth();

  const [selectedUsecase, setSelectedUsecase] = useState("");

  const [usecases, setUsecases] = useState(null);
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();
  const dispatchHelper = useHelperDispatch();

  // delete potential previous helper
  useEffect(() => {
    dispatchHelper({ type: "delete" });
  }, []);

  const initUsecases = (
    jsonUsecases = {
      usecaseA: {
        status: "unavailable",
        reasons: ["activity"],
        actions: {},
        providers: [],
        credit_line: null,
      },
      usecaseB: {
        status: "available",
        reasons: [],
        actions: {
          add_representant_email: true,
          synchronise_accounts: false,
        },
        providers: [],
        credit_line: null,
      },
      usecaseC: {
        status: "register",
        reasons: [],
        actions: {
          add_representant_email: true,
          synchronise_accounts: true,
        },
        providers: ["Defacto"],
        credit_line: null,
      },
      usecaseD: {
        status: "active",
        reasons: ["activity"],
        actions: {
          add_representant_email: true,
          synchronise_accounts: true,
        },
        providers: ["Defacto"],
        credit_line: {
          max_amount: 142000,
          available_amount: 20000,
        },
      },
    },
  ) => {
    const newUsecases = Object.entries(jsonUsecases).map(
      ([usecaseID, usecaseContent]) => {
        const status = usecaseContent.status;
        const reasons = usecaseContent.reasons;
        const providers = usecaseContent.providers;
        const creditLine = usecaseContent.credit_line;

        let actions = [];

        // ----- Actions -----
        const hasActions =
          usecaseContent.actions &&
          Object.keys(usecaseContent.actions).length > 0;
        if (hasActions) {
          actions = Object.entries(usecaseContent.actions).map(
            ([actionID, actionDone]) => ({ id: actionID, done: actionDone }),
          );
        }

        const initActionButton = (actionID) => {
          const onClickFn = {
            add_representant_email: () => navigate(routes.Company.reps),
            synchronise_accounts: () => navigate(routes.Vault.bank),
            import_statement: () => navigate(routes.Vault.finance),
            fill_banking_data_6: () => navigate(routes.Vault.bank),
            fill_banking_data_12: () => navigate(routes.Vault.bank),
          };

          const handleActionWithHelper = (actionID) => {
            const actionHelper = {
              heading: t("financing:unlock.actions.helper.heading"),
              subheading: t("financing:unlock.actions.helper.subheading"),
              button: t("financing:unlock.actions.helper.button"),
              navigate: {
                target: routes.Financing.unlock,
                state: {
                  need: need,
                },
              },
            };

            onClickFn[actionID]();
            dispatchHelper({
              type: "add",
              expand: true,
              ...actionHelper,
            });
          };

          return {
            onClick: () => handleActionWithHelper(actionID),
            text: t(`financing:unlock.actions.ids.${actionID}.button`),
          };
        };

        const initActions = (
          actions = [
            { id: "actionID1", done: true },
            { id: "actionID2", done: false },
          ],
        ) =>
          actions.map((action) => ({
            id: action.id,
            icon: <FileCopy />,
            title: t(`financing:unlock.actions.ids.${action.id}.title`),
            subtitle: t(`financing:unlock.actions.ids.${action.id}.subtitle`),
            button: action.done ? null : initActionButton(action.id),
            buttonAlt: action.done,
          }));

        // ---- Use cases ---- //

        const initUsecaseElements = (usecaseID, status) => {
          const pill = (color, text) => ({
            color: color,
            text: text,
          });

          const moreInfoButton = () => {
            const moreInfoModalNote = reasons && (
              <ul style={{ padding: "revert", margin: "0" }}>
                {reasons.map((reasonID, index) => (
                  <li style={{ listStyle: "revert" }} key={index}>
                    {capitalize(
                      t(
                        `financing:unlock.usecases.modals.moreInfo.note.reasons.${reasonID}`,
                      ),
                    )}
                  </li>
                ))}
              </ul>
            );

            const moreInfoModal = {
              title: t("financing:unlock.usecases.modals.moreInfo.title"),
              message: t("financing:unlock.usecases.modals.moreInfo.message"),
              note: moreInfoModalNote,
              size: "medium",
            };

            return {
              text: t("financing:unlock:usecases.buttons.moreInfo"),
              onClick: () =>
                dispatchModal({
                  type: "add",
                  ...moreInfoModal,
                }),
            };
          };

          const viewActionsButton = (usecaseID) => ({
            text: t("financing:unlock.usecases.buttons.unlock"),
            onClick: () => setSelectedUsecase(usecaseID),
          });

          const requestFinancingButton = (usecaseID) => ({
            text: t("financing:unlock.usecases.buttons.request"),
            onClick: () => goForward(usecaseID),
          });

          const registerButton = () => {
            const handleRegistration = async () => {
              let responseError = null;
              for (const provider of providers) {
                const response = await api.registerBorrower(need, provider);
                if (!response.ok) {
                  const jsonResponse = await response.json();
                  responseError = response.status;
                  dispatchModal({ type: "success" });
                  switch (responseError) {
                    case 502:
                      dispatchToast({
                        type: "add",
                        variant: "info",
                        subheading: t(
                          "financing:unlock.usecases.modals.register.toasts.partner_busy",
                        ),
                      });
                      break;

                    case 400:
                      for (const error of jsonResponse.errors) {
                        const knownErrors = [
                          "IBAN_invalid",
                          "representants_missing",
                        ];
                        if (
                          knownErrors.some((knownError) => knownError === error)
                        ) {
                          dispatchToast({
                            type: "add",
                            variant: "error",
                            heading: t(
                              "financing:unlock.usecases.modals.register.toasts.heading.error",
                            ),
                            subheading: t(
                              `financing:unlock.usecases.modals.register.toasts.${error}`,
                            ),
                          });
                        } else {
                          const field = error.split("_")[0];
                          const reason = error.split("_")[1];

                          dispatchToast({
                            type: "add",
                            variant: "error",
                            subheading: (
                              <Trans
                                t={t}
                                i18nKey={
                                  "financing:unlock.usecases.modals.register.toasts.field_reason"
                                }
                                values={{ field: field, reason: reason }}
                              />
                            ),
                          });
                        }
                      }
                      break;
                    default:
                      console.log(responseError);
                      dispatchToast({
                        type: "add",
                        variant: "info",
                        subheading: t("common:toasts.errorToast.subheading"),
                      });
                  }
                }
              }
              dispatchModal({ type: "success" });
              !responseError &&
                dispatchToast({
                  type: "add",
                  variant: "success",
                  subheading: t(
                    `financing:unlock.usecases.modals.register.toasts.${"success"}`,
                  ),
                });
            };

            const defactoLinks = {
              en: "https://cdn.prod.website-files.com/65186d27d0dcf0d8e7a48388/669f9ac1f9f5f07cc3d578f5_Terms%20of%20service%20-%20EN%20-%20202407.docx.pdf",
              fr: "https://cdn.prod.website-files.com/65186d27d0dcf0d8e7a48388/669f9ac1f9f5f07cc3d578f5_Terms%20of%20service%20-%20EN%20-%20202407.docx.pdf",
              it: "https://cdn.prod.website-files.com/65186d27d0dcf0d8e7a48388/669f9ac1f9f5f07cc3d578f5_Terms%20of%20service%20-%20EN%20-%20202407.docx.pdf",
            };

            // Defacto only for now
            const message = (
              <Trans
                t={t}
                i18nKey={"financing:unlock.usecases.modals.register.message"}
                components={{
                  // eslint-disable-next-line
                  anchor: <a href={defactoLinks[i18n.resolvedLanguage]} />,
                }}
              />
            );

            const registerModal = {
              title: t("financing:unlock.usecases.modals.register.title"),
              message: message,
              component: (
                <Form
                  onSubmit={handleRegistration}
                  button={t(
                    "financing:unlock.usecases.modals.register.component.button",
                  )}
                />
              ),
              size: "medium",
            };

            return {
              text: t("financing:unlock.usecases.buttons.register"),
              onClick: () => dispatchModal({ type: "add", ...registerModal }),
            };
          };

          const variants = {
            unavailable: {
              pill: pill("red", capitalize(t("common:unavailable"))),
              button: moreInfoButton(),
            },
            available: {
              pill: pill("yellow", capitalize(t("common:toUnlock"))),
              button: viewActionsButton(usecaseID),
            },
            register: {
              pill: pill("yellow", capitalize(t("common:toUnlock"))),
              button: registerButton(),
            },
            active: {
              pill: pill("green", capitalize(t("common:available"))),
              button: requestFinancingButton(usecaseID),
              component: creditLine && (
                <CreditLine
                  available={creditLine.available_amount}
                  max={creditLine.max_amount}
                  currency={activeCompany.currency}
                />
              ),
            },
          };

          return variants[status];
        };

        return {
          id: usecaseID,
          icon: <DocumentScanner />,
          title: t(`financing:unlock.usecases.ids.${usecaseID}.title`),
          subtitle: t(`financing:unlock.usecases.ids.${usecaseID}.subtitle`),
          ...initUsecaseElements(usecaseID, status),
          actions: initActions(actions),
        };
      },
    );

    setUsecases(newUsecases);
  };

  const init = async () => {
    const response = await api.getUsecases(need);
    if (response.ok) {
      const jsonUsecases = await response.json();
      const testUsecases = false;
      testUsecases ? initUsecases() : initUsecases(jsonUsecases);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (!selectedUsecase)
    return (
      <Usecases
        need={need}
        usecases={usecases}
        onBack={goBack}
        loading={loading || loadingActiveCompany}
      />
    );

  const useCase = usecases.filter(
    (usecase) => usecase.id === selectedUsecase,
  )[0];
  return (
    <Actions
      id={useCase.id}
      actions={useCase.actions}
      onBack={() => setSelectedUsecase("")}
      loading={loading || loadingActiveCompany}
    />
  );
}

function Usecases({ need, usecases, onBack, loading }) {
  const { t } = useTranslation(["common", "financing"]);

  return (
    usecases && (
      <Main themeSwitch>
        <Header
          onBackButtonClick={onBack}
          heading={t(`financing:assessment.results.keys.${need}.name`)}
          subheading={t("financing:unlock.usecases.subheading")}
        />
        <Content>
          {loading ? <FormLoader /> : <Cards cards={usecases} />}
        </Content>
      </Main>
    )
  );
}

function Actions({ id, actions, onBack, loading }) {
  const { t } = useTranslation(["common", "financing"]);

  return (
    actions && (
      <Main themeSwitch>
        <Header>
          <Heading onBackButtonClick={onBack}>
            {t(`financing:unlock.usecases.ids.${id}.title`)}
          </Heading>
          <SubHeading>{t("financing:unlock.actions.subheading")}</SubHeading>
        </Header>
        <Content>
          {loading ? (
            <FormLoader />
          ) : (
            <div className="Actions">
              <div className="Actions-header">
                <div className="Actions-header-text">
                  <div className="Actions-header-text-heading">
                    {t("financing:unlock.actions.counter.heading")}
                  </div>
                  <div className="Actions-header-text-subheading">
                    {t("financing:unlock.actions.counter.subheading")}
                  </div>
                </div>
                <div className="Actions-header-progress">
                  <ProgressBar
                    status
                    length={actions.length}
                    index={actions.filter((action) => !action.button).length}
                  />
                </div>
              </div>
              <Cards cards={actions} />
            </div>
          )}
        </Content>
      </Main>
    )
  );
}

function Cards({ cards }) {
  return (
    <div className="Cards">
      {cards.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </div>
  );
}

function Card({
  icon,
  title,
  pill,
  subtitle,
  button,
  buttonAlt = false,
  component,
}) {
  return (
    <div className="Card">
      <div className="Card-icon">{icon}</div>

      <div className="Card-container">
        <div className="Card-container-description">
          <div className="Card-container-description-title">
            <div className="Card-container-description-title-text">{title}</div>
            <div className="Card-container-description-title-pill">
              {pill && <Pill color={pill.color} text={pill.text} />}
            </div>
          </div>
          <p className="Card-container-description-subtitle">{subtitle}</p>
          {component && (
            <div className="Card-container-description-component">
              {component}
            </div>
          )}
        </div>
        <div className="Card-container-cta">
          {button ? (
            <Button
              className="Card-container-cta-button"
              variant={"primary_a"}
              text={button.text}
              onClick={button.onClick}
            />
          ) : buttonAlt ? (
            <CheckCircleRounded className="Card-container-cta-check" />
          ) : null}
        </div>
      </div>
    </div>
  );
}
