import { useEffect } from "react";
import { AlertTriangle, Check, Info, X } from "react-feather";
import { useToastsDispatch } from "./ToastsContext";

export default function Toast({ toast }) {
  const dispatchToast = useToastsDispatch();

  // Delete toast after 5 s if it's not an error one
  useEffect(() => {
    if (toast.variant !== "error") {
      const timerID = setTimeout(() => {
        dispatchToast({ type: "delete", id: toast.id });
      }, 5 * 1000);
      return () => clearTimeout(timerID);
    }
  }, [toast, dispatchToast]);

  const variantToClassName = (variant) => `Toast-${variant}`;

  const variantToIcon = (variant) => {
    switch (variant) {
      case "warning":
        return <AlertTriangle size={35} />;
      case "error":
        return <AlertTriangle size={35} />;
      case "info":
        return <Info size={35} />;
      case "success":
        return <Check size={35} />;
      default:
        return <Check size={35} />;
    }
  };

  return (
    <div
      key={toast.id}
      className={`Toast ${variantToClassName(toast.variant)}`}
    >
      <div className="Toast-icon">{variantToIcon(toast.variant)}</div>
      <div className="Toast-text">
        {toast.heading && (
          <div>
            <p className="Toast-text-heading">{toast.heading}</p>
          </div>
        )}
        {toast.subheading && (
          <div>
            <p className="Toast-text-subheading">{toast.subheading}</p>
          </div>
        )}
      </div>
      <div
        className="Toast-clearButton"
        onClick={() => dispatchToast({ type: "delete", id: toast.id })}
      >
        <X />
      </div>
    </div>
  );
}
