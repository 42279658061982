import { useTranslation } from "react-i18next";
import { Content, Header, Main } from "../commons/Template";
import { Fragment, useCallback, useEffect, useState } from "react";
import Form, { FormLoader } from "../commons/Form";
import api from "../../api/resources";
import { ArrowLeft, ArrowRight, Briefcase } from "react-feather";
import { formatDateTime } from "../../format";
import Button from "../commons/Button";
import {
  AccountBalance,
  Archive,
  BarChart,
  Calculate,
  Cancel,
  CheckCircleRounded,
  CreditScore,
  DeleteForeverOutlined,
  Edit,
  HourglassTop,
  NoAccounts,
  ShoppingBag,
} from "@mui/icons-material";
import Input from "../commons/Input";
import { Badge, Tooltip, tooltipClasses } from "@mui/material";
import EmptySection from "../commons/EmptySection";
import routes, { dashboardRoute } from "../../routes/routes";
import { useNavigate } from "react-router-dom";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { i18n } from "../../i18n";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import Address from "../commons/Address";
import Tabs from "../commons/Tabs";

export default function Clients() {
  const { t } = useTranslation(["common", "reseller"]);
  document.title = t("reseller:clients.title");
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState("");
  const [searchSuccess, setSearchSuccess] = useState(false);
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(true);

  const init = useCallback(async () => {
    setSearchKey("");
    const response = await api.getClients();
    if (response.ok) {
      const clients = await response.json();
      setClients(
        clients.map((client) => ({
          ...client,
        })),
      );
      setLoadingClients(false);
    }
  }, []);

  const handleArchive = useCallback(
    async (clientID) => {
      const nextArchivedState = !clients.filter(
        (client) => client.id === clientID,
      )[0].archived;
      const response = await api.updateClient(clientID, {
        id: clientID,
        archived: nextArchivedState,
      });
      if (response.ok) {
        init();
      }
    },
    [clients, init],
  );

  useEffect(() => {
    init();
  }, [init]);

  const handleSearch = async () => {
    const response = await api.searchCompanies({ key: searchKey });
    if (response.ok) {
      setSearchSuccess(true);
      const companies = await response.json();
      if (companies.length === 0) {
        dispatchToast({
          type: "add",
          variant: "warning",
          heading: t("reseller:clients.emptySearchToast.heading"),
        });
      } else if (companies.length === 1) {
        const clientAddModal = {
          title: t("reseller:clients.addModal.title"),
          component: <ClientAddModal client={companies[0]} />,
          size: "medium",
          onSuccess: () => init(),
        };
        dispatchModal({ type: "add", ...clientAddModal });
      }
    }
  };

  const addNoteModal = (clientID, reseller_note) => {
    const modal = {
      title: t("reseller:clients.noteModal.title"),
      message: t("reseller:clients.noteModal.message"),
      component: (
        <ClientNoteModal clientID={clientID} reseller_note={reseller_note} />
      ),
      size: "medium",
      onSuccess: () => init(),
    };
    dispatchModal({ type: "add", ...modal });
  };

  const sendInvitationModal = (clientID) => {
    const modal = {
      title: t("reseller:clients.inviteModal.title"),
      message: t("reseller:clients.inviteModal.message"),
      component: (
        <ClientInviteModal
          clientID={clientID}
          onInstantInviteSuccess={() => handleSelectTab("managed")}
        />
      ),
      onSuccess: () => init(),
    };
    dispatchModal({ type: "add", ...modal });
  };

  const deleteClientModal = (clientID) => {
    const modal = {
      title: t("reseller:clients.deleteModal.title"),
      message: t("reseller:clients.deleteModal.message"),
      component: <ClientDeleteModal clientID={clientID} />,
      onSuccess: () => init(),
    };
    dispatchModal({ type: "add", ...modal });
  };

  const handleManage = async (clientID) => {
    const response = await api.manageClient(clientID);
    if (response.ok) {
      window.location.href = dashboardRoute; // not using useNavigate because needs page refresh
    } else {
      dispatchToast({
        type: "add",
        variant: "warning",
        heading: t("reseller:clients.failedManageToast"),
      });
    }
  };

  const handleAssessment = (clientID) => {
    navigate(`${routes.Reseller.assessment}/${clientID}`);
  };

  const clientSearchForm = {
    searchKey: searchKey,
    onSearchKeyChange: setSearchKey,
    searchSuccess: searchSuccess,
    onSearch: handleSearch,
  };

  const clientListCommonProps = {
    onDelete: deleteClientModal,
    onArchive: handleArchive,
    onInvite: sendInvitationModal,
    onNote: addNoteModal,
    onManage: handleManage,
    onAssess: handleAssessment,
  };

  const clientLists = [
    {
      type: "potential",
      ...clientListCommonProps,
      clients: clients.filter((client) => !client.archived && !client.admin),
    },
    {
      type: "managed",
      ...clientListCommonProps,
      clients: clients.filter((client) => !client.archived && client.admin),
    },
    {
      type: "archived",
      ...clientListCommonProps,
      clients: clients.filter((client) => client.archived),
    },
  ];

  const clientTabs = [
    {
      title: t("reseller:clients.tabs.potential"),
      type: "potential",
    },
    {
      title: t("reseller:clients.tabs.managed"),
      type: "managed",
    },
    {
      title: t("reseller:clients.tabs.archived"),
      type: "archived",
    },
  ];

  const [selectedClientTab, setSelectedClientTab] = useState("managed");

  const handleSelectTab = (type) => setSelectedClientTab(type);

  if (loadingClients) return <FormLoader />;

  return (
    <Main themeSwitch>
      <Header
        heading={t("reseller:clients.title")}
        subheading={t("reseller:clients.subheading")}
      />
      <Content className="Clients">
        <ClientSearchForm {...clientSearchForm} />
        <ClientTabs
          clientTabs={clientTabs}
          selectedClientTab={selectedClientTab}
          onSelect={handleSelectTab}
        />
        <ClientList
          {...clientLists.filter(
            (clientList) =>
              clientList.type ===
              clientTabs.filter(
                (clientTab) => clientTab.type === selectedClientTab,
              )[0].type,
          )[0]}
        />
      </Content>
    </Main>
  );
}

function ClientTabs({ clientTabs, selectedClientTab, onSelect }) {
  return (
    <Tabs
      tabs={clientTabs.map((clientTab) => ({
        id: clientTab.type,
        title: clientTab.title,
      }))}
      selectedID={selectedClientTab}
      onSelect={onSelect}
    />
  );
}

function ClientList({
  type,
  clients,
  onArchive,
  onDelete,
  onInvite,
  onNote,
  onManage,
  onAssess,
}) {
  const [filterTerm, setFilterTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const { t } = useTranslation(["common", "reseller"]);

  const filterByText = (client) =>
    client.name.toLowerCase().includes(filterTerm.toLowerCase()) ||
    client.code.includes(filterTerm) ||
    (client.reseller_note &&
      client.reseller_note.toLowerCase().includes(filterTerm.toLowerCase()));

  const filterByOptions =
    filterOptions.length > 0
      ? filterOptions
          .map((option) => option.value)
          .reduce((x, y) => (z) => x(z) && y(z))
      : () => true;

  const headerText = [
    {
      type: "potential",
      text: t("reseller:clients.cardsHeader.potential"),
    },
    {
      type: "managed",
      text: t("reseller:clients.cardsHeader.managed"),
    },
    {
      type: "archived",
      text: t("reseller:clients.cardsHeader.archived"),
    },
  ];

  return (
    <Fragment>
      <div className="ClientCards-filter">
        <ClientFilterInput
          filterTerm={filterTerm}
          onFilterTermChange={setFilterTerm}
        />
      </div>
      <div className="ClientCards-container">
        {clients.length > 0 ? (
          <div className="ClientCards-container-header">
            <div className="ClientCards-container-header-text">
              <h3>Clients</h3>
              <small>
                {headerText.filter((header) => header.type === type)[0].text}
              </small>
            </div>
            <div className="ClientCards-container-header-filter">
              {type === "managed" ? (
                <Filters onFilterOptionsChange={setFilterOptions} />
              ) : null}
            </div>
          </div>
        ) : null}
        {clients.length > 0 ? (
          <div className="ClientCards-container-cards">
            {clients
              .filter(filterByText)
              .filter(filterByOptions)
              .map((client) => (
                <Fragment key={client.code}>
                  <ClientCard
                    type={type}
                    onArchive={() => onArchive(client.id)}
                    onDelete={() => onDelete(client.id)}
                    onNote={() => onNote(client.id, client.reseller_note)}
                    onInvite={() => onInvite(client.id)}
                    onManage={() => onManage(client.id)}
                    onAssess={() => onAssess(client.id)}
                    {...client}
                  />
                </Fragment>
              ))}
          </div>
        ) : (
          <EmptySection
            icon={<NoAccounts />}
            text={t("reseller:clients.noClients")}
          />
        )}
      </div>
    </Fragment>
  );
}

function ClientCard({
  type,
  onArchive,
  onNote,
  onInvite,
  onDelete,
  onManage,
  onAssess,
  ...client
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["common", "settings", "reseller"]);

  const clientProps = [
    {
      label: t("reseller:clients.cardIcons.activeLoans.label"),
      icon: <CreditScore />,
      value:
        client.active_loans ||
        t("reseller:clients.cardIcons.activeLoans.value"),
      types: ["managed"],
    },
    {
      label: t("reseller:clients.cardIcons.pendingLoans.label"),
      icon: <HourglassTop />,
      value:
        client.pending_loans ||
        t("reseller:clients.cardIcons.pendingLoans.value"),
      types: ["managed"],
    },
    {
      label: t("reseller:clients.cardIcons.subscription.label"),
      icon: <ShoppingBag />,
      value: client.plan
        ? t(`settings:items.subscription.plans.${client.plan}`).split(" ")[0]
        : t("reseller:clients.cardIcons.subscription.value"),
      types: ["managed"],
    },
    {
      label: t("reseller:clients.cardIcons.bankingState.label"),
      icon: <AccountBalance />,
      value: client.banking_ok ? (
        <CheckCircleRounded sx={{ color: "var(--color-success-1)" }} />
      ) : (
        <Cancel sx={{ color: "var(--color-error-1)" }} />
      ),
      types: ["managed"],
    },
  ];

  const slotProps1 = {
    popper: {
      sx: {
        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
          {
            marginTop: "0.1rem",
          },
      },
    },
  };

  const slotProps2 = {
    popper: {
      sx: {
        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
          {
            marginBottom: "0.1rem",
          },
      },
    },
  };

  const clientDetails = [
    {
      types: ["managed"],
      label: "Contact",
      value: client.admin ? (
        <Fragment>
          <ul style={{ margin: "0" }}>
            <li>{client.admin.name}</li>
            <li>{client.admin.email}</li>
            <li>{client.admin.telephone}</li>
          </ul>
        </Fragment>
      ) : (
        <></>
      ),
    },
    {
      types: ["potential"],
      label: t("reseller:clients.cardDetails.invitation.label"),
      value: client.invitation ? (
        <Fragment>
          <ul style={{ margin: "0" }}>
            <strong>
              {t("reseller:clients.cardDetails.invitation.value.true")}{" "}
              <CheckCircleRounded
                sx={{ color: "var(--color-success-1)" }}
                style={{ fontSize: "small" }}
              />
            </strong>
            <li>{client.invitation.email}</li>
            <li style={{ color: "var(--color-neutral-1)", fontSize: "small" }}>
              {formatDateTime(
                client.invitation.timestamp,
                i18n.resolvedLanguage,
              )}
            </li>
          </ul>
        </Fragment>
      ) : (
        <span>{t("reseller:clients.cardDetails.invitation.value.false")}</span>
      ),
    },
    {
      types: ["archived"],
      label: t("reseller:clients.cardDetails.note.label"),
      value: (
        <Tooltip
          title={client.reseller_note}
          slotProps={slotProps1}
          placement="top"
        >
          <Fragment>
            <Button
              text={
                client.reseller_note
                  ? t("reseller:clients.cardDetails.note.value.true")
                  : t("reseller:clients.cardDetails.note.value.false")
              }
              icon={<Edit />}
              onClick={onNote}
            />
          </Fragment>
        </Tooltip>
      ),
    },
  ];

  return (
    <div className="ClientCard">
      <div className="ClientCard-header">
        <Tooltip title={client.name} slotProps={slotProps2} placement="top">
          <strong>{client.name}</strong>
        </Tooltip>
        <small>{client.code}</small>
      </div>
      <div className="ClientCard-body">
        <div
          className="ClientCard-body-icons"
          style={{
            visibility: !open && type === "managed" ? "visible" : "hidden",
          }}
        >
          {clientProps
            // filtering here is no longer needed due to necessity to include icons (visiblity hidden) for consistent card height
            //  .filter((clientProp) => clientProp.types.includes(type))
            .map((clientProp, index) => (
              <Fragment key={index}>
                <ClientProp {...clientProp} />
              </Fragment>
            ))}
        </div>
        <div
          className="ClientCard-body-back"
          style={{ display: !open && type === "managed" ? "none" : "flex" }}
        >
          <div className="ClientCard-body-back-details">
            {clientDetails
              .filter((detail) => detail.types.includes(type))
              .map((detail, index) => (
                <Fragment key={index}>{detail.value}</Fragment>
              ))}
          </div>
          <div className="ClientCard-body-back-buttons">
            {typeof client.is_ecommerce === "boolean" ? (
              <Button
                title={t("reseller:clients.cardButtons.seeResults")}
                icon={<BarChart />}
                onClick={onAssess}
              />
            ) : (
              <Button
                title={t("reseller:clients.cardButtons.assess")}
                icon={<Calculate />}
                onClick={onAssess}
              />
            )}

            {type === "managed" ? (
              <Button
                icon={<Archive />}
                onClick={onArchive}
                title={t("reseller:clients.cardButtons.archive")}
              />
            ) : null}
            {type === "potential" || type === "managed" ? (
              <Button
                icon={<Edit />}
                onClick={onNote}
                title={t("reseller:clients.cardButtons.note")}
              />
            ) : null}
            {client.archived || type === "potential" ? (
              <Button
                icon={<DeleteForeverOutlined />}
                onClick={onDelete}
                title={t("reseller:clients.cardButtons.delete")}
              />
            ) : null}

            {type === "managed" ? (
              <Button
                text={t("reseller:clients.cardButtons.manage")}
                variant="primary_a_wide"
                onClick={onManage}
              />
            ) : type === "potential" ? (
              client.invitation === null ? (
                <Button
                  text={t("reseller:clients.cardButtons.invite")}
                  variant="primary_a_wide"
                  onClick={onInvite}
                />
              ) : (
                <Button
                  text={t("reseller:clients.cardButtons.reinvite")}
                  variant="primary_a_wide"
                  onClick={onInvite}
                />
              )
            ) : null}
            {type === "archived" ? (
              <Button
                text={t("reseller:clients.cardButtons.unarchive")}
                variant="primary_a_wide"
                onClick={onArchive}
              />
            ) : null}
          </div>
        </div>
      </div>
      {type === "managed" ? (
        <Badge
          style={{
            position: "absolute",
            right: "0.6rem",
            top: "0.6rem",
          }}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "white",
              borderRadius: "50%",
              height: "unset",
              padding: "0.5rem",
              boxShadow:
                "0px 1.492537260055542px 8.95522403717041px 0px #0000001F",
              cursor: "pointer",
            },
          }}
          badgeContent={open ? <ArrowLeft /> : <ArrowRight />}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        ></Badge>
      ) : null}
    </div>
  );
}

function ClientProp({ icon, value, label }) {
  const slotProps = {
    popper: {
      sx: {
        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
          {
            marginTop: "0.3rem",
          },
      },
    },
  };

  return (
    <Tooltip title={label} slotProps={slotProps} placement="bottom">
      <div className="ClientProp">
        <div className="ClientProp-icon">{icon}</div>
        <div className="ClientProp-value">{value}</div>
      </div>
    </Tooltip>
  );
}

function Filters({ onFilterOptionsChange }) {
  const { t } = useTranslation(["common", "reseller"]);
  const [filters, setFilters] = useState([
    {
      label: t("reseller:clients.cardsFilters.activeLoans"),
      value: (client) => client.active_loans,
      selected: false,
    },
    {
      label: t("reseller:clients.cardsFilters.pendingLoans"),
      value: (client) => client.pending_loans,
      selected: false,
    },
    {
      label: t("reseller:clients.cardsFilters.unsyncedBank"),
      value: (client) => !client.banking_ok,
      selected: false,
    },
  ]);

  useEffect(() => {
    onFilterOptionsChange(
      filters
        .filter((filter) => filter.selected)
        .map((filter) => ({ label: filter.label, value: filter.value })),
    );
  }, [filters, onFilterOptionsChange]);

  const handleSelect = (label) => {
    setFilters((prevFilters) =>
      prevFilters.map((prevFilter) =>
        prevFilter.label === label
          ? { ...prevFilter, selected: !prevFilter.selected }
          : prevFilter,
      ),
    );
  };

  return (
    <div className="Filters">
      {filters.map((filter) => (
        <Filter
          key={filter.label}
          label={filter.label}
          selected={filter.selected}
          onSelect={() => handleSelect(filter.label)}
        />
      ))}
    </div>
  );
}

function Filter({ label, selected, onSelect }) {
  return (
    <div
      className={["Filter", selected ? "selected" : ""]
        .filter(Boolean)
        .join(" ")}
      onClick={onSelect}
    >
      {label}
    </div>
  );
}

function ClientSearchForm({
  searchKey,
  onSearchKeyChange,
  searchSuccess,
  onSearch,
}) {
  const { t } = useTranslation(["common", "reseller"]);
  const inputs = [
    {
      type: "text",
      placeholder: t("reseller:clients.searchForm.placeholder"),
      name: "key",
      maxLength: 9,
      minLength: 9,
      pattern: "[0-9]*",
      value: searchKey,
      onChange: onSearchKeyChange,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    onSubmit: onSearch,
    resetLoader: searchSuccess,
    variant: "compact",
    button: t("common:add"),
    className: "ClientSearch-Form",
  };

  return (
    <div className="ClientSearch">
      <div className="ClientSearch-label">
        <div className="ClientSearch-label-icon">
          <Briefcase />
        </div>
        <div className="ClientSearch-label-text">
          <strong>{t("reseller:clients.searchForm.heading")}</strong>
          <small>{t("reseller:clients.searchForm.subheading")}</small>
        </div>
      </div>
      <Form {...form} />
    </div>
  );
}

function ClientAddModal({ client }) {
  const { t } = useTranslation(["common", "reseller"]);
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();

  const handleAddClient = async () => {
    const response = await api.createClient(client);
    if (response.ok) {
      dispatchModal({ type: "success" });
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("reseller:clients.addSuccessToast.heading"),
      });
    } else {
      const errors = await response.json();
      dispatchModal({ type: "success" });
      dispatchToast({
        type: "add",
        variant: "warning",
        heading: t("reseller:clients.addFailToast.heading"),
        subheading: errors.code[0],
      });
    }
  };

  return (
    <div style={{ padding: "1rem" }}>
      <Form
        onSubmit={handleAddClient}
        fancyButton
        heading={`${client.name} (${client.code})`}
        subheading={<Address address={client.address} />}
        icon={<Briefcase />}
        iconConf={<ArrowRight />}
      />
    </div>
  );
}

function ClientFilterInput({ filterTerm, onFilterTermChange }) {
  const { t } = useTranslation(["common", "reseller"]);
  const textInput = {
    type: "text",
    placeholder: t("reseller:clients.cardsFilters.text"),
    value: filterTerm,
    onChange: onFilterTermChange,
    className: "ClientFilter-text",
  };

  return (
    <div className="ClientFilter">
      <Input {...textInput} />
    </div>
  );
}

function ClientNoteModal({ clientID, reseller_note = "" }) {
  const { t } = useTranslation(["common", "reseller"]);
  const dispatchModal = useModalDispatch();
  const [note, setNote] = useState(reseller_note);

  const handleNote = useCallback(async () => {
    const response = await api.updateClient(clientID, {
      id: clientID,
      reseller_note: note,
    });
    if (response.ok) {
      dispatchModal({ type: "success" });
    }
  }, [clientID, note, dispatchModal]);

  const inputs = [
    {
      type: "text",
      label: t("reseller:clients.noteModal.label"),
      name: "note",
      maxLength: 150,
      value: note,
      onChange: setNote,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    onSubmit: handleNote,
    button: t("reseller:clients.noteModal.button"),
  };
  return <Form {...form} isButtonDisabled={!note} />;
}

function ClientInviteModal({ clientID, onInstantInviteSuccess }) {
  const { t } = useTranslation(["common", "reseller"]);
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();
  const [email, setEmail] = useState("");

  const handleInvite = useCallback(async () => {
    const response = await api.inviteClient({
      company: clientID,
      email: email,
    });

    const pendingConfirmationToast = {
      variant: "info",
      heading: t(
        "reseller:clients.inviteModal.toasts.pendingConfirmation.heading",
      ),
      subheading: t(
        "reseller:clients.inviteModal.toasts.pendingConfirmation.subheading",
      ),
    };

    const instantAddToast = {
      variant: "success",
      heading: t("reseller:clients.inviteModal.toasts.instantAdd.heading"),
      subheading: t(
        "reseller:clients.inviteModal.toasts.instantAdd.subheading",
      ),
    };

    switch (response.status) {
      case 200:
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          ...instantAddToast,
        });
        onInstantInviteSuccess();
        break;
      case 201:
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          ...pendingConfirmationToast,
        });
        break;
      default: {
        dispatchModal({ type: "success" });
        break;
      }
    }
  }, [clientID, email]);

  const inputs = [
    {
      type: "email",
      label: t("reseller:clients.inviteModal.label"),
      name: "email",
      value: email,
      onChange: setEmail,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    onSubmit: handleInvite,
    button: t("reseller:clients.inviteModal.button"),
  };
  return <Form {...form} isButtonDisabled={!email} />;
}

function ClientDeleteModal({ clientID }) {
  const { t } = useTranslation(["common", "reseller"]);
  const dispatchModal = useModalDispatch();

  const handleDelete = useCallback(async () => {
    const response = await api.deleteClient(clientID);
    if (response.ok) {
      dispatchModal({ type: "success" });
    }
  }, [clientID, dispatchModal]);
  const form = {
    onSubmit: handleDelete,
    button: t("reseller:clients.deleteModal.button"),
  };
  return <Form {...form} />;
}
