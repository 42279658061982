import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LangSwitch from "./LangSwitch";
import BackButton from "./BackButton";
import logoPNG from "../../assets/leano/logo-c.png";
import logoTextWhite from "../../assets/leano/name-b_w.png";
import routes from "../../routes/routes";
import { Menu as MenuIcon } from "react-feather";
import { useAuth } from "../../auth/AuthContext";
import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Drawer } from "@mui/material";
import {
  NotificationsNone,
  Logout,
  Close,
  InfoOutlined,
} from "@mui/icons-material";
import Button from "./Button";
import Form from "./Form";
import Navigation from "../Navigation/Navigation";
import CompanySwitch, {
  CompanySwitchBanner,
} from "../Navigation/CompanySwitch";
import { useModalDispatch } from "./Modal/ModalContext";
import Tabs from "./Tabs";
import { useHelper, useHelperDispatch } from "./Helper/HelperContext";

// Combine side menu et main content into a single element
export default function Template({ children }) {
  return (
    <Fragment>
      <Menu />
      {children}
    </Fragment>
  );
}

function Menu() {
  const { logged, loadingLogged, activeCompany, loadingActiveCompany } =
    useAuth();

  const [navDrawer, setNavDrawer] = useState(false);
  const toggleNavDrawer = () => setNavDrawer((navDrawer) => !navDrawer);

  const helper = useHelper();

  return (
    <header className="Menu">
      <div className="Menu_wrapper">
        <div className="Menu-container">
          <MenuLogos />
          {!loadingLogged && logged && (
            <Fragment>
              <div
                className="MenuButtons"
                style={{ visibility: navDrawer ? "hidden" : "visible" }}
              >
                {/* future helper in the menu */}
                {helper && false && <MenuHelper />}
                {!loadingLogged &&
                  logged &&
                  !loadingActiveCompany &&
                  activeCompany && (
                    <Fragment>
                      <CompanySwitch />
                      <MenuNotifications />
                    </Fragment>
                  )}

                <MenuLogout />
              </div>

              <MenuNavigation
                navDrawer={navDrawer}
                onNavigate={() => setNavDrawer(false)}
                onToggle={toggleNavDrawer}
              />
            </Fragment>
          )}
          {!loadingLogged && !logged && <LangSwitch />}
        </div>
      </div>
      {!loadingLogged && logged && !loadingActiveCompany && activeCompany && (
        <div style={{ visibility: navDrawer ? "hidden" : "visible" }}>
          <CompanySwitchBanner />
        </div>
      )}
    </header>
  );
}

function MenuHelper() {
  const dispatchHelper = useHelperDispatch();
  return (
    <Button
      icon={<InfoOutlined />}
      onClick={() => dispatchHelper({ type: "toggle" })}
    />
  );
}

function MenuNavigation({ onNavigate, onToggle, navDrawer }) {
  return (
    <div className="MenuButton MenuNavigation">
      <Button icon={!navDrawer ? <MenuIcon /> : <Close />} onClick={onToggle} />

      <Drawer
        anchor={"top"}
        open={navDrawer}
        onClose={onToggle}
        className="NavigationDrawer"
      >
        <Navigation onNavigate={onNavigate} />
      </Drawer>
    </div>
  );
}

function MenuNotifications() {
  const { unreadNotifications, loadingNotifications } = useAuth();
  const { t } = useTranslation("notifications");

  return (
    <div className="MenuButton MenuNotifications">
      <Badge
        sx={{
          "& .MuiBadge-badge": {
            color: "white",
            backgroundColor: "#e94954", // Leano red
            padding: "0.1rem",
          },
        }}
        overlap="circular"
        badgeContent={!loadingNotifications && unreadNotifications}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Button
          title={t("notifications:title")}
          icon={<NotificationsNone />}
          url={routes.Notifications.root}
        />
      </Badge>
    </div>
  );
}

export function MenuLogos() {
  return (
    <Link to={routes.Home} className="Menu-logos">
      <img src={logoPNG} alt="logo" />
      <img
        className="Menu-logos-logoTextWhite"
        src={logoTextWhite}
        alt="text logo"
      />
    </Link>
  );
}

function MenuLogout() {
  const { t } = useTranslation(["common", "account"]);
  const navigate = useNavigate();
  const dispatchModal = useModalDispatch();

  return (
    <Button
      title={t("common:logout")}
      className="MenuLogout"
      icon={<Logout fontSize="small" />}
      onClick={() => {
        dispatchModal({
          type: "add",
          ...{
            title: t("account:logout.title"),
            message: t("account:logout.message"),
            component: <LogoutModal />,
            onSuccess: () => navigate(routes.Home),
          },
        });
      }}
    />
  );
}

function LogoutModal() {
  const { t } = useTranslation(["common", "account"]);
  const { onLogout } = useAuth();
  const dispatchModal = useModalDispatch();

  const handleLogout = async () => {
    const response = await onLogout();
    if (response.ok) {
      dispatchModal({ type: "success" });
    }
  };
  return <Form onSubmit={handleLogout} button={t("account:logout.button")} />;
}

// Main page element
export function Main({ children, className, themeSwitch }) {
  return (
    <main
      className={["Main", themeSwitch ? "uncenter" : "", className]
        .filter(Boolean)
        .join(" ")}
    >
      {children}
    </main>
  );
}

export function Header({
  heading,
  onBackButtonClick,
  subheading,
  children,
  tabs = null,
  selectedID,
  onSelect,
  smallTabs = false,
}) {
  return (
    <header className="Header">
      {children ? (
        children
      ) : (
        <Fragment>
          <Heading onBackButtonClick={onBackButtonClick} heading={heading} />
          <SubHeading subheading={subheading} />
          {tabs && (
            <Tabs
              tabs={tabs}
              selectedID={selectedID}
              onSelect={onSelect}
              smallTabs={smallTabs}
            />
          )}
        </Fragment>
      )}
    </header>
  );
}

export function Heading({ onBackButtonClick = null, heading, children }) {
  return (
    <div className="Header-heading">
      {onBackButtonClick && <BackButton onClick={onBackButtonClick} />}
      <h1>{children ? children : heading}</h1>
    </div>
  );
}

export function SubHeading({ subheading, children }) {
  return (
    <div className="Header-subheading">{children ? children : subheading}</div>
  );
}

export function Content({ style, content, children, className }) {
  return (
    <div
      style={style}
      className={["Content", className].filter(Boolean).join(" ")}
    >
      {children ? children : content}
    </div>
  );
}
