// Language selector

import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../i18n";
import Cookies from "universal-cookie";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useEffect, useState } from "react";

export default function LangSwitch() {
  const { i18n } = useTranslation();
  const [langCode, setLangCode] = useState(i18n.resolvedLanguage);

  const handleChange = (event, newCode) => setLangCode(newCode);

  useEffect(() => {
    i18n.changeLanguage(langCode);
    const cookies = new Cookies();
    cookies.set("language", langCode, { path: "/" });
  }, [langCode, i18n]);

  return (
    <ToggleButtonGroup
      className="LangSwitch"
      value={langCode}
      size={"small"}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {Object.keys(availableLanguages).map((langCode, index) => (
        <ToggleButton key={index} value={langCode}>
          {langCode}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
