import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "../commons/Template";
import { VaultWrapper } from "./Vault";
import Form from "../commons/Form";
import api from "../../api/resources";
import Files from "../commons/Files";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";

export default function Other() {
  const { t } = useTranslation(["common", "vault"]);
  document.title = t("vault:tabs.other.title");
  const dispatchToast = useToastsDispatch();

  const [selectedType, setSelectedType] = useState(null);
  const [newFiles, setNewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  /**
   * Return translated and formatted string for different document types
   * @param {String} documentType the document type
   * @returns {String} the localised formatted document type
   */
  const formatDocumentType = useCallback(
    (documentType) => {
      switch (documentType) {
        case "business_plan":
          return t(
            `vault:tabs.other.content.files.options.types.${documentType}`,
          );
        case "company_presentation":
          return t(
            `vault:tabs.other.content.files.options.types.${documentType}`,
          );
        case "outstanding_debt":
          return t(
            `vault:tabs.other.content.files.options.types.${documentType}`,
          );
        default:
          return t("common:other");
      }
    },
    [t],
  );

  // Return formatted document name based on its type and index
  const formatDocumentName = useCallback(
    (document) => {
      let name = formatDocumentType(document.type);
      return (
        name +
        " – " +
        document.file.split("?")[0].split(".").slice(-2)[0].split("-").slice(-1)
      );
    },
    [formatDocumentType],
  );

  // Delete files then: init options (if required)
  const handleFileDelete = useCallback(async (fileID) => {
    const response = await api.deleteDocument(fileID);
    if (response.ok) {
      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.id !== fileID),
      );
      // initOptions(); // to be used if options list is filtered
      return true;
    }
    return false;
  }, []);

  // Download files
  const handleFileDownload = useCallback(async () => {
    const response = await api.getDocuments();
    if (response.ok) {
      const files = await response.json();

      setFiles(
        files
          .filter(
            (file) =>
              file.type === "business_plan" ||
              file.type === "company_presentation" ||
              file.type === "outstanding_debt",
          )
          .map((file) => ({
            id: file.id,
            name: formatDocumentName(file),
            url: file.file,
            onDelete: () => handleFileDelete(file.id),
          })),
      );
      setLoadingFiles(false);
    }
  }, [formatDocumentName, handleFileDelete]);

  useEffect(() => {
    handleFileDownload();
  }, [handleFileDownload]);

  // Upload files then: reset states (upload success, new files), download files and init options
  const handleFileUpload = async () => {
    const data = new FormData();
    data.append("file", newFiles[0]);
    data.append("type", selectedType.value);
    data.append("year", null);

    const response = await api.createDocument(data, true);
    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("vault:common.toasts.fileUploadSuccess.heading"),
        subheading: t("vault:common.toasts.fileUploadSuccess.subheading"),
      });
      setUploadSuccess(true);
      setNewFiles([]);
      handleFileDownload();
      // initOptions(); // to be used if options list is filtered
    } else {
      const errors = await response.json();
      dispatchToast({
        type: "add",
        variant: "warning",
        heading: errors.file[0],
      });
      setUploadSuccess(true);
    }
  };

  const inputs = [
    {
      type: "select",
      placeholder: t("vault:tabs.other.content.files.options.placeholder"),
      onChange: setSelectedType,
      value: selectedType,
      options: [
        {
          label: formatDocumentType("business_plan"),
          value: "business_plan",
        },
        {
          label: formatDocumentType("company_presentation"),
          value: "company_presentation",
        },
        {
          label: formatDocumentType("outstanding_debt"),
          value: "outstanding_debt",
        },
      ],
      noOptionsMessage: t("vault:selectNoOptions"),
    },
    {
      type: "file",
      value: newFiles,
      onChange: setNewFiles,
    },
  ];
  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    button: t("vault:common.fileDrop.upload"),
    onSubmit: handleFileUpload,
    isButtonDisabled: newFiles.length === 0,
    className: "FileDropForm",
    resetLoader: uploadSuccess,
  };

  return (
    <Content>
      <VaultWrapper>
        <Form {...form} />
        <Files files={files} loadingFiles={loadingFiles} />
      </VaultWrapper>
    </Content>
  );
}
