import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "../commons/Template";
import Form from "../commons/Form";
import api from "../../api/resources";
import { VaultWrapper } from "./Vault";
import Files from "../commons/Files";
import { useAuth } from "../../auth/AuthContext";
import { getMissingFinancialStatements } from "../../utils";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";

export default function Finance() {
  const { t } = useTranslation(["common", "vault"]);
  document.title = t("vault:tabs.finance.title");
  const { activeCompany, loadingActiveCompany } = useAuth();
  const dispatchToast = useToastsDispatch();

  const [selectedYear, setSelectedYear] = useState(null);
  const [years, setYears] = useState("");
  const [loadingYears, setLoadingYears] = useState(true);
  const [newFiles, setNewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  // Init options
  const initOptions = useCallback(async () => {
    setSelectedYear(null);
    const response = await api.getDocuments();
    if (response.ok && !loadingActiveCompany) {
      const documents = await response.json();
      setYears(
        getMissingFinancialStatements(
          documents,
          activeCompany.immatriculation_date,
        ),
      );
      setLoadingYears(false);
    }
  }, [loadingActiveCompany, activeCompany.immatriculation_date]);

  useEffect(() => {
    initOptions();
  }, [initOptions, uploadSuccess]);

  // Delete files then: init options
  const handleFileDelete = useCallback(
    async (fileID) => {
      const response = await api.deleteDocument(fileID);
      if (response.ok) {
        setFiles((prevFiles) =>
          prevFiles.filter((prevFile) => prevFile.id !== fileID),
        );
        initOptions();
        return true;
      }
      return false;
    },
    [initOptions],
  );

  // Download files
  const handleFileDownload = useCallback(async () => {
    const response = await api.getDocuments();
    if (response.ok) {
      const files = await response.json();

      setFiles(
        files
          .filter((file) => file.type === "financial_statement")
          .map((file) => ({
            id: file.id,
            name: file.year,
            date: new Date(file.year),
            url: file.file,
            onDelete: () => handleFileDelete(file.id),
          })),
      );
      setLoadingFiles(false);
    }
  }, [handleFileDelete]);

  useEffect(() => {
    handleFileDownload();
  }, [handleFileDownload]);

  // Upload files then: reset states (upload success, new files), download files and init options
  const handleFileUpload = async () => {
    const data = new FormData();
    data.append("file", newFiles[0]);
    data.append("type", "financial_statement");
    data.append("year", selectedYear.value);

    const response = await api.createDocument(data);
    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("vault:common.toasts.fileUploadSuccess.heading"),
        subheading: t("vault:common.toasts.fileUploadSuccess.subheading"),
      });
      setUploadSuccess(true);
      setNewFiles([]);
      handleFileDownload();
      initOptions();
    } else {
      const errors = await response.json();
      dispatchToast({
        type: "add",
        variant: "warning",
        heading: errors.file[0],
      });
      setUploadSuccess(true);
    }
  };

  const inputs = [
    {
      type: "select",
      placeholder: t("vault:tabs.finance.content.files.options.placeholder"),
      onChange: setSelectedYear,
      value: selectedYear,
      required: true,
      options:
        !loadingYears &&
        years.map((year) => ({
          label: year,
          value: year,
        })),
      noOptionsMessage: t("vault:selectNoOptions"),
    },
    {
      type: "file",
      value: newFiles,
      onChange: setNewFiles,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    button: t("vault:common.fileDrop.upload"),
    onSubmit: handleFileUpload,
    isButtonDisabled: newFiles.length === 0,
    className: "FileDropForm",
    resetLoader: uploadSuccess,
  };
  return (
    <Content>
      <VaultWrapper>
        <Form {...form} />
        <Files files={files} loadingFiles={loadingFiles} sortByDate />
      </VaultWrapper>
    </Content>
  );
}
