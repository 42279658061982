import { useEffect, useState } from "react";

export default function useUserActivity() {
  const [active, setActive] = useState(false); // user state (init. as inactive)

  useEffect(() => {
    // Set activity when user presses a mouse button
    setActive(false);
    const handleOnMouseDown = () => setActive(true);
    document.onmousedown = handleOnMouseDown;
    return document.removeEventListener("onmousedown", handleOnMouseDown);
  }, [active]);

  return active;
}
