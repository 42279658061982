import { createContext, useContext, useReducer } from "react";

const ToastsContext = createContext(null);

const ToastsDispatchContext = createContext(null);

export function ToastsProvider({ children }) {
  const [toasts, dispatchToast] = useReducer(toastsReducer, initialToasts);

  return (
    <ToastsContext.Provider value={toasts}>
      <ToastsDispatchContext.Provider value={dispatchToast}>
        {children}
      </ToastsDispatchContext.Provider>
    </ToastsContext.Provider>
  );
}

export function useToasts() {
  return useContext(ToastsContext);
}

export function useToastsDispatch() {
  return useContext(ToastsDispatchContext);
}

function toastsReducer(toasts, action) {
  switch (action.type) {
    case "add": {
      return [
        ...toasts,
        {
          id: toasts.length + 1,
          variant: action.variant,
          heading: action.heading,
          subheading: action.subheading,
        },
      ];
    }
    case "delete": {
      return toasts.filter((toast) => toast.id !== action.id);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

const initialToasts = [
  // {
  //   id: 1,
  //   variant: "success",
  // },
  // {
  //   id: 2,
  //   variant: "error",
  // },
];
