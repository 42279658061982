import { useState, useEffect, useMemo } from "react";
import api from "../../../api/resources";
import CompanyNameSet from "./CompanyNameSet";
import CodeSet from "./CodeSet";
import CategorySet from "./CategorySet";
import DateSet from "./DateSet";
import EcomSet from "./EcomSet";
import AddressSet from "./AddressSet";
import { SettingList } from "../Settings";
import { FormLoader } from "../../commons/Form";
import { useAuth } from "../../../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes/routes";
import { useToastsDispatch } from "../../commons/Toasts/ToastsContext";
import { useTranslation } from "react-i18next";
import Button from "../../commons/Button";
import Form from "../../commons/Form";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export function Company() {
  const { t } = useTranslation();
  document.title = t("settings:tabs.company.title");
  const [loading, setLoading] = useState(true);
  const dispatchToast = useToastsDispatch();
  const navigate = useNavigate();
  const { user, loadingUser } = useAuth();

  const [company, setCompany] = useState(null);

  const handleEcomChange = async (value) => {
    const response = await api.updateActiveCompany({ is_ecommerce: value });
    if (response.ok) {
      handleCompanyChange(value, "is_ecommerce");
    } else {
      dispatchToast({
        type: "add",
        variant: "info",
        heading: "Info",
        subheading: "cannot modify e-commerce status",
      });
    }
  };

  const handleCompanyChange = (value, field) => {
    setCompany((prevCompany) => ({
      ...prevCompany,
      [field]: value,
    }));
  };

  const init = useMemo(
    () => async () => {
      const response = await api.getActiveCompany();
      if (response.ok) {
        let company = await response.json();
        if (company.length === 0) {
          navigate(routes.Company.root);
        } else {
          setCompany(company);
          setLoading(false);
        }
      }
    },
    [navigate],
  );

  useEffect(() => {
    init();
  }, [init]);

  if (loading || loadingUser) return <FormLoader />;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <SettingList>
        <CompanyNameSet value={company.name} onSuccess={init} />
        <CodeSet value={company.code} country={company.address.country} />
        <CategorySet
          value={company.category}
          country={company.address.country}
        />
        <DateSet value={company.immatriculation_date} />
        <EcomSet value={company.is_ecommerce} onChange={handleEcomChange} />
        <AddressSet value={company.address} onSuccess={init} />
      </SettingList>

      {company.as_reseller ? null : (
        <CompanyManage
          companyName={company.name}
          type={
            company.managers.length === 1
              ? "delete"
              : user.is_admin
                ? "transfer"
                : "quit"
          }
        />
      )}
    </div>
  );
}

function CompanyManage({ companyName, type }) {
  const { t } = useTranslation(["common", "settings"]);
  const dispatchModal = useModalDispatch();
  const navigate = useNavigate();
  return (
    <div>
      <Button
        variant="link"
        text={t(`settings:items.${type}Company.label`)}
        onClick={
          type === "transfer"
            ? () => navigate(routes.Settings.users)
            : () =>
                dispatchModal({
                  type: "add",
                  ...{
                    title: companyName,
                    message: t(`settings:modals.${type}Company.message`),
                    component: <ManageCompanyModal type={type} />,
                    onSuccess: () => window.location.reload(),
                  },
                })
        }
      />
    </div>
  );
}

function ManageCompanyModal({ type }) {
  const dispatchModal = useModalDispatch();

  const { t } = useTranslation();
  const [errorsObj, setErrorsObj] = useState({});

  const handleDeleteCompany = async () => {
    setErrorsObj({});

    const response = await api.deleteActiveCompany();

    if (response.ok) {
      dispatchModal({ type: "success" });
    } else {
      setErrorsObj({
        non_field_errors: ["Une erreur s'est produite."],
      });
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  return (
    <Form
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handleDeleteCompany}
      errors={getErrors("non_field_errors")}
      button={t(`common:${type}`)}
    />
  );
}
