import { useEffect, useState } from "react";
import Fillout from "./Fillout";
import Estimate from "./Estimate";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../routes/routes";

export default function Request({ initID = 0, startWithEdit = true }) {
  const { need, usecase } = useRequestLocation();
  const [id, setID] = useState(initID);
  const [edit, setEdit] = useState(startWithEdit);

  const handleEstimate = (newRequestID = id) => {
    setID(newRequestID);
    setEdit(false);
  };

  const handleEdit = () => setEdit(true);

  if (edit) {
    return (
      <Fillout
        need={need}
        usecase={usecase}
        patchID={id}
        onEstimate={handleEstimate}
      />
    );
  }

  return <Estimate loanRequestID={id} onEdit={handleEdit} />;
}

function useRequestLocation() {
  const location = useLocation();
  let [need, usecase] = "";
  if (location.state) {
    need = location.state.need;
    usecase = location.state.usecase;
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (!need || !usecase) {
      navigate(routes.Financing.assess);
    }
  }, []);

  return { need, usecase };
}
