import routes from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { Navigate, useLocation } from "react-router-dom";

export default function PrivateRouteWrapper({ children }) {
  const { logged, loadingLogged, activeCompany, loadingActiveCompany } =
    useAuth();
  const location = useLocation();

  if (loadingLogged) return <></>;
  if (!logged) return <Navigate to={routes.Account.Login} replace />;

  if (loadingActiveCompany) return <></>;
  if (!activeCompany) {
    const allowedRoutesWhenNoActiveCompany = [
      routes.Company.root,
      routes.Settings.profile,
      routes.Settings.password,
    ];
    if (
      !allowedRoutesWhenNoActiveCompany.some((route) =>
        location.pathname.includes(route),
      )
    ) {
      return <Navigate to={routes.Company.root} replace />;
    }
  }

  return children;
}
