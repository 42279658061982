import routes from "./routes";
import { useAuth } from "../auth/AuthContext";
import { Navigate } from "react-router-dom";

export default function DashboardRoute({ children }) {
  const { activeCompany, loadingActiveCompany } = useAuth();

  if (loadingActiveCompany) return <></>;
  if (activeCompany.is_reseller)
    return <Navigate to={routes.Reseller.clients} />;
  return children;
}
