import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AccountDel from "./AccountDel";
import EmailSet from "./EmailSet";
import { FormLoader } from "../../commons/Form";
import LangSet from "./LangSet";
import NameSet from "./NameSet";
import PhoneSet from "./PhoneSet";
import { SettingList } from "../Settings";
import SurnameSet from "./SurnameSet";
import authAPI from "../../../api/authentication";

export default function Profile() {
  const { t } = useTranslation(["common", "settings"]);
  document.title = t("settings:tabs.profile.title");
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });

  const init = useMemo(
    () => async () => {
      const response = await authAPI.getUserInfo();
      if (response.status === 200) {
        setUser(response.data.user);
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    init();
  }, [init]);

  return loading ? (
    <FormLoader />
  ) : (
    <SettingList>
      <NameSet value={user.name} />
      <SurnameSet value={user.surname} />
      <LangSet />
      <PhoneSet value={user.phone} onSuccess={init} />
      <EmailSet value={user.email} onSuccess={init} />
      <AccountDel />
    </SettingList>
  );
}
