import { useEffect, useState } from "react";
import api from "../../api/resources";
import { Content, Header, Main } from "../commons/Template";
import CreditLine from "./CreditLine";
import Form from "../commons/Form";
import { useAuth } from "../../auth/AuthContext";
import { useTranslation } from "react-i18next";
import { addDays, differenceInCalendarDays, formatDate } from "date-fns";
import useFormatAmount from "./useFormatAmount";
import { i18n } from "../../i18n";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import Button from "../commons/Button";
import { AddBox } from "@mui/icons-material";
import { useCountries } from "../../hooks/commons/useCountries";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { capitalize } from "../../utils";

export default function Fillout({ need, usecase, patchID, onEstimate }) {
  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:fillout.title");

  // Init.
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const { activeCompany, loadingActiveCompany } = useAuth();

  const [creditLine, setCreditLine] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [counterparties, setCounterparties] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([
    "FR",
    "IT",
    "DE",
  ]);
  const [companyCodes, setCompanyCodes] = useState([
    {
      FR: "123456789",
    },
  ]);

  const initCreditLine = async () => {
    const response = await api.getUsecases(need);
    if (response.ok) {
      const jsonUsecases = await response.json();
      const currentUsecase = jsonUsecases[usecase];
      if (currentUsecase.credit_line) {
        const calcCreditLine = {
          available: currentUsecase.credit_line.available_amount,
          max: currentUsecase.credit_line.max_amount,
          currency: activeCompany.currency,
        };
        setCreditLine(calcCreditLine);
      }
    }
  };

  const initBankAccounts = async () => {
    const response = await api.getAccounts();
    if (response.ok) {
      const accounts = await response.json();
      const options = accounts
        .filter((account) => account.is_active)
        .map((account) => ({
          label: account.name,
          value: account.id,
        }));
      setBankAccounts(options);
    }
  };

  const initCounterparties = async () => {
    handleInvoiceChange(null, "counterparty");
    const response = await api.getCounterparties(true);
    if (response.ok) {
      const allCounterparties = await response.json();
      const options = allCounterparties.map((counterparty) => ({
        label: counterparty.label,
        value: counterparty.id,
      }));
      setCounterparties(options);
    }
  };

  const initCurrencies = async () => {
    const response = await api.getCurrencies();
    if (response.ok) {
      const jsonCurrencies = await response.json();
      const currenciesOptions = jsonCurrencies.map((currency) => ({
        label: currency,
        value: currency,
      }));
      setCurrencies(currenciesOptions);
      const activeCompanyCurrency = currenciesOptions.find(
        (currency) => currency.value === activeCompany.currency,
      );
      handleLoanRequestChange(activeCompanyCurrency, "currency");
      handleInvoiceChange(activeCompanyCurrency, "currency");
    }
  };

  const initCountries = async () => {
    const response = await api.getCountries();
    if (response.ok) {
      const jsonResponse = await response.json();
      setAvailableCountries(jsonResponse);
    }
  };

  const initCompanyCodes = async () => {
    const response = await api.getCompanyCodes();
    if (response.ok) {
      const jsonResponse = await response.json();
      setCompanyCodes(jsonResponse);
    }
  };

  // --- DATES

  const today = () => new Date().setHours(0, 0, 0, 0);
  const formatDateForInput = (date) => formatDate(date, "yyyy-MM-dd");
  const [days, setDays] = useState("");

  const maxDays = 90;

  const isDateValid = (dateStr) => !isNaN(new Date(dateStr));

  // if date is valid, format it, else set it to a default (today)
  const setDateInput = (inputValue) =>
    formatDateForInput(
      inputValue && isDateValid(inputValue) ? inputValue : today(),
    );

  const handleDateChange = (value, setFn) =>
    value && isDateValid(value) ? setFn(value) : setFn(today());

  // --- END DATES

  // Results

  const [invoice, setInvoice] = useState({
    id: 0,
    reference: "",
    creation_date: "",
    amount_without_tax: "",
    amount_with_tax: "",
    currency: null,
    due_date: "",
    counterparty: null,
    file: [],
    fileURL: "",
    type: null,
  });

  const handleInvoiceChange = (newValue, field) => {
    setInvoice((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const initialInvoiceErrors = {
    file: [],
    type: [],
    reference: [],
    creation_date: [],
    amount_without_tax: [],
    amount_with_tax: [],
    currency: [],
    due_date: [],
    counterparty: [],
    non_field_errors: [],
  };

  const [invoiceErrors, setInvoiceErrors] = useState(initialInvoiceErrors);

  const initialLoanRequestErrors = {
    use_case: [],
    starting_date: [],
    ending_date: [],
    amount: [],
    currency: [],
    invoice: [],
    bank_account: [],
    months: [],
    default_reasons: [],
    custom_reason: [],
    non_field_errors: [],
  };

  const [loanRequest, setLoanRequest] = useState({
    id: 0,
    use_case: usecase,
    starting_date: addDays(today(), 1),
    ending_date: addDays(today(), 2),
    amount: "",
    currency: null,
    invoice: "", // id
    bank_account: null,
    months: 0,
    default_reasons: [],
    custom_reason: "",
  });

  const handleLoanRequestChange = (newValue, field) => {
    setLoanRequest((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const [loanRequestErrors, setLoanRequestErrors] = useState(
    initialLoanRequestErrors,
  );

  const [inputErrors, setInputErrors] = useState(false);

  const handleErrorsChange = (newErrors = {}, target = "") => {
    switch (target) {
      case "invoice":
        setInvoiceErrors((prev) => ({
          ...prev,
          ...newErrors,
        }));
        setInputErrors(true);
        break;
      case "loanRequest":
        setLoanRequestErrors((prev) => ({
          ...prev,
          ...newErrors,
        }));
        setInputErrors(true);
        break;
      default:
        console.log("no errors target provided");
        break;
    }
  };

  const clearErrors = () => {
    setInvoiceErrors(initialInvoiceErrors);
    setLoanRequestErrors(initialLoanRequestErrors);
    setInputErrors(false);
  };

  const [page, setPage] = useState("");

  // Page 1: create invoice + create loan request
  const creditDateInput = {
    type: "date",
    name: "creditDate",
    label: t("common:creditDate"),
    value: setDateInput(loanRequest.starting_date),
    onChange: (value) =>
      handleDateChange(value, () =>
        handleLoanRequestChange(value, "starting_date"),
      ),
    errors: loanRequestErrors.starting_date,
    min: formatDateForInput(addDays(today(), 1)),
  };

  const refundDateInput = {
    type: "date",
    name: "refundDate",
    label: t("common:refundDate"),
    value: setDateInput(loanRequest.ending_date),
    onChange: (value) =>
      handleDateChange(value, () =>
        handleLoanRequestChange(value, "ending_date"),
      ),
    errors: loanRequestErrors.ending_date,
    min: formatDateForInput(
      addDays(setDateInput(loanRequest.starting_date), 1),
    ),
    max: formatDateForInput(
      addDays(setDateInput(loanRequest.starting_date), maxDays),
    ),
  };

  const handleDaysChange = (newDays) => {
    // set days
    setDays(newDays);

    // compute ending day accordingly
    if (newDays && newDays <= maxDays) {
      const newEndingDate = addDays(
        loanRequest.starting_date,
        parseInt(newDays),
      );
      handleLoanRequestChange(newEndingDate, "ending_date");
    }
  };

  const daysInput = {
    type: "number",
    name: "days",
    label: capitalize(t("common:days")),
    value: days,
    onChange: (value) => handleDaysChange(value),
    min: 1,
    max: maxDays,
  };

  // Compute (days) difference between refund and credit dates
  useEffect(() => {
    if (loanRequest.starting_date && loanRequest.ending_date) {
      setDays(
        differenceInCalendarDays(
          loanRequest.ending_date,
          loanRequest.starting_date,
        ),
      );
    }
  }, [loanRequest.starting_date, loanRequest.ending_date]);

  const amountInputVariableCurrency = {
    type: "number",
    name: "amount",
    label: t("financing:fillout.step1.form.inputs.requestedAmount"),
    value: loanRequest.amount,
    onChange: (value) => handleLoanRequestChange(value, "amount"),
    errors: loanRequestErrors.amount,
    min: 0,
    onFormatNumber: (value) =>
      useFormatAmount(
        value,
        i18n.resolvedLanguage,
        loanRequest.currency && loanRequest.currency.value,
      ),
  };

  const amountInputCompanyCurrency = {
    type: "number",
    name: "amount",
    label: t("financing:fillout.step1.form.inputs.requestedAmount"),
    value: loanRequest.amount,
    onChange: (value) => handleLoanRequestChange(value, "amount"),
    errors: loanRequestErrors.amount,
    min: 0,
    onFormatNumber: (value) =>
      useFormatAmount(
        value,
        i18n.resolvedLanguage,
        loanRequest.currency && loanRequest.currency.value,
      ),
  };

  const currencyInput = {
    type: "select",
    name: "currency",
    label: t("common:currency"),
    value: loanRequest.currency,
    onChange: (value) => handleLoanRequestChange(value, "currency"),
    options: currencies,
    errors: loanRequestErrors.currency,
  };

  const durationInput = {
    type: "number",
    name: "months",
    label: `${t("common:desiredDuration")} (${t("common:inMonths")})`,
    value: loanRequest.months,
    onChange: (value) => handleLoanRequestChange(value, "months"),
    errors: loanRequestErrors.months,
    min: 1,
    max: 12,
    step: 1,
  };

  const reasonOptions = Object.entries(
    t("financing:fillout.step1.form.inputs.loanRequestReasons.options", {
      returnObjects: true,
    }),
  ).map(([i18nKey, stringValue]) => ({
    label: stringValue,
    value: i18nKey,
  }));

  const reasonsInput = {
    type: "select",
    name: "reasons",
    label: t("financing:fillout.step1.form.inputs.loanRequestReasons.label"),
    value: loanRequest.default_reasons,
    onChange: (value) => handleLoanRequestChange(value, "default_reasons"),
    options: reasonOptions,
    isMulti: true,
    errors: loanRequestErrors.default_reasons,
  };

  const reasonELSEInput = {
    type: "text",
    name: "reasonElse",
    label: t("financing:fillout.step1.form.inputs.loanRequestReasonELSE.label"),
    value: loanRequest.custom_reason,
    onChange: (value) => handleLoanRequestChange(value, "custom_reason"),
    maxLength: 100,
    errors: loanRequestErrors.custom_reason,
    disabled: !loanRequest.default_reasons.some(
      (reason) => reason.value === "ELSE",
    ),
  };

  const bankAccountInput = {
    type: "select",
    name: "bankAccount",
    label: t("common:bankAccount"),
    value: loanRequest.bank_account,
    onChange: (value) => handleLoanRequestChange(value, "bank_account"),
    options: bankAccounts,
    errors: loanRequestErrors.bank_account,
  };

  const invoiceTypes = [
    { label: t("common:client"), value: "client" },
    { label: t("common:supplier"), value: "supplier" },
  ];

  const invoiceTypeInput = {
    type: "select",
    name: "invoiceType",
    label: t("common:invoiceType"),
    value: invoice.type,
    onChange: (value) => handleInvoiceChange(value, "type"),
    options: invoiceTypes,
    errors: invoiceErrors.type,
  };

  const invoiceFileInput = {
    type: "file",
    name: "invoiceFile",
    label: t("common:invoice"),
    value: invoice.file,
    onChange: (value) => handleInvoiceChange(value, "file"),
    errors: invoiceErrors.file,
  };

  const convertDataForAPI = () => ({
    starting_date: formatDateForInput(loanRequest.starting_date),
    ending_date:
      loanRequest.ending_date && !loanRequest.months
        ? formatDateForInput(loanRequest.ending_date)
        : null,
    currency: loanRequest.currency.value,
    bank_account: loanRequest.bank_account
      ? loanRequest.bank_account.value
      : null,
    months: loanRequest.months || null,
    default_reasons: loanRequest.default_reasons.map((reason) => reason.value),
    // send custom_reason only if default_reasons includes "ELSE"
    custom_reason: loanRequest.default_reasons.some(
      (reason) => reason.value === "ELSE",
    )
      ? loanRequest.custom_reason
      : null,
  });

  const handleCreate = async () => {
    clearErrors();
    const response = await api.createLoanRequest({
      ...loanRequest,
      ...convertDataForAPI(),
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      handleLoanRequestChange(jsonResponse.id, "id");
      onEstimate(jsonResponse.id);
    } else {
      const errorsJsonRes = await response.json();
      handleErrorsChange(errorsJsonRes, "loanRequest");
    }
  };

  const handlePatch = async () => {
    clearErrors();
    const loanRequestResponse = await api.patchLoanRequest(patchID, {
      ...loanRequest,
      ...convertDataForAPI(),
    });
    if (loanRequestResponse.ok) {
      onEstimate(patchID);
    } else {
      const errorsJsonRes = await loanRequestResponse.json();
      handleErrorsChange(errorsJsonRes, "loanRequest");
    }
  };

  const handleCreateSTI = async () => {
    const handleCreateLoanRequest = async (invoiceID) => {
      const response = await api.createLoanRequest({
        ...loanRequest,
        invoice: invoiceID,
        ...convertDataForAPI(),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        handleLoanRequestChange(jsonResponse.id, "id");
        setPage(2);
      } else {
        const errorsJsonRes = await response.json();
        handleErrorsChange(errorsJsonRes, "loanRequest");
      }
    };

    clearErrors();
    const newInvoice = new FormData();
    newInvoice.append("file", invoice.file[0]);
    newInvoice.append("type", invoice.type.value);
    const invoiceResponse = await api.createInvoice(newInvoice);

    if (invoiceResponse.ok) {
      const jsonInvoice = await invoiceResponse.json();
      handleInvoiceChange(jsonInvoice.id, "id");
      handleInvoiceChange(jsonInvoice.file, "fileURL");

      handleCreateLoanRequest(jsonInvoice.id);
    } else {
      const errorsJsonRes = await invoiceResponse.json();
      handleErrorsChange(errorsJsonRes, "invoice");
    }
  };

  const handlePatchSTI = async () => {
    clearErrors();
    const invoiceResponse = await api.patchInvoice(invoice.id, {
      type: invoice.type.value,
    });
    if (invoiceResponse.ok) {
      const loanRequestResponse = await api.patchLoanRequest(patchID, {
        ...loanRequest,
        ...convertDataForAPI(),
      });
      if (loanRequestResponse.ok) {
        setPage(2);
      } else {
        const errorsJsonRes = await loanRequestResponse.json();
        handleErrorsChange(errorsJsonRes, "loanRequest");
      }
    } else {
      const errorsJsonRes = await invoiceResponse.json();
      handleErrorsChange(errorsJsonRes, "invoice");
    }
  };

  const forms = [
    {
      usecase: "short_term_invoice",
      inputs: [
        {
          data: [amountInputVariableCurrency, currencyInput],
          display: "grid",
          gridTemplateColumns: "4fr 1fr",
        },
        {
          data: [creditDateInput, daysInput, refundDateInput],
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        },
        {
          data: [bankAccountInput, invoiceTypeInput],
          display: "grid",
        },
        {
          data: [invoiceFileInput],
        },
      ],
      inputsPatch: [
        {
          data: [amountInputVariableCurrency, currencyInput],
          display: "grid",
          gridTemplateColumns: "4fr 1fr",
        },
        {
          data: [creditDateInput, daysInput, refundDateInput],
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        },
        {
          data: [bankAccountInput, invoiceTypeInput],
          display: "grid",
        },
      ],
      onCreate: handleCreateSTI,
      onPatch: handlePatchSTI,
    },
    {
      usecase: "treasury_tickets",
      inputs: [
        {
          data: [amountInputCompanyCurrency],
        },
        {
          data: [creditDateInput, durationInput],
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
        },
        {
          data: [reasonsInput],
        },
        {
          data:
            loanRequest.default_reasons.length > 0 &&
            loanRequest.default_reasons.some(
              (reason) => reason.value === "ELSE",
            )
              ? [reasonELSEInput]
              : [],
        },
      ],
      inputsPatch: [],
      onCreate: handleCreate,
      onPatch: handlePatch,
    },
  ];

  const filterForm = () => {
    const form = forms.find((form) => form.usecase === usecase);

    return {
      inputs:
        patchID && form.inputsPatch.length > 0 ? form.inputsPatch : form.inputs,
      onSubmit: patchID ? form.onPatch : form.onCreate,
    };
  };

  // Page 2: patchID created invoice based on created loan request

  const handlePatchInvoice = async () => {
    const response = await api.patchInvoice(invoice.id, {
      ...invoice,
      type: invoice.type.value,
      currency: invoice.currency.value,
      counterparty: invoice.counterparty.value,
    });

    if (response.ok) {
      onEstimate(!patchID ? loanRequest.id : patchID);
    } else {
      const jsonErrors = await response.json();
      handleErrorsChange(jsonErrors, "invoice");
    }
  };

  const initData = async () => {
    const loanRequestResponse = await api.getLoanRequest(patchID);

    if (loanRequestResponse.ok) {
      const jsonLoanRequest = await loanRequestResponse.json();
      handleLoanRequestChange(jsonLoanRequest.amount, "amount");
      handleLoanRequestChange(
        currencies.find(
          (currency) => currency.value === jsonLoanRequest.currency,
        ),
        "currency",
      );

      handleLoanRequestChange(jsonLoanRequest.starting_date, "starting_date");
      handleLoanRequestChange(jsonLoanRequest.ending_date, "ending_date");

      handleLoanRequestChange(
        bankAccounts.find(
          (bankAccount) => bankAccount.value === jsonLoanRequest.bank_account,
        ),
        "bank_account",
      );

      if (jsonLoanRequest.months) {
        handleLoanRequestChange(jsonLoanRequest.months, "months");
      }

      if (jsonLoanRequest.default_reasons) {
        if (jsonLoanRequest.default_reasons.length > 0) {
          handleLoanRequestChange(
            jsonLoanRequest.default_reasons.map((reasonValue) =>
              reasonOptions.find(
                (reasonOption) => reasonOption.value === reasonValue,
              ),
            ),
            "default_reasons",
          );
        }
      }

      handleLoanRequestChange(jsonLoanRequest.custom_reason, "custom_reason");

      if (jsonLoanRequest.invoice) {
        const invoiceResponse = await api.getInvoice(jsonLoanRequest.invoice);
        if (invoiceResponse.ok) {
          const jsonInvoice = await invoiceResponse.json();

          handleInvoiceChange(jsonInvoice.reference, "reference");
          handleInvoiceChange(jsonInvoice.creation_date, "creation_date");
          handleInvoiceChange(
            jsonInvoice.amount_without_tax,
            "amount_without_tax",
          );
          handleInvoiceChange(jsonInvoice.amount_with_tax, "amount_with_tax");
          handleInvoiceChange(
            currencies.find(
              (currency) => currency.value === jsonInvoice.currency,
            ),
            "currency",
          );
          handleInvoiceChange(jsonInvoice.due_date, "due_date");
          handleInvoiceChange(
            counterparties.find(
              (counterparty) => counterparty.value === jsonInvoice.counterparty,
            ),
            "counterparty",
          );
          handleInvoiceChange(jsonLoanRequest.invoice, "id");
          handleInvoiceChange(jsonInvoice.file, "fileURL");
          handleInvoiceChange(
            invoiceTypes.find((type) => type.value === jsonInvoice.type),
            "type",
          );
        }
      }
    }

    setPage(1);
  };

  const init = () => {
    setLoading(true);
    initCreditLine();
    initBankAccounts();
    initCounterparties();
    initCurrencies();
    initCountries();
    initCompanyCodes();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setLoadingData(true);
    if (
      patchID &&
      !loading &&
      !loadingActiveCompany &&
      currencies.length > 0 &&
      counterparties.length > 0 &&
      bankAccounts.length > 0
    )
      initData();
    setLoadingData(false);
  }, [
    loading,
    loadingActiveCompany,
    currencies.length,
    counterparties.length,
    bankAccounts.length,
  ]);

  useEffect(() => {
    if (!patchID) {
      setPage(1);
    }
  }, [patchID]);

  if (!page || loading || loadingActiveCompany || loadingData) return <></>;

  if (page === 2)
    return (
      <Invoice
        invoice={invoice}
        onInvoiceChange={handleInvoiceChange}
        invoiceErrors={invoiceErrors}
        inputErrors={inputErrors}
        onBack={() => setPage(1)}
        onSubmit={handlePatchInvoice}
        counterparties={counterparties}
        currencies={currencies}
        companyCodes={companyCodes}
        availableCountries={availableCountries}
        onCounterpartyRefresh={() => init()}
      />
    );

  return (
    <Main themeSwitch>
      <Header
        heading={t("financing:fillout.step1.heading")}
        subheading={t("financing:fillout.step1.subheading")}
      />
      <Content
        style={{
          display: "flex",
          gap: "4rem",
          flexWrap: "wrap-reverse",
          justifyContent: creditLine ? "center" : "flex-start",
        }}
      >
        <div style={{ flexBasis: "50rem", flexGrow: creditLine ? "1" : "0" }}>
          <Form
            {...filterForm()}
            inputErrors={inputErrors}
            errors={loanRequestErrors.non_field_errors}
          />
        </div>
        {creditLine && (
          <div>
            <CreditLine {...creditLine} variant="gauge" />
          </div>
        )}
      </Content>
    </Main>
  );
}

function Invoice({
  invoice,
  onInvoiceChange,
  invoiceErrors,
  inputErrors,
  onBack,
  onSubmit,
  counterparties,
  currencies,
  companyCodes,
  availableCountries,
  onCounterpartyRefresh,
}) {
  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:fillout.title");

  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();

  const handleCreateCounterparty = () => {
    const handleCreatedCounterparty = (newCounterparty) => {
      onCounterpartyRefresh();
      onInvoiceChange(newCounterparty, "counterparty");
      dispatchToast({
        type: "add",
        variant: "success",
        subheading: t("financing:fillout.step2.toasts.counterparty.subheading"),
      });
    };

    const modal = {
      title: t("financing:fillout.step2.modals.counterparty.title"),
      message: t("financing:fillout.step2.modals.counterparty.message"),
      component: (
        <NewCounterparty
          onCreate={handleCreatedCounterparty}
          availableCountries={availableCountries}
          companyCodes={companyCodes}
        />
      ),
      size: "medium",
    };

    dispatchModal({
      type: "add",
      ...modal,
    });
  };

  const counterpartyInput = {
    type: "select",
    name: "counterparty",
    label: t("common:counterparty"),
    value: invoice.counterparty,
    onChange: (value) => onInvoiceChange(value, "counterparty"),
    options: counterparties,
    errors: invoiceErrors.counterparty,
    isSearchable: true,
    noOptionsMessage: (
      <Button
        style={{
          marginLeft: "auto",
          padding: "0.5rem 0",
          fontSize: "unset",
          fontWeight: "500",
          color: "var(--color-leano-2) !important",
        }}
        onClick={handleCreateCounterparty}
        text={t(
          "financing:fillout.step2.form.inputs.counterparty.noOptionsMessage",
        )}
        icon={
          <AddBox
            style={{ fontSize: "large" }}
            sx={{ color: "var(--color-leano-2) !important" }}
          />
        }
        iconLeft
      />
    ),
    placeholder: t(
      "financing:fillout.step2.form.inputs.counterparty.placeholder",
    ),
  };

  const invoiceRefInput = {
    type: "text",
    name: "invoiceRef",
    label: t("common:reference"),
    value: invoice.reference,
    onChange: (value) => onInvoiceChange(value, "reference"),
    errors: invoiceErrors.reference,
  };

  const invoiceCreationDateInput = {
    type: "date",
    name: "invoiceCreationDate",
    label: t("common:creationDate"),
    value: invoice.creation_date,
    onChange: (value) => onInvoiceChange(value, "creation_date"),
    errors: invoiceErrors.creation_date,
  };

  const invoiceAmountHTInput = {
    type: "number",
    name: "invoiceAmountHT",
    label: t("common:amountHT"),
    value: invoice.amount_without_tax,
    onChange: (value) => onInvoiceChange(value, "amount_without_tax"),
    errors: invoiceErrors.amount_without_tax,
    min: 0,
    onFormatNumber: (value) =>
      useFormatAmount(
        value,
        i18n.resolvedLanguage,
        invoice.currency && invoice.currency.value,
      ),
  };

  const invoiceAmountTTCInput = {
    type: "number",
    name: "invoiceAmountTTC",
    label: t("common:amountTTC"),
    value: invoice.amount_with_tax,
    onChange: (value) => onInvoiceChange(value, "amount_with_tax"),
    errors: invoiceErrors.amount_with_tax,
    min: 0,
    onFormatNumber: (value) =>
      useFormatAmount(
        value,
        i18n.resolvedLanguage,
        invoice.currency && invoice.currency.value,
      ),
  };

  const invoiceCurrencyInput = {
    type: "select",
    name: "invoiceCurrency",
    label: t("common:currency"),
    value: invoice.currency,
    onChange: (value) => onInvoiceChange(value, "currency"),
    options: currencies,
    errors: invoiceErrors.currency,
  };

  const invoiceDueDateInput = {
    type: "date",
    name: "invoiceDueDate",
    label: t("common:dueDate"),
    value: invoice.due_date,
    onChange: (value) => onInvoiceChange(value, "due_date"),
    errors: invoiceErrors.due_date,
  };

  const fillInputs = [
    {
      data: [
        counterpartyInput,
        invoiceRefInput,
        invoiceCreationDateInput,
        invoiceAmountHTInput,
        invoiceAmountTTCInput,
        invoiceCurrencyInput,
        invoiceDueDateInput,
      ],
    },
  ];

  return (
    <Main themeSwitch>
      <Header
        heading={t("financing:fillout.step2.heading")}
        onBackButtonClick={onBack}
      />
      <Content
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "4rem",
        }}
      >
        <iframe
          style={{
            flexBasis: "30rem",
            flexGrow: "1",
            border: "none",
            boxShadow: "0px 4px 4px 0px var(--color-neutral-2)",
            borderRadius: "0.65rem",
            height: "70svh",
          }}
          src={invoice.fileURL}
        />
        <div style={{ flexBasis: "30rem" }}>
          <Form
            inputs={fillInputs}
            onSubmit={onSubmit}
            button={t("financing:fillout.step2.form.button")}
            inputErrors={inputErrors}
            errors={invoiceErrors.non_field_errors}
          />
        </div>
      </Content>
    </Main>
  );
}

function NewCounterparty({ onCreate, availableCountries, companyCodes }) {
  const { t, i18n } = useTranslation("common", "financing");
  const countries = useCountries();
  const dispatchModal = useModalDispatch();

  const [counterparty, setCounterparty] = useState({
    name: "",
    country: null,
    identifier: "",
  });

  const initialErrors = {
    name: [],
    country: [],
    identifier: [],
    non_field_errors: [],
  };

  const [counterpartyErrors, setCounterpartyErrors] = useState(initialErrors);
  const [inputErrors, setInputErrors] = useState(false);

  const handleCounterpartyChange = (newValue, field) => {
    setCounterparty((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const nameInput = {
    type: "text",
    name: "name",
    label: t("common:name"),
    value: counterparty.name,
    onChange: (value) => handleCounterpartyChange(value, "name"),
    errors: counterpartyErrors.name,
  };

  const countryInput = {
    type: "radio",
    name: "country",
    label: t("common:country"),
    value: counterparty.country,
    onChange: (value) => handleCounterpartyChange(value, "country"),
    options: Object.entries(
      countries.getNames(i18n.resolvedLanguage, { select: "official" }),
    )
      .map(([alpha2Code, name]) => {
        return {
          label: name,
          value: alpha2Code,
        };
      })
      .filter((country) =>
        availableCountries.some(
          (availableCountry) => availableCountry === country.value,
        ),
      ),
    isSearchable: true,
    errors: counterpartyErrors.country,
  };

  const IDInput = {
    type: "text",
    name: "identifier",
    placeholder:
      counterparty.country && companyCodes[counterparty.country.value],
    label: t("common:identifier"),
    value: counterparty.identifier,
    onChange: (value) => handleCounterpartyChange(value, "identifier"),
    errors: counterpartyErrors.identifier,
  };

  const inputs = [
    {
      data: [nameInput],
    },
    {
      data: [countryInput],
    },
    {
      data: [IDInput],
    },
  ];

  const handleSubmit = async () => {
    setCounterpartyErrors(initialErrors);
    setInputErrors(false);
    const createdResponse = await api.createCounterparty({
      ...counterparty,
      country: counterparty.country.value,
    });

    if (createdResponse.ok) {
      const createdCounterparty = await createdResponse.json();
      const newCounterpartiesResponse = await api.getCounterparties(true);

      if (newCounterpartiesResponse.ok) {
        const newCounterparties = await newCounterpartiesResponse.json();

        const newCounterparty_formatted = newCounterparties.filter(
          (counterparty) => counterparty.id === createdCounterparty.id,
        )[0];

        dispatchModal({
          type: "success",
          onSuccess: () =>
            onCreate({
              label: newCounterparty_formatted.label,
              value: newCounterparty_formatted.id,
            }),
        });
      }
    } else {
      const jsonErrors = await createdResponse.json();
      setCounterpartyErrors(jsonErrors);
      setInputErrors(true);
    }
  };

  return (
    <Form
      inputs={inputs}
      onSubmit={handleSubmit}
      button={t("financing:fillout.step2.modals.counterparty.button")}
      inputErrors={inputErrors}
      errors={counterpartyErrors.non_field_errors}
    />
  );
}
