import {
  Checklist,
  Clear,
  DeleteForever,
  InsertDriveFile,
  OpenInBrowser,
} from "@mui/icons-material";
import Button from "./Button";
import { FormLoader } from "./Form";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import EmptySection from "./EmptySection";
import { useToastsDispatch } from "./Toasts/ToastsContext";

export default function Files({
  files = [
    {
      id: 0,
      name: "document 1",
      date: new Date(),
      url: "",
      onDelete: null,
    },
    {
      id: 1,
      name: "document 2",
      date: new Date(),
      url: "",
      onDelete: null,
    },
  ],
  loadingFiles = false,
  sortByDate = false,
}) {
  const { t } = useTranslation(["common", "vault"]);

  const sortByDescendingDate = (a, b) =>
    sortByDate && (a.date < b.date ? 1 : -1);

  return loadingFiles ? (
    <FormLoader />
  ) : files.length === 0 ? (
    <EmptySection icon={<Checklist />} text={t("vault:common.files.none")} />
  ) : (
    <div className="Files">
      <div className="Files-header">{t("vault:common.files.title")}</div>
      <div className="Files-container">
        {files.sort(sortByDescendingDate).map((file) => (
          <div className="Files-container-File-wrapper" key={file.id}>
            <MyFile {...file} />
          </div>
        ))}
      </div>
    </div>
  );
}

export function MyFile({ name, url, onDelete, card }) {
  const dispatchToast = useToastsDispatch();
  const { t } = useTranslation(["common", "vault"]);

  return (
    <div className={["File", card ? "Card" : ""].filter(Boolean).join(" ")}>
      {card && (
        <div className="File-icon">
          <InsertDriveFile />
        </div>
      )}
      <div className="File-text">{name}</div>
      <div className="File-buttons">
        {!card && (
          <Button
            icon={<OpenInBrowser />}
            url={url}
            isExternalURL={true}
            title={t("common:show")}
            // new tab - bug
          />
        )}
        <Button
          icon={card ? <Clear /> : <DeleteForever />}
          title={card ? "" : t("common:delete")}
          onClick={() => {
            const done = onDelete();
            if (done) {
              const subheading = (
                <Trans
                  t={t}
                  i18nKey="vault:common.toasts.fileDeleteSuccess.subheading"
                  values={{ document: name }}
                />
              );
              dispatchToast({
                type: "add",
                variant: "success",
                heading: t("vault:common.toasts.fileDeleteSuccess.heading"),
                subheading: subheading,
              });
            }
          }}
        />
      </div>
    </div>
  );
}
