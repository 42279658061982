// Routes/Paths list used across the app

const account = "/account";
const company = "/company";
const settings = "/settings";
const banking = "/banking";
const vault = "/vault";
const notifications = "/notifications";
const reseller = "/reseller";
const financing = "/financing";

const routes = {
  Test: "/test",
  // External links
  EmailSupport: "support@leano.fr",
  EmailInfo: "info@leano.fr",
  TermsOfService: "https://leano.fr/condition-generales-utilisation/",
  PrivacyPolicy: "https://leano.fr/politique-de-confidentialite/",
  // Error page
  Error500: "/error",
  // Home pages
  Home: "/",
  Account: {
    root: account,
    SignUp: account + "/sign-up",
    EmailConfirmation: account + "/confirm",
    Login: account + "/sign-in",
    Logout: account + "/sign-out",
    PasswordReset: account + "/password/reset",
    Inactive: account + "/inactive",
  },
  Company: {
    root: company,
    reps: company + "/representants",
  },
  Settings: {
    root: settings,
    profile: settings + "/profile",
    password: settings + "/password",
    users: settings + "/users",
    company: settings + "/company",
    subscription: settings + "/subscription",
  },
  Banking: {
    root: process.env.REACT_APP_API_URL + banking + "/",
    treasury: process.env.REACT_APP_API_URL + "/banking-api/treasury",
    downloadStatements:
      process.env.REACT_APP_API_URL + "/banking-api/download-statements",
  },
  Notifications: {
    root: notifications,
  },
  Stripe: {
    root: `https://billing.stripe.com/p/login/${process.env.REACT_APP_STRIPE_LOGIN}`,
  },
  Vault: {
    root: vault,
    bank: vault + "/banking",
    finance: vault + "/statements",
    id: vault + "/identity",
    other: vault + "/documents",
  },
  VaultAllow: "/allow-vault",
  Reseller: {
    root: reseller,
    clients: reseller + "/clients",
    assessment: reseller + "/clients/assess",
  },
  Financing: {
    root: financing,
    assess: financing + "/dashboard",
    unlock: financing + "/use-cases",
    request: financing + "/request",
    loans: financing + "/loans",
  },
};

export default routes;

export const dashboardRoute = routes.Financing.assess;

export const getRelativePath = (absolutePath) =>
  absolutePath.split("/")[absolutePath.split("/").length - 1];
