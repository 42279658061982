import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useRootRedirect(root, to) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === root || location.pathname === `${root}/`) {
      navigate(to, { replace: true });
    }
  }, [location, navigate]);
}
