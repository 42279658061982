import { useTranslation } from "react-i18next";
import { Setting, SettingDisplay } from "../Settings";

export default function PlanSet({ value }) {
  const { t } = useTranslation(["common", "settings"]);

  return (
    <Setting>
      <SettingDisplay
        label={t("settings:items.subscription.plan")}
        value={t(`settings:items.subscription.plans.${value}`)}
      />
    </Setting>
  );
}
