import PasswordSet from "./PasswordSet";
import { SettingList } from "../Settings";
import { useTranslation } from "react-i18next";

export default function Password() {
  const { t } = useTranslation();
  document.title = t("settings:tabs.password.title");

  return (
    <SettingList>
      <PasswordSet />
    </SettingList>
  );
}
