export default function Card({
  className,
  children,
  onClick = null,
  pop = false,
  row = false,
}) {
  return (
    <div
      className={[
        "CardNew",
        className,
        onClick ? "clickable" : "",
        pop ? "pop" : "",
        row ? "row" : "",
      ]
        .filter(Boolean)
        .join(" ")}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export function CardText({ heading, subheading, children, row = false }) {
  return (
    <div className={["Card-text", row ? "row" : ""].filter(Boolean).join(" ")}>
      {heading && <div className="Card-text-heading">{heading}</div>}
      {subheading && <div className="Card-text-subheading">{subheading}</div>}
      {children}
    </div>
  );
}

export function Cards({ className, children }) {
  return (
    <div className={["CardsNew", className].filter(Boolean).join(" ")}>
      {children}
    </div>
  );
}
