import { useAuth } from "../../../auth/AuthContext";
import routes from "../../../routes/routes";
import { useNavigate } from "react-router-dom";

export default function UsersRedirect({ children }) {
  const { user, loadingUser } = useAuth();
  const navigate = useNavigate();
  if (loadingUser) return <></>;
  if (!user.is_admin) navigate(routes.Settings.company);
  return children;
}
