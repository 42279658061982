// Error pages (404 and 500)

import { useTranslation } from "react-i18next";
import error404Image from "../../assets/errors/error404.svg";
import error500Image from "../../assets/errors/error500.svg";
import error500Smiley from "../../assets/errors/error500Smiley.svg";
import { Main } from "../commons/Template";
import { useRouteError } from "react-router-dom";

export default function ErrorBoundary() {
  const error = useRouteError();
  return <ErrorPage code={error.status === 404 ? 404 : 500} />;
}

function ErrorPage({ code }) {
  const { t } = useTranslation(["common", "errors"]);

  document.title = t(`errors:error${code}.title`);

  const images = {
    404: error404Image,
    500: error500Image,
  };

  return (
    <Main themeSwitch className="ErrorPage">
      <div className="ErrorPage-header">
        <div className="ErrorPage-message">
          <h1>{t(`errors:error${code}.header`)}</h1>
          <p>{t(`errors:error${code}.message`)}</p>
        </div>
        {code === 500 && (
          <div className="ErrorPage-smiley">
            <img src={error500Smiley} alt=":(" />
          </div>
        )}
      </div>
      <div className="ErrorPage-image">
        <img src={images[code]} alt="Error" />
      </div>
    </Main>
  );
}
