import { createTheme } from "@mui/material/styles";

export const switchTheme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        track: {
          // Track colour
          // Unchecked
          backgroundColor: "var(--color-neutral-3)",
          opacity: 1,
          // Checked
          ".Mui-checked.Mui-checked + &": {
            backgroundColor: "var(--color-leano-2)",
            opacity: 0.75,
          },
        },
        // Thumb colour
        switchBase: { color: "var(--color-leano-1)" }, // unchecked
        colorPrimary: { "&.Mui-checked": { color: "var(--color-leano-2)" } }, // checked
      },
    },
  },
});
