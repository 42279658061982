import Toast from "./Toast";
import { useToasts } from "./ToastsContext";

export default function Toasts() {
  const toasts = useToasts();

  return toasts.length > 0 ? (
    <div className="Toasts-wrapper">
      {toasts.map((toast) => {
        return <Toast key={toast.id} toast={toast} />;
      })}
    </div>
  ) : null;
}
