import api from "../../api/resources";
import { Trans, useTranslation } from "react-i18next";
import routes, { dashboardRoute } from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddCircleOutline, Business, Undo } from "@mui/icons-material";
import { Popover } from "@mui/material";
import Button from "../commons/Button";
import Form from "../commons/Form";

export default function CompanySwitch() {
  const { t } = useTranslation(["common", "navigation"]);
  const {
    activeCompany,
    loadingActiveCompany,
    companies,
    loadingCompanies,
    reseller,
    user,
    loadingUser,
  } = useAuth();
  const navigate = useNavigate();

  // popover
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const handleActivateCompany = async (companyID) => {
    const response = await api.setActiveCompany(companyID);
    if (response.ok) {
      handleClose();
      window.location.href = dashboardRoute; // not using useNavigate because needs page refresh
    }
  };

  const handleAddCompany = () => {
    handleClose();
    navigate(routes.Company.root);
    window.location.reload();
  };

  if (loadingUser || loadingActiveCompany || loadingCompanies) return <></>;
  return (
    <div className="CompanySwitch" style={{ color: "white" }}>
      {!reseller && (
        <div className="CompanySwitch-button">
          <Button
            title={t("navigation:companySwitch.list.title")}
            icon={<Business />}
            onClick={handleClick}
          />
        </div>
      )}

      <Popover
        className="CompanySwitch-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <span className="CompanySwitch-popover-title">
          {t("navigation:companySwitch.list.title")}
        </span>
        <span className="CompanySwitch-popover-hello">
          <Trans
            t={t}
            shouldUnescape
            tOptions={{ interpolation: { escapeValue: true } }}
            i18nKey="navigation:companySwitch.list.hello"
          >
            {{ user: user.name }}
            <span
              className="CompanySwitch-popover-hello-company"
              style={{ color: activeCompany.colour }}
            >
              {{ activeCompany: activeCompany.name }}
            </span>
          </Trans>
        </span>

        <CompanySwitchComponent
          companies={companies}
          activeCompany={activeCompany}
          reseller={reseller}
          onActivate={handleActivateCompany}
          onAdd={handleAddCompany}
        />
      </Popover>
    </div>
  );
}

function CompanySwitchComponent({
  companies,
  activeCompany,
  reseller,
  onActivate,
  onAdd,
}) {
  const { t } = useTranslation(["common", "settings", "navigation"]);
  const isReseller = (company) => reseller && company.name === reseller.name;

  const [selectedCompany, setSelectedCompany] = useState(null);

  const inputs = [
    {
      label: t("navigation:companySwitch.list.otherCompanies"),
      type: "radio",
      name: "companies",
      value: selectedCompany,
      onChange: setSelectedCompany,
      options: companies
        // Remove active company
        .filter((company) => company.id !== activeCompany.id)
        // Remove reseller company
        .filter((company) => !isReseller(company))
        .map((company) => ({
          value: company.id,
          label: company.name,
        })),
    },
  ];

  return (
    <div className="CompanySwitch-popover-component">
      {companies.length > 1 && (
        <Form
          className="CompanySwitch-popover-component-switch"
          inputs={[{ data: inputs }]}
          onSubmit={() => onActivate(selectedCompany.value)}
        />
      )}
      <Button className="CompanySwitch-popover-component-add" onClick={onAdd}>
        <AddCircleOutline />{" "}
        <span>{t("navigation:companySwitch.list.add")}</span>
      </Button>
    </div>
  );
}

export function CompanySwitchBanner() {
  const { t } = useTranslation(["common", "navigation"]);
  const { activeCompany, loadingActiveCompany, loadingCompanies, reseller } =
    useAuth();

  const handleActivateCompany = async (companyID) => {
    const response = await api.setActiveCompany(companyID);
    if (response.ok) {
      window.location.href = dashboardRoute; // not using useNavigate because needs page refresh
    }
  };

  if (loadingActiveCompany || loadingCompanies) return <></>;
  return (
    <div
      className="CompanySwitchBanner"
      style={{
        backgroundColor: reseller
          ? "var(--color-leano-8)"
          : "var(--color-leano-9)",
      }}
    >
      <div
        className="CompanySwitchBanner-container"
        style={{ color: reseller ? "var(--color-leano-1)" : "white" }}
      >
        <div>
          <Trans
            t={t}
            shouldUnescape
            tOptions={{ interpolation: { escapeValue: true } }}
            i18nKey="navigation:companySwitch.banner.manage"
          >
            You are managing
            <span
              className="activeCompany"
              style={{ color: reseller ? "white" : activeCompany.colour }}
            >
              {{ activeCompany: activeCompany.name }}
            </span>
          </Trans>
        </div>
        {reseller && (
          <Button onClick={() => handleActivateCompany(reseller.id)}>
            <div
              style={{
                display: "flex",
                gap: "0.3rem",
              }}
            >
              <span style={{ textAlign: "right" }}>
                <Trans
                  t={t}
                  shouldUnescape
                  tOptions={{ interpolation: { escapeValue: true } }}
                  i18nKey="navigation:companySwitch.banner.back"
                >
                  Back to
                  <span style={{ fontWeight: "800" }}>
                    {{ resellerCompany: reseller.name }}
                  </span>
                </Trans>
              </span>
              <Undo fontSize="x-small" />
            </div>
          </Button>
        )}
      </div>
    </div>
  );
}
