import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
} from "react-router-dom";
import PublicRouteWrapper from "../features/commons/PublicRouteWrapper";
import PrivateRouteWrapper from "../features/commons/PrivateRouteWrapper";
import routes, { dashboardRoute, getRelativePath } from "./routes";
import EmailConfirmation from "../features/Account/EmailConfirmation";
import ErrorBoundary from "../features/Errors/Errors";
import Home from "../features/Account/Home";
import Inactive from "../features/Account/Inactive";
import Login from "../features/Account/Login";
import Root from "../features/Root";
import PasswordResetForm from "../features/Account/PasswordResetForm";
import PasswordResetKickOff from "../features/Account/PasswordResetKickOff";
import SignUp from "../features/Account/SignUp";
import Company from "../features/Company/Company";
import CompanyDetails from "../features/Company/CompanyDetails";
import CompanyReps from "../features/Company/CompanyReps";
import Settings from "../features/Settings/Settings";
import Profile from "../features/Settings/Profile/Profile";
import Password from "../features/Settings/Password/Password";
import { Company as CompanySet } from "../features/Settings/Company/Company";
import Subscription from "../features/Settings/Subscription/Subscription";
import Template from "../features/commons/Template";
import Notifications from "../features/Notifications/Notifications";
import Vault from "../features/Vault/Vault";
import Bank from "../features/Vault/Bank";
import Finance from "../features/Vault/Finance";
import ID from "../features/Vault/ID";
import Other from "../features/Vault/Other";
import PasswordProtector, {
  PasswordProtectorMirror,
} from "../features/commons/PasswordProtector";
import Reseller from "../features/Reseller/Reseller";
import Clients from "../features/Reseller/Clients";
import Assessment from "../features/Financing/Assessment";
import ResellerRoute from "../features/Reseller/ResellerRoute";
import Account from "../features/Account/Account";
import Users from "../features/Settings/Users/Users";
import UsersRedirect from "../features/Settings/Users/UsersRedirect";
import Financing from "../features/Financing/Financing";
import Unlock from "../features/Financing/Unlock";
import DashboardRoute from "./DashboardRoute";
import Request from "../features/Financing/Request";
import Loans from "../features/Financing/Loans";

export default function RouterProvider() {
  const routesObj = {
    element: <Root />,
    path: "/",
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: (
          <PublicRouteWrapper>
            <Home />
          </PublicRouteWrapper>
        ),
      },
      {
        path: routes.Account.root,
        element: <Account />,
        children: [
          {
            path: getRelativePath(routes.Account.Login),
            element: (
              <PublicRouteWrapper>
                <Template>
                  <Login />
                </Template>
              </PublicRouteWrapper>
            ),
          },
          {
            path: getRelativePath(routes.Account.SignUp),
            element: (
              <PublicRouteWrapper>
                <Template>
                  <SignUp />
                </Template>
              </PublicRouteWrapper>
            ),
          },
          {
            path: `${getRelativePath(routes.Account.EmailConfirmation)}/:key`,
            element: (
              <Template>
                <EmailConfirmation />
              </Template>
            ),
          },
          {
            path: getRelativePath(routes.Account.Inactive),
            element: (
              <PublicRouteWrapper>
                <Template>
                  <Inactive />
                </Template>
              </PublicRouteWrapper>
            ),
          },
          {
            path: routes.Account.PasswordReset,
            element: (
              <Template>
                <PasswordResetKickOff />
              </Template>
            ),
          },
          {
            path: `${routes.Account.PasswordReset}/:key`,
            element: (
              <Template>
                <PasswordResetForm />
              </Template>
            ),
          },
        ],
      },
      {
        path: routes.Company.root,
        element: (
          <PrivateRouteWrapper>
            <Template>
              <Company />
            </Template>
          </PrivateRouteWrapper>
        ),
        children: [
          {
            path: routes.Company.root,
            element: <CompanyDetails />,
          },
          {
            path: getRelativePath(routes.Company.reps),
            element: <CompanyReps />,
          },
        ],
      },
      {
        path: routes.VaultAllow,
        element: <PasswordProtectorMirror />,
      },
      {
        path: routes.Settings.root,
        element: (
          <PrivateRouteWrapper>
            <Template>
              <Settings />
            </Template>
          </PrivateRouteWrapper>
        ),
        children: [
          {
            path: getRelativePath(routes.Settings.profile),
            element: <Profile />,
          },
          {
            path: getRelativePath(routes.Settings.password),
            element: <Password />,
          },
          {
            path: getRelativePath(routes.Settings.users),
            element: (
              <UsersRedirect>
                <Users />
              </UsersRedirect>
            ),
          },
          {
            path: getRelativePath(routes.Settings.company),
            element: <CompanySet />,
          },
          {
            path: getRelativePath(routes.Settings.subscription),
            element: <Subscription />,
          },
        ],
      },
      {
        element: (
          <PrivateRouteWrapper>
            <Template>
              <Notifications />
            </Template>
          </PrivateRouteWrapper>
        ),
        path: routes.Notifications.root,
      },
      {
        element: (
          <PrivateRouteWrapper>
            <PasswordProtector>
              <Template>
                <Vault />
              </Template>
            </PasswordProtector>
          </PrivateRouteWrapper>
        ),
        path: routes.Vault.root,
        children: [
          {
            path: getRelativePath(routes.Vault.bank),
            element: <Bank />,
          },
          {
            path: getRelativePath(routes.Vault.finance),
            element: <Finance />,
          },
          {
            path: getRelativePath(routes.Vault.id),
            element: <ID />,
          },
          {
            path: getRelativePath(routes.Vault.other),
            element: <Other />,
          },
        ],
      },
      {
        element: (
          <PrivateRouteWrapper>
            <ResellerRoute>
              <Template>
                <Reseller />
              </Template>
            </ResellerRoute>
          </PrivateRouteWrapper>
        ),
        path: routes.Reseller.root,
        children: [
          {
            path: getRelativePath(routes.Reseller.clients),
            element: <Clients />,
          },
          {
            path: `${routes.Reseller.assessment}/:clientID`,
            element: <Assessment reseller />,
          },
        ],
      },
      {
        element: (
          <PrivateRouteWrapper>
            <Template>
              <Financing />
            </Template>
          </PrivateRouteWrapper>
        ),
        path: routes.Financing.root,
        children: [
          {
            element: (
              <DashboardRoute>
                <Assessment />
              </DashboardRoute>
            ),
            path: getRelativePath(dashboardRoute),
          },
          {
            element: <Unlock />,
            path: getRelativePath(routes.Financing.unlock),
          },
          {
            element: <Request />,
            path: getRelativePath(routes.Financing.request),
          },
          {
            element: <Loans />,
            path: getRelativePath(routes.Financing.loans),
          },
        ],
      },
    ],
  };

  const appRouter = createBrowserRouter([routesObj]);

  return <ReactRouterProvider router={appRouter} />;
}
