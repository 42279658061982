import { Trans, useTranslation } from "react-i18next";
import { i18n } from "../../i18n";
import { formatAmount } from "../../format";
import { AccountBalanceWallet } from "@mui/icons-material";
import creditLineImg from "../../assets/credit/creditLine.png";
import { capitalize } from "../../utils";
import ImageContainer from "../commons/ImageContainer";
import Card, { CardText } from "../commons/Card";
import { Gauge } from "@mui/x-charts";

export default function CreditLine({
  available,
  max,
  currency,
  variant = "small",
}) {
  const { t } = useTranslation(["common", "financing"]);

  if (variant === "small")
    return (
      <div className="CreditLineSmall">
        <div className="CreditLineSmall-title">
          {t("financing:unlock.usecases.creditLine.title")}
        </div>
        <div className="CreditLineSmall-amounts">
          {formatAmount(available, i18n.resolvedLanguage, currency)} /{" "}
          {formatAmount(max, i18n.resolvedLanguage, currency)}
        </div>
      </div>
    );

  if (variant === "card")
    return (
      <Card className="CreditLineCard">
        <ImageContainer
          className="CreditLineCard-img"
          src={creditLineImg}
          alt="credit-illustration"
        />

        <div className="CreditLineCard-text">
          <div className="CreditLineCard-title">
            {t("financing:unlock.usecases.creditLine.title")}
          </div>
          <CreditLineCardAmount
            color={"var(--color-success-1)"}
            tag={capitalize(t("common:available"))}
            amount={formatAmount(available, i18n.resolvedLanguage, currency)}
          />
          <CreditLineCardAmount
            color={"var(--color-leano-2"}
            tag={t("common:total")}
            amount={formatAmount(max, i18n.resolvedLanguage, currency)}
          />
        </div>
      </Card>
    );

  if (variant === "gauge")
    return (
      <Card className="CreditLineGaugeCard">
        <CardText
          heading={t("financing:unlock.usecases.creditLine.available")}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Gauge
            cornerRadius={"50%"}
            valueMin={0}
            valueMax={max}
            width={200}
            height={100}
            value={available}
            startAngle={-90}
            endAngle={90}
          />
          <div className="CreditLineGaugeCard-available">
            {formatAmount(available, i18n.resolvedLanguage, currency)}
          </div>
          <div className="CreditLineGaugeCard-max">
            <Trans
              t={t}
              i18nKey="financing:unlock.usecases.creditLine.max"
              components={{
                label: <span className="CreditLineGaugeCard-max-label" />,
                value: <span className="CreditLineGaugeCard-max-value" />,
              }}
              values={{
                max: formatAmount(max, i18n.resolvedLanguage, currency),
              }}
            />
          </div>
        </div>
      </Card>
    );
}

function CreditLineCardAmount({ color, tag, amount }) {
  return (
    <div className="CreditLineCard-amount">
      <div className="CreditLineCard-amount-icon" style={{ color: color }}>
        <AccountBalanceWallet />
      </div>
      <div className="CreditLineCard-amount-content">
        <div className="CreditLineCard-amount-content-tag">{tag}</div>
        <div className="CreditLineCard-amount-content-number">{amount}</div>
      </div>
    </div>
  );
}
