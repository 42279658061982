import { createContext, useContext, useEffect, useReducer } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const HelperContext = createContext(null);

const HelperDispatchContext = createContext(null);

export function HelperProvider({ children }) {
  const [helper, dispatchHelper] = useReducer(HelperReducer, initialHelper);

  // on init : dispatch a helper from cookies if previously set
  useEffect(() => {
    const cookieHelper = cookies.get("helper", { path: "/" });
    if (cookieHelper) {
      dispatchHelper({
        type: "add",
        ...cookieHelper,
      });
    }
  }, []);

  return (
    <HelperContext.Provider value={helper}>
      <HelperDispatchContext.Provider value={dispatchHelper}>
        {children}
      </HelperDispatchContext.Provider>
    </HelperContext.Provider>
  );
}

export function useHelper() {
  return useContext(HelperContext);
}

export function useHelperDispatch() {
  return useContext(HelperDispatchContext);
}

function HelperReducer(existingHelper, action) {
  const { type, ...newHelper } = action;

  switch (type) {
    case "add": {
      const newHelperWrapped = {
        ...newHelper,
        expand: newHelper.expand || false,
      };

      cookies.set("helper", newHelperWrapped, { path: "/" });
      return newHelperWrapped;
    }

    case "toggle": {
      const existingHelperWrapped = {
        ...existingHelper,
        expand: !existingHelper.expand,
      };

      // save user expand setting between page refreshs (only for current helper instance)
      cookies.set("helper", existingHelperWrapped, { path: "/" });

      return existingHelperWrapped;
    }

    case "delete": {
      cookies.remove("helper", { path: "/" });
      return null;
    }

    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

const initialHelper = null;
