export function TableWrapper({ children }) {
  return <div className="TableWrapper">{children}</div>;
}

export function Table({ children }) {
  return (
    <table className="Table">
      <tbody>{children}</tbody>
    </table>
  );
}

export function TableRow({ children }) {
  return <tr>{children}</tr>;
}

export function TableRowHeader({ thLabel, thValue }) {
  return (
    <th scope="row">
      <div className="th-component">
        <div className="label">{thLabel}</div>
        <div className="value">{thValue}</div>
      </div>
    </th>
  );
}

export function TableRowItem({ label, value, component, className }) {
  return (
    <td>
      <div
        className={[
          "td-component",
          className && className === "styleLastTd" ? "styleLastTd" : "",
        ]
          .filter(Boolean)
          .join(" ")}
      >
        {label && <div className="label">{label}</div>}
        {value && <div className="value">{component ? component : value}</div>}
      </div>
    </td>
  );
}
