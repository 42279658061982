import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import i18n from "i18next";

// To be refactored
const availableLanguages = { en: "English", fr: "Français", it: "Italiano" };

const initOptions = {
  debug: process.env.REACT_APP_ENV !== "production",
  ns: [
    "account",
    "common",
    "company",
    "errors",
    "navigation",
    "notifications",
    "reseller",
    "settings",
    "vault",
    "financing",
  ],
  defaultNS: "common",
  supportedLngs: ["fr", "en", "it"],
  fallbackLng: "fr",
  load: "languageOnly",
  nonExplicitSupportedLngs: true,
  backend: {
    loadPath: process.env.REACT_APP_API_URL + "/react-i18n/{{lng}}/{{ns}}",
  },
};

i18n.use(backend).use(detector).use(initReactI18next).init(initOptions);

export { i18n, availableLanguages };
