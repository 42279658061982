import { useEffect, useState } from "react";
import Input from "../commons/Input";
import { formatDateTime } from "../../format";
import Button from "../commons/Button";
import { Badge } from "@mui/material";
import { i18n } from "../../i18n";
import Card from "../commons/Card";

export default function Notification({
  id,
  title,
  timestamp,
  message,
  url,
  isExternalURL,
  button,
  selectedByControl,
  read,
  onAdd,
  onRemove,
  selectAllShown,
}) {
  const [selected, setSelected] = useState(selectedByControl);

  useEffect(() => {
    setSelected(selectedByControl);
  }, [selectedByControl]);

  useEffect(() => {
    if (selected) {
      onAdd(id);
    } else {
      onRemove(id);
    }
  }, [selected, id, onAdd, onRemove]);

  return (
    <div className={`Notification ${!read ? "Notification_unread" : ""}`}>
      {selectAllShown ? (
        <div className="Notification-select">
          <Input
            type="checkbox"
            value={read ? false : selected}
            onChange={setSelected}
            disabled={read}
          />
        </div>
      ) : null}
      <div className="Notification-content-container">
        <Card className="Notification-content">
          <div className="Notification-content-header">
            <div className="Notification-content-header-title">
              <span>
                <strong>{title}</strong>
              </span>
            </div>
            <div className="Notification-content-header-timestamp">
              <span className="Notification-content-header-timestamp_long">
                {formatDateTime(timestamp, i18n.resolvedLanguage)}
              </span>
              <span className="Notification-content-header-timestamp_short">
                {formatDateTime(timestamp, i18n.resolvedLanguage, "short")}
              </span>
            </div>
          </div>
          <div className="Notification-content-body">
            <div className="Notitication-content-body-message">{message}</div>
            {url ? (
              <Button
                text={button}
                url={url}
                isExternalURL={isExternalURL}
                variant="secondary_b"
              />
            ) : null}
          </div>
        </Card>

        <Badge
          sx={{
            "& .MuiBadge-dot": {
              backgroundColor: "var(--color-error-1)",
              width: "1rem",
              height: "1rem",
              borderRadius: "50%",
            },
          }}
          overlap="circular"
          variant="dot"
          badgeContent={!read ? 1 : 0}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div></div>{" "}
          {/*empty div to hold badge because using it on content breaks layout*/}
        </Badge>
      </div>
    </div>
  );
}
