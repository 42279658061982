import { format } from "date-fns";
import { fr, enGB, it } from "date-fns/locale";
const locales = { fr: fr, en: enGB, it: it };

/**
 * Get date formatter according to given format
 * @param {String} dateFormat the desired format
 * @returns {String} the date formatter
 */
function getDateFormatter(dateFormat = "standard") {
  switch (dateFormat) {
    case "short":
      return "d-M-y";
    case "month-day":
      return "d MMMM";
    case "year-month":
      return "MMMM y";
    default:
      return "d MMMM y";
  }
}

/**
 * Get time formatter
 * @param {String} languageCode language code
 * @returns {String} the time formatter
 */
function getTimeFormatter(languageCode) {
  switch (languageCode) {
    case "fr":
      return "H'h'mm";
    case "en":
      return "K:mm a";
    default:
      return "H:mm";
  }
}

/**
 * Format address in a pretty readable way (using different lines)
 * @param {object} address the full address object
 * @param {String} lang language code
 * @param {boolean} complements allow to choose whether to print address "line 2" and "line 3"
 * @return {Array} the formatted address
 */
export function formatAddress(address, lang, complements = false) {
  const code = getLanguageCode(lang);
  let formattedAddress = [];
  address.line_1 && formattedAddress.push(address.line_1);
  if (complements) {
    if (address.line_2) {
      formattedAddress.push(address.line_2);
    }
    if (address.line_3) {
      formattedAddress.push(address.line_3);
    }
  }
  const codeTown = [address.code, address.town]
    .filter((x) => Boolean(x))
    .join(" ");
  if (codeTown) {
    formattedAddress.push(codeTown);
  }
  let regionNames = new Intl.DisplayNames([code], { type: "region" });
  let country = regionNames.of(address.country);
  formattedAddress.push(country);
  return formattedAddress;
}

/**
 * Format date
 * @param {String} date date as string in ISO format
 * @param {String} lang language code
 * @param {String} dateFormat the desired format
 * @returns {String} the formatted date
 */
export function formatDate(date, lang, dateFormat = "standard") {
  const formatter = getDateFormatter(dateFormat);
  const code = getLanguageCode(lang);
  return format(new Date(date), formatter, { locale: locales[code] });
}

/**
 * Format date-time
 * @param {String} dateTime date-time as string in ISO format
 * @param {String} lang language code
 * @param {String} dateTimeFormat the desired format (`short` or `long`)
 * @returns {String} the formatted date-time
 */
export function formatDateTime(dateTime, lang, dateTimeFormat) {
  const code = getLanguageCode(lang);
  // Date formatter
  var formatter = getDateFormatter(dateTimeFormat);
  // Date-time connector
  if (dateTimeFormat === "short") {
    formatter += " ";
  } else {
    if (code === "fr") {
      formatter += " à ";
    } else {
      formatter += ", ";
    }
  }
  // Time formatter
  formatter += getTimeFormatter(code);
  return format(new Date(dateTime), formatter, { locale: locales[code] });
}

/**
 * Format numbers with correct thousand separator (narrow non-breaking space) and with decimal
 * separator depending on currenct locale. If the optional `currency` parameter is provided, format
 * it as a currency amount.
 * @param {Number} value the number to be formatted
 * @param {String} lang language code
 * @param {String|Undefined} currency the currency ISO code
 * @returns {String} the formatted value
 */
export function formatAmount(value, lang, currency = undefined) {
  if (isNaN(value)) {
    return "-";
  }
  const code = getLanguageCode(lang);
  const options = currency ? { style: "currency", currency: currency } : {};
  // Get number parts to be able to access and replace thousand separator
  var parts = Intl.NumberFormat(code, options).formatToParts(value);
  parts = parts.map((p) => (p.type === "group" ? " " : p.value));
  // Recompose formatted output
  return parts.join("");
}

/**
 * Get 2-letter language code from composed language-country code
 * @param {String} lang full language code
 * @returns 2-letter language code
 */
function getLanguageCode(lang) {
  return lang.substring(0, 2);
}
