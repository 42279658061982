import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Header, Heading, Main, SubHeading } from "../commons/Template";
import routes from "../../routes/routes";

// Component shown when the authenticated user has an inactive account
export default function Inactive() {
  const { t } = useTranslation(["common", "account"]);
  document.title = t("account:inactive.title");

  return (
    <Main>
      <Header>
        <Heading>
          <Trans
            t={t}
            i18nKey="account:inactive.heading"
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
        <SubHeading>
          <p>
            <Trans
              t={t}
              i18nKey="account:inactive.subheading"
              components={{
                anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
              }}
            />
          </p>
        </SubHeading>
      </Header>
    </Main>
  );
}
