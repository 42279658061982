export default function ImageContainer({
  src,
  alt = "Image",
  className = "",
  useOverlay = false,
  overlayColor = "var(--color-leano-8)",
  overlayBlur = "0px",
  overlayOpacity = "0.45",
  backgroundColor = "transparent",
}) {
  return (
    <div
      className={["Image", className].filter(Boolean).join(" ")}
      style={
        useOverlay
          ? { background: overlayColor }
          : { backgroundColor: backgroundColor }
      }
    >
      <img
        className="Image-img"
        src={src}
        alt={alt}
        style={
          useOverlay
            ? {
                filter: `grayscale(100%) blur(${overlayBlur})`,
                opacity: `${overlayOpacity}`,
              }
            : null
        }
      />
    </div>
  );
}
