import { Fragment, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Content, Header, Heading, Main } from "../commons/Template";
import Form from "../commons/Form";
import api from "../../api/resources";
import { ArrowRight, Plus, User } from "react-feather";
import FancyButtons from "../commons/FancyButtons";
import { dashboardRoute } from "../../routes/routes";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { useCountries } from "../../hooks/commons/useCountries";
import { useNavigate } from "react-router-dom";

export default function CompanyReps() {
  const { t, i18n } = useTranslation(["common", "company"]);
  document.title = t("company:reps.title");
  const countries = useCountries();
  const dispatchToast = useToastsDispatch();
  const [rep, setRep] = useState(null);
  const [initialRep, setInitialRep] = useState(null);
  const [reps, setReps] = useState([]);

  const listReps = async () => {
    const response = await api.listReps();
    if (!response.ok) throw new Error("Request NOK");
    let jsonResponse = await response.json();
    if (jsonResponse.length !== 0) setReps(jsonResponse);
  };

  useEffect(() => {
    listReps();
  }, []);

  const handleSelect = (
    rep = {
      id: "",
      name: "",
      surname: "",
      role: "",
      birth_date: "",
      nationality: null,
      id_card: null,
      email: "",
      telephone: "",
    },
  ) => {
    const convertFn = (rep) => {
      // Delete `id_card` field because it is not required and is uploaded in vault
      delete rep.id_card;
      return {
        ...rep,
        // Convert country code into label/value object for select component
        nationality: {
          label: countries.getName(rep.nationality, i18n.resolvedLanguage),
          value: rep.nationality,
        },
      };
    };

    const convertedRep = convertFn(rep);

    setRep(convertedRep);
    setInitialRep(convertedRep);
  };

  const handleChange = (newValue, targetField) =>
    setRep((prev) => ({ ...prev, [targetField]: newValue }));

  const handleSuccess = (type = "create") => {
    listReps();
    dispatchToast({
      type: "add",
      variant: "success",
      heading: t(`company:reps.verifyRep.form.message.${type}.title`),
      subheading: t(`company:reps.verifyRep.form.message.${type}.content`),
    });
    setRep(null);
  };

  return (
    <Fragment>
      {rep ? (
        <Rep
          initialRep={initialRep}
          rep={rep}
          onChange={handleChange}
          onClear={() => setRep(null)}
          onSuccess={handleSuccess}
        />
      ) : (
        <Reps reps={reps} onSelect={handleSelect} />
      )}
    </Fragment>
  );
}

function Reps({ reps, onSelect }) {
  const { t } = useTranslation(["common", "company"]);
  const navigate = useNavigate();

  return (
    <Main>
      <Header>
        <Heading>
          <Trans
            t={t}
            i18nKey={"company:reps.home.heading"}
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
      </Header>
      <Content>
        <FancyButtons>
          {reps.map((rep, index) => {
            return (
              <Form
                key={index}
                fancyButton
                heading={`${rep.name} ${rep.surname}`}
                subheading={
                  [...rep.role][0].toUpperCase() +
                  [...rep.role].slice(1).join("")
                }
                icon={<User />}
                iconConf={<ArrowRight />}
                onSubmit={() => onSelect(rep)}
              />
            );
          })}
          <Form
            fancyButton
            heading={t("company:reps.home.button.add")}
            icon={<Plus />}
            iconConf={<ArrowRight />}
            onSubmit={() => onSelect()}
          />
          <Form
            button={t("company:reps.home.button.continue")}
            onSubmit={() => navigate(dashboardRoute)}
          />
        </FancyButtons>
      </Content>
    </Main>
  );
}

function Rep({ initialRep, rep, onChange, onClear, onSuccess }) {
  const { t, i18n } = useTranslation(["common", "company"]);
  const countries = useCountries();

  const [errorsObj, setErrorsObj] = useState({});
  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});

    // If rep. has an ID, then it is an update (patch)
    if (rep.id) {
      const response = await api.updateRep(
        { ...rep, nationality: rep.nationality.value },
        rep.id,
      );
      if (response.ok) {
        onSuccess("update");
      } else {
        const errors = await response.json();
        setErrorsObj(errors);
      }

      // If there is no ID, it is a creation (post)
    } else {
      const response = await api.createRep({
        ...rep,
        nationality: rep.nationality.value,
      });
      if (response.ok) {
        onSuccess("create");
      } else {
        const errors = await response.json();
        setErrorsObj(errors);
      }
    }
  };

  const nationalityInput = {
    label: t("company:reps.verifyRep.form.nationality"),
    type: "select",
    name: "nationality",
    value: rep.nationality,
    onChange: (value) => onChange(value, "nationality"),
    errors: getErrors("nationality"),
    options: Object.entries(
      countries.getNames(i18n.resolvedLanguage, { select: "official" }),
    ).map(([alpha2Code, name]) => {
      return {
        label: name,
        value: countries.alpha2ToAlpha3(alpha2Code),
      };
    }),
    isSearchable: true,
    placeholder: rep.nationality
      ? countries.getName(rep.nationality, i18n.resolvedLanguage)
      : t("company:reps.verifyRep.form.nationality"),
  };

  const mainInfoInputs1 = [
    {
      label: t("company:reps.verifyRep.form.name"),
      type: "text",
      name: "name",
      value: rep.name,
      onChange: (value) => onChange(value, "name"),
      errors: getErrors("name"),
      disabled: initialRep.id && initialRep.name,
    },
    {
      label: t("company:reps.verifyRep.form.surname"),
      type: "text",
      name: "surname",
      value: rep.surname,
      onChange: (value) => onChange(value, "surname"),
      errors: getErrors("surname"),
      disabled: initialRep.id && initialRep.surname,
    },
  ];

  const mainInfoInputs2 = [
    {
      label: t("company:reps.verifyRep.form.birthDate"),
      type: "date",
      name: "birth_date",
      value: rep.birth_date,
      onChange: (value) => onChange(value, "birth_date"),
      errors: getErrors("birth_date"),
      disabled: initialRep.id && initialRep.birth_date,
    },
    nationalityInput,
  ];

  const contactInfoInputs = [
    {
      label: t("company:reps.verifyRep.form.email"),
      type: "text",
      name: "email",
      value: rep.email,
      onChange: (value) => onChange(value, "email"),
      errors: getErrors("email"),
      required: false,
    },
    {
      label: t("company:reps.verifyRep.form.tel"),
      type: "tel",
      name: "telephone",
      value: rep.telephone && rep.telephone.replaceAll(" ", ""),
      onChange: (value) => onChange(value, "telephone"),
      errors: getErrors("telephone"),
    },
  ];

  const roleInput = [
    {
      label: t("company:reps.verifyRep.form.roles"),
      type: "text",
      name: "role",
      value: rep.role,
      onChange: (value) => onChange(value, "role"),
      errors: getErrors("role"),
    },
  ];

  return (
    <Main>
      <Header>
        <Heading onBackButtonClick={onClear}>
          {rep.name
            ? `${rep.name} ${rep.surname}`
            : t("company:reps.verifyRep.heading")}
        </Heading>
      </Header>
      <Content>
        <Form
          button={t("company:reps.verifyRep.form.button")}
          onSubmit={handleSubmit}
          errors={getErrors("non_field_errors")}
          inputErrors={Object.keys(errorsObj) ? true : false}
          inputs={[
            {
              data: mainInfoInputs1,
              display: "grid",
            },
            {
              data: mainInfoInputs2,
              display: "grid",
            },
            { data: contactInfoInputs, display: "grid" },
            { data: roleInput },
          ]}
        ></Form>
      </Content>
    </Main>
  );
}
