import { useState, useMemo, useEffect } from "react";
import { SettingList } from "../Settings";
import api from "../../../api/resources";
import { useTranslation } from "react-i18next";
import PlanSet from "./PlanSet";
import FrequencySet from "./FrequencySet";
import BillingDateSet from "./BillingDateSet";
import SubscriptionManage from "./SubscriptionManage";
import { FormLoader } from "../../commons/Form";

export default function Subscription() {
  const { t } = useTranslation(["common", "settings"]);
  document.title = t("settings:tabs.subscription.title");
  const [loading, setLoading] = useState(true);

  const [subscription, setSubscription] = useState({
    plan: "",
    frequency: "",
    billingDate: "",
  });

  const [subscribed, setSubscribed] = useState(false);
  const [upgradable, setUpgradable] = useState(true);

  const handleSubscriptionChange = (value, field) =>
    setSubscription((prevSubscription) => ({
      ...prevSubscription,
      [field]: value,
    }));

  const init = useMemo(
    () => async () => {
      const subscriptionRes = await api.getSubscription();
      if (subscriptionRes.ok) {
        let subscription = await subscriptionRes.json();
        handleSubscriptionChange(subscription.plan, "plan");
        handleSubscriptionChange(subscription.frequency, "frequency");
        handleSubscriptionChange(subscription.next_start, "billingDate");
        setSubscribed(
          subscription.plan !== "Trial" && subscription.plan !== "TrialEnded",
        );
        setUpgradable(
          subscription.plan !== "Free" && subscription.plan !== "Unlimited",
        );
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    init();
  }, [init]);

  if (loading) return <FormLoader />;
  return (
    <SettingList>
      <PlanSet value={subscription.plan} />
      {!subscription.frequency || subscription.plan === "Free" ? null : (
        <FrequencySet value={subscription.frequency} />
      )}
      {!subscription.billingDate || subscription.plan === "Free" ? null : (
        <BillingDateSet value={subscription.billingDate} />
      )}
      {subscription.plan === "Free" ? null : (
        <SubscriptionManage subscribed={subscribed} upgradable={upgradable} />
      )}
    </SettingList>
  );
}
