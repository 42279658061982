import { Fragment, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../commons/Button";
import Form, { FormLoader } from "../../commons/Form";
import { PersonRemove } from "@mui/icons-material";
import {
  Setting,
  SettingDisplay,
  SettingModder,
  SettingList,
} from "../Settings";
import api from "../../../api/resources";
import authAPI from "../../../api/authentication";
import { i18n } from "../../../i18n";
import { formatDate } from "../../../format";
import { useModalDispatch } from "../../commons/Modal/ModalContext";
import { useToastsDispatch } from "../../commons/Toasts/ToastsContext";

export default function Users() {
  const { t } = useTranslation(["common", "settings"]);
  document.title = t("settings:tabs.users.title");
  const dispatchModal = useModalDispatch();
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const dispatchToast = useToastsDispatch();

  const init = useCallback(async () => {
    const companyRes = await api.getActiveCompany();
    if (companyRes.ok) {
      const companyJsonRes = await companyRes.json();
      const userResponse = await authAPI.getUserInfo();
      if (userResponse.status === 200) {
        const userData = userResponse.data.user;
        setCurrentUser(userData);

        const managersList = companyJsonRes.managers.map((manager) => ({
          id: manager.id,
          name:
            userData.email === manager.email ? t("common:You") : manager.name,
          email: manager.email,
          status:
            userData.is_admin && userData.email === manager.email
              ? "admin"
              : "active", // admin status will show up as active for a current user that is not admin
        }));

        let invitedList = [];
        const invitationsRes = await api.getInvitations(companyJsonRes.id);
        if (invitationsRes.ok) {
          const invitationsJsonRes = await invitationsRes.json();
          if (invitationsJsonRes) {
            invitedList = invitationsJsonRes.map((invitation) => ({
              id: invitation.email,
              name: invitation.email,
              email: invitation.email,
              status: "pending",
              date: invitation.sent,
              invitationKey: invitation.key,
            }));
          }
        }

        setUsers([...managersList, ...invitedList]);
        setLoadingUsers(false);
      }
    }
  }, [t]);

  useEffect(() => {
    init();
  }, [init]);

  const handleDelete = (user) => {
    const handleDeleteManager = async (userID) => {
      const response = await api.deleteManager(userID);
      if (response.ok) {
        dispatchModal({ type: "success" });
      } else {
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "info",
          subheading: t("common:toasts.errorToast.subheading"),
        });
      }
    };

    const handleDeleteInvitation = async (invitationKey) => {
      const response = await api.deleteInvitation(invitationKey);
      if (response.ok) {
        dispatchModal({ type: "success" });
      } else {
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "info",
          subheading: t("common:toasts.errorToast.subheading"),
        });
      }
    };

    dispatchModal({
      type: "add",
      ...{
        title: t("settings:modals.deleteManager.title"),
        message:
          user.status === "active" ? (
            <Trans
              t={t}
              i18nKey="settings:modals.deleteManager.message"
              components={{ name: user.name }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="settings:modals.deleteInvite.message"
              components={{ email: user.email }}
            />
          ),
        component: (
          <Form
            button={t("common:delete")}
            onSubmit={
              user.status === "active"
                ? () => handleDeleteManager(user.id)
                : () => handleDeleteInvitation(user.invitationKey)
            }
          />
        ),
        onSuccess: () => init(),
      },
    });
  };

  const handleInvite = () => {
    dispatchModal({
      type: "add",
      ...{
        title: t("settings:modals.inviteUser.title"),
        message: t("settings:modals.inviteUser.message"),
        component: <UserInvite />,
        onSuccess: () => init(),
      },
    });
  };

  const handleSwitch = () => {
    dispatchModal({
      type: "add",
      ...{
        title: t("settings:modals.switchAdmin.title"),
        message: t("settings:modals.switchAdmin.message"),
        component: (
          <AdminSwitch
            managers={users.filter((user) => user.status === "active")}
          />
        ),
        onSuccess: () => init(),
      },
    });
  };

  const pillColors = {
    active: "green",
    pending: "yellow",
  };

  if (loadingUsers) return <FormLoader />;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
      <SettingList>
        {users
          .sort((user) => (user.status === "admin" ? -1 : 1)) // admin first
          .map((user) => (
            <Setting key={user.email}>
              <SettingDisplay
                label={user.name}
                tag={t(`common:${user.status}`)}
                tagColor={pillColors[user.status]}
                value={
                  user.status === "pending"
                    ? [
                        t(
                          `settings:tabs.users.items.${
                            ["01", "08", "11"].includes(
                              user.date.substring(8, 10),
                            )
                              ? "sentElided"
                              : "sent"
                          }`,
                        ),
                        formatDate(user.date, i18n.resolvedLanguage),
                      ]
                        .join(" ")
                        .replace("' ", "'")
                    : user.email
                }
              />
              {currentUser.is_admin && user.status !== "admin" && (
                <SettingModder
                  action="delete"
                  icon={<PersonRemove />}
                  onClick={() => handleDelete(user)}
                />
              )}
            </Setting>
          ))}
      </SettingList>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
        {currentUser.is_admin ? (
          <Fragment>
            {users.filter((user) => user.status === "active").length > 0 ? (
              <Button
                variant="primary_a"
                text={t("settings:tabs.users.buttons.switchAdmin")}
                onClick={handleSwitch}
              />
            ) : null}
            <Button
              variant="primary_a"
              text={t("settings:tabs.users.buttons.addUser")}
              onClick={handleInvite}
            />
          </Fragment>
        ) : null}
      </div>
    </div>
  );
}

function UserInvite() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();

  const handleInviteUser = async () => {
    const response = await api.inviteUser({ email: email });
    if (response.ok) {
      dispatchModal({ type: "success" });
    } else {
      dispatchModal({ type: "success" });
      dispatchToast({
        type: "add",
        variant: "info",
        subheading: t("common:toasts.errorToast.subheading"),
      });
    }
  };

  const emailInput = {
    type: "email",
    label: t("common:email"),
    value: email,
    onChange: setEmail,
    required: true,
  };

  return (
    <Form
      inputs={[{ data: [emailInput] }]}
      button={t("common:invite")}
      onSubmit={handleInviteUser}
    />
  );
}

function AdminSwitch({ managers }) {
  const { t } = useTranslation(["common", "settings"]);
  const [newAdmin, setNewAdmin] = useState(null);
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();

  const input = {
    type: "radio",
    label: t("settings:modals.switchAdmin.label"),
    options: managers.map((manager) => ({
      label: manager.name,
      value: manager.id,
    })),
    value: newAdmin,
    onChange: setNewAdmin,
    required: true,
  };

  const handleSwitch = async () => {
    const response = await api.updateActiveCompany({ admin: newAdmin.value });
    if (response.ok) {
      window.location.reload(); // reload in this case will reload /settings/users which will have a false is_admin which will redirect to /settings/company
    } else {
      dispatchModal({ type: "success" });
      dispatchToast({
        type: "add",
        variant: "info",
        subheading: t("common:toasts.errorToast.subheading"),
      });
    }
  };

  return <Form inputs={[{ data: [input] }]} onSubmit={handleSwitch} />;
}
