import { Trans, useTranslation } from "react-i18next";
import { Content, Header, Main } from "../commons/Template";
import api from "../../api/resources";
import { Fragment, useEffect, useState } from "react";
import Form, { FormLoader } from "../commons/Form";
import {
  AccessTime,
  ArrowForward,
  Clear,
  Download,
  FactCheck,
  MoreVert,
  OpenInBrowser,
  Radio,
} from "@mui/icons-material";
import { i18n } from "../../i18n";
import { differenceInCalendarDays, formatISO, getMonth } from "date-fns";
import { formatAmount, formatDate, formatDateTime } from "../../format";
import Tabs from "../commons/Tabs";
import Input from "../commons/Input";
import Button from "../commons/Button";
import { Drawer, Switch, ThemeProvider } from "@mui/material";
import { DrawerHeader } from "../commons/Drawer";
import usePartners from "./usePartners";
import ImageContainer from "../commons/ImageContainer";
import {
  Table,
  TableRow,
  TableRowHeader,
  TableRowItem,
  TableWrapper,
} from "../commons/Table";
import { Pill } from "./Assessment";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Check } from "react-feather";
import * as XLSX from "xlsx";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { switchTheme } from "../commons/Switch";
import { BarChart } from "@mui/x-charts";

export default function Loans() {
  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:loans.title");

  const [loans, setLoans] = useState(null);
  const [amounts, setAmounts] = useState([]);

  const activeStatus = useActiveStatus();

  // Tabs
  const tabs = [
    {
      id: "active",
      filter: (loan) => activeStatus.some((status) => status === loan.status),
    },
    {
      id: "closed",
      filter: (loan) => loan.status === "closed",
    },
    {
      id: "refused",
      filter: (loan) => loan.status === "refused",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0].id);
  const handleSelectTab = (id) => setSelectedTab(id);

  const [loading, setLoading] = useState(true);

  const init = async () => {
    setLoading(true);

    const loansResponse = await api.getLoans();
    if (loansResponse.ok) {
      const jsonLoans = await loansResponse.json();
      setLoans(jsonLoans);
    }

    const amountsResponse = await api.getMonthlyAmounts();
    if (amountsResponse.ok) {
      const jsonAmounts = await amountsResponse.json();
      setAmounts(jsonAmounts);
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const [graphShown, setGraphShown] = useState(false);

  return (
    <Main themeSwitch>
      <Header
        heading={t("financing:loans.title")}
        subheading={t("financing:loans.subheading")}
      />
      {loading ? (
        <FormLoader />
      ) : (
        <Content style={{ display: "flex", flexDirection: "column" }}>
          <div className="LoanHeader">
            <LoanCounters loans={loans} />
            <GraphSwitch value={graphShown} onSwitch={setGraphShown} />
          </div>

          {!graphShown ? (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <LoanTabs
                tabs={tabs.map((tab) => tab.id)}
                selectedTab={selectedTab}
                onSelect={handleSelectTab}
              />
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  style={{ display: tab.id === selectedTab ? "block" : "none" }}
                >
                  <TabLoanList
                    tabID={tab.id}
                    tabLoanList={loans.filter(
                      tabs.find((tab) => tab.id === selectedTab).filter,
                    )}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Graph amounts={amounts} />
          )}
        </Content>
      )}
    </Main>
  );
}

function useActiveStatus() {
  return ["accepted", "ongoing", "overdue", "pending"];
}

function TabLoanList({ tabID, tabLoanList }) {
  const [filterOptions, setFilterOptions] = useState([]);
  const handleFilterOptionsChange = (id, newFilterFn) =>
    setFilterOptions((prevFilterOptions) => {
      // if new filter function, add it
      if (newFilterFn) {
        return [
          ...prevFilterOptions.filter((filter) => filter.id !== id),
          {
            id: id,
            filterFn: newFilterFn,
          },
        ];
      }
      // else, remove it
      return [...prevFilterOptions.filter((filter) => filter.id !== id)];
    });

  const filterByOptions =
    filterOptions.length > 0
      ? filterOptions
          .map((filterOption) => filterOption.filterFn)
          .reduce((x, y) => (z) => x(z) && y(z))
      : () => true;

  // Array of unique status values from current subset of loans
  const availableStatus = Array.from(
    new Set(tabLoanList.map((loan) => loan.status)),
  );

  // Array of unique partner values from current subset of loans
  const availablePartners = Array.from(
    new Set(tabLoanList.map((loan) => loan.provider)),
  );

  // Array of unique creation dates values from current subset of loans
  const sortByDescendingDate = (a, b) => (a < b ? 1 : -1);
  const rawDates = tabLoanList
    .map((loan) => new Date(loan.creation_datetime))
    .sort(sortByDescendingDate);
  const availableISODates = Array.from(
    new Set(rawDates.map((date) => formatISO(date))),
  );

  // Drawer
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer((drawer) => !drawer);

  const [selectedLoan, setSelectedLoan] = useState(null);
  const handleSelectLoan = (id) => {
    setSelectedLoan(tabLoanList.find((loan) => loan.id == id));
    toggleDrawer();
  };

  const { t } = useTranslation(["common", "financing"]);
  const getCommonT = (word) => t(`common:${word}`);
  const formatAmountFn = (amount, currency) =>
    amount ? formatAmount(amount, lang, currency) : "-";
  const lang = i18n.resolvedLanguage;

  const statusPillColors = {
    accepted: "green",
    ongoing: "green",
    overdue: "red",
    pending: "yellow",
  };

  const sortByAscendingIntID = (a, b) => (a.int_id > b.int_id ? 1 : -1);
  const computeRows = (tabLoanList) =>
    tabLoanList.sort(sortByAscendingIntID).map((loan) => {
      const currency = loan.currency;
      return {
        thValue: loan.int_id,
        items: [
          {
            label: getCommonT("creationDate"),
            value: formatDate(loan.creation_datetime, lang),
            rawValue: formatDate(loan.creation_datetime, lang),
          },
          {
            label: getCommonT("amount"),
            value: formatAmountFn(loan.amount, currency),
            rawValue: loan.amount,
          },
          loan.months
            ? {
                label: getCommonT("duration"),
                value: `${loan.months} ${
                  loan.months > 1 ? getCommonT("months") : getCommonT("month")
                }`,
                rawValue: loan.months,
              }
            : {
                label: getCommonT("duration"),
                value: `${loan.days} ${
                  loan.days > 1 ? getCommonT("days") : getCommonT("day")
                }`,
                rawValue: loan.days,
              },
          {
            label: getCommonT("funder"),
            value: loan.provider,
            rawValue: loan.provider,
          },
          {
            label: getCommonT("cost"),
            value: formatAmountFn(loan.cost, currency),
            rawValue: loan.cost,
          },
          {
            label: getCommonT("status"),
            value: loan.status,
            rawValue: loan.status,
            component: (
              <Pill
                text={t(`financing:loans.status.${loan.status}`)}
                color={statusPillColors[loan.status]}
              />
            ),
          },
          {
            value: (
              <Button
                icon={<MoreVert />}
                onClick={() => handleSelectLoan(loan.id)}
              />
            ),
          },
        ],
      };
    });

  const handleExportXLSX = (rows) => {
    const headers = rows[0].items.map((item) => item.label);
    const worksheetData = rows.map((row) =>
      row.items.map((item) => item.rawValue),
    );
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...worksheetData]);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, tabID);
    XLSX.writeFile(newWorkbook, `${tabID}.xlsx`);
  };

  const filteredRows = computeRows(tabLoanList.filter(filterByOptions));

  return (
    <div className="TabLoanList">
      <div className="TabLoanListControls">
        <TabLoanListFilters>
          {tabID === "active" && (
            <StatusFilter
              availableStatus={availableStatus}
              onChange={(filterFn) =>
                handleFilterOptionsChange("status", filterFn)
              }
            />
          )}
          <DateFilter
            availableISODates={availableISODates}
            onChange={(filterFn) =>
              handleFilterOptionsChange("creation_datetime", filterFn)
            }
          />
          <PartnerFilter
            availablePartners={availablePartners}
            onChange={(filterFn) =>
              handleFilterOptionsChange("partner", filterFn)
            }
          />
        </TabLoanListFilters>
        {tabLoanList.length > 0 && (
          <Button
            onClick={() => handleExportXLSX(filteredRows)}
            variant={"secondary_b"}
            text={t("financing:loans.table.export")}
            icon={<Download />}
            iconLeft
          />
        )}
      </div>

      <TableWrapper>
        <Table>
          {filteredRows.map((row, index) => (
            <TableRow key={index}>
              <TableRowHeader
                thLabel={t("common:application")}
                thValue={row.thValue}
              />
              {row.items.map((item, itemIndex) => (
                <TableRowItem key={itemIndex} {...item} />
              ))}
            </TableRow>
          ))}
        </Table>
      </TableWrapper>

      <Drawer
        anchor={"right"}
        open={drawer}
        onClose={toggleDrawer}
        className="PartnersDrawer"
      >
        <DrawerHeader onClose={toggleDrawer} />

        {selectedLoan && (
          <LoanDetails>
            <LoanDetailsHeader selectedLoan={selectedLoan} />
            <StatusChapter selectedLoan={selectedLoan} />
            <BankingChapter
              selectedLoan={selectedLoan}
              onModalOpen={() => toggleDrawer()}
            />
            <DocumentChapter selectedLoan={selectedLoan} />
          </LoanDetails>
        )}
      </Drawer>
    </div>
  );
}

function GraphSwitch({ value, onSwitch }) {
  const handleSwitchChange = ({ target: { checked } }) => onSwitch(checked);

  return (
    <div className="GraphSwitch">
      <span className="GraphSwitch-label">{"Tableau"}</span>

      <ThemeProvider theme={switchTheme}>
        <Switch
          size="small"
          checked={value}
          onChange={(newValue) => handleSwitchChange(newValue)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </ThemeProvider>
      <span className="GraphSwitch-label">{"Graphique"}</span>
    </div>
  );
}

function Graph({ amounts }) {
  const options = [
    {
      label: "Montant emprunté",
      value: "A",
    },
    {
      label: "Montant payé",
      value: "B",
    },
  ];

  const [type, setType] = useState(options[0]);

  const graphControlInput = {
    type: "radio",
    value: type,
    onChange: setType,
    options: options,
  };

  return (
    <div className="Graph">
      <div className="Graph-header">
        <p className="Graph-header-heading">Votre historique</p>
        <p className="Graph-header-subheading">
          Graphique concernant les douze derniers mois
        </p>
      </div>
      <div className="Graph-controls">
        <Input {...graphControlInput} rowRadio />
      </div>
      <BarChart
        series={[
          { data: [35, 44, 24, 34] },
          { data: [51, 6, 49, 30] },
          { data: [15, 25, 30, 50] },
          { data: [60, 50, 15, 25] },
        ]}
        height={290}
        xAxis={[{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
    </div>
  );
}

function LoanDetails({ children }) {
  return <div className="LoanDetails">{children}</div>;
}

function LoanDetailsHeader({ selectedLoan }) {
  const { t } = useTranslation(["common", "financing"]);
  const partners = usePartners();

  return (
    <div className="LoanDetails-header">
      <ImageContainer
        className="LoanDetails-header-logo"
        src={
          partners.find((partner) => partner.id === selectedLoan.provider).logo
        }
      />
      <div className="LoanDetails-header-text">
        <p className="LoanDetails-header-text-title">
          {t("financing:loans.details.header")}
        </p>
        <p className="LoanDetails-header-text-subtitle">
          {selectedLoan.ext_id}
        </p>
      </div>
    </div>
  );
}

function LoanDetailsChapter({ icon, title, children }) {
  return (
    <div className="LoanDetails-chapter">
      <p className="LoanDetails-chapter-title">
        {icon} <span>{title}</span>
      </p>
      <div className="LoanDetails-chapter-content">{children}</div>
    </div>
  );
}

function StatusChapter({ selectedLoan }) {
  const { t } = useTranslation(["common", "financing"]);
  const lang = i18n.resolvedLanguage;
  const active = useActiveStatus();

  const defaultItem = {
    className: "unknown",
    icon: <Radio />,
    text: "",
    datetime: "",
  };

  // item 1 : dispatch
  const sentDispatch = {
    className: "success",
    icon: <Check />,
    text: t("financing:loans.details.statusChapter.dispatch.sent"),
    datetime: formatDateTime(selectedLoan.creation_datetime, lang),
  };

  // item 2: response
  const pendingResponse = {
    className: "pending",
    icon: <AccessTime />,
    text: t("financing:loans.details.statusChapter.response.pending"),
  };

  const refusedResponse = {
    className: "error",
    icon: <Clear />,
    text: t("financing:loans.details.statusChapter.response.refused"),
  };

  const acceptedResponse = {
    className: "success",
    icon: <Check />,
    text: t("financing:loans.details.statusChapter.response.accepted"),
  };

  // item 3: reimbursement
  const pendingReimbursement = {
    className: "pending",
    icon: <AccessTime />,
    text: t("financing:loans.details.statusChapter.reimbursement.pending"),
    datetime: selectedLoan.ending_date,
  };

  const okReimbursement = {
    className: "success",
    icon: <Check />,
    text: t("financing:loans.details.statusChapter.reimbursement.done"),
    datetime: formatDate(selectedLoan.reimbursement_date, lang),
  };

  let items = [
    sentDispatch,
    {
      ...(selectedLoan.status === "accepted"
        ? acceptedResponse
        : selectedLoan.status === "refused"
          ? refusedResponse
          : !selectedLoan.response_datetime
            ? pendingResponse
            : defaultItem), // might not work if response_datetime && (pending or overdue)
      datetime: selectedLoan.response_datetime
        ? formatDateTime(selectedLoan.response_datetime, lang)
        : "",
    },
    {
      ...(active.some((activeStatus) => activeStatus === selectedLoan.status)
        ? pendingReimbursement
        : selectedLoan.status === "closed" && selectedLoan.reimbursement_date
          ? okReimbursement
          : defaultItem),
    },
  ];

  // remove last item if reimbursement doesn't make sense
  if (selectedLoan.status === "refused" || selectedLoan.status === "pending") {
    items = items.slice(0, -1);
  }

  return (
    <LoanDetailsChapter
      title={t("financing:loans.details.statusChapter.title")}
      icon={<FactCheck />}
    >
      <Timeline className="LoanTimeline">
        {items.map((item, index) => (
          <TimelineItem key={index} className={item.className}>
            <TimelineSeparator>
              <TimelineDot className={item.className}>{item.icon}</TimelineDot>
              {index < items.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <div className="Timeline-item-content">
                <p className="Timeline-item-content-text">{item.text}</p>
                <p className="Timeline-item-content-datetime">
                  {item.datetime}
                </p>
              </div>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </LoanDetailsChapter>
  );
}

function BankingChapter({ selectedLoan, onModalOpen }) {
  const { t } = useTranslation(["common", "financing"]);
  const dispatchModal = useModalDispatch();

  const style1 = {
    fontWeight: 600,
  };

  const iban = (iban) =>
    iban ? `IBAN : ${selectedLoan.bank_account.IBAN}` : "-";

  const today = new Date();

  const showPayment =
    selectedLoan.provider === "Defacto" &&
    selectedLoan.status !== "overdue" &&
    differenceInCalendarDays(selectedLoan.reimbursement_date, today) > 3;

  const handlePayment = () => {
    onModalOpen();
    dispatchModal({
      type: "add",
      title: (
        <Trans
          t={t}
          i18nKey="financing:loans.details.bankingChapter.repay.modal.title"
          values={{ loanID: selectedLoan.ext_id }}
        />
      ),
      message: t("financing:loans.details.bankingChapter.repay.modal.message"),
      component: <PayDateModal loanID={selectedLoan.id} />,
      size: "medium",
    });
  };

  if (!selectedLoan.bank_account) return <></>;
  return (
    <LoanDetailsChapter
      title={t("financing:loans.details.bankingChapter.title")}
      icon={<FactCheck />}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div style={{ lineHeight: "1.5", fontSize: "smaller" }}>
          <p style={style1}>
            {t("financing:loans.details.bankingChapter.accounts.receive")}
          </p>
          <p>{iban(selectedLoan.bank_account.IBAN)}</p>

          <p style={style1}>
            {t("financing:loans.details.bankingChapter.accounts.repay")}
          </p>
          <p>{iban(selectedLoan.bank_account.IBAN)}</p>
        </div>

        {showPayment && (
          <Fragment>
            <div
              style={{
                lineHeight: "1.5",
                fontSize: "smaller",
              }}
            >
              <p style={{ color: "var(--color-leano-2)", fontWeight: "600" }}>
                {t("common:note")}
              </p>
              <p>{t("financing:loans.details.bankingChapter.repay.note")}</p>
            </div>

            <p style={{ fontWeight: "700", fontSize: "larger" }}>
              {selectedLoan.current_interest
                ? formatAmount(
                    selectedLoan.current_interest,
                    i18n.resolvedLanguage,
                    selectedLoan.currency,
                  )
                : "-"}
            </p>

            <Button
              onClick={handlePayment}
              style={{
                color: "var(--color-leano-2)",
                display: "inline-flex",
                fontSize: "medium",
                fontWeight: "600",
              }}
              text={t("financing:loans.details.bankingChapter.repay.button")}
              icon={<ArrowForward />}
            />
          </Fragment>
        )}
      </div>
    </LoanDetailsChapter>
  );
}

function PayDateModal({ loanID }) {
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();
  const { t } = useTranslation(["common", "financing"]);
  const [date, setDate] = useState("");

  const handlePayment = async () => {
    const response = await api.anticipateReimbursement(loanID, {
      date: date,
    });
    let errors = [];
    switch (response.status) {
      case 200:
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "success",
          heading: t(
            "financing:loans.details.bankingChapter.repay.modal.successToast.heading",
          ),
          subheading: t(
            "financing:loans.details.bankingChapter.repay.modal.successToast.subheading",
          ),
        });
        break;
      case 422:
        errors = await response.json();
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "error",
          heading: t(
            "financing:loans.details.bankingChapter.repay.modal.errorToast.heading",
          ),
          subheading: (
            <ul>
              {errors.map((errorCode, index) => (
                <li key={index}>{errorCode}</li>
              ))}
            </ul>
          ),
        });
        break;
      default:
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "info",
          subheading: t("common:toasts.errorToast.subheading"),
        });
        break;
    }
  };

  const dateInput = {
    name: "date",
    type: "date",
    label: t("financing:loans.details.bankingChapter.repay.modal.label"),
    value: date,
    onChange: setDate,
  };

  return <Form inputs={[{ data: [dateInput] }]} onSubmit={handlePayment} />;
}

function DocumentChapter({ selectedLoan }) {
  const { t } = useTranslation(["common", "financing"]);
  const style = {
    display: "flex",
    alignItems: "center",
    color: "var(--color-leano-1)",
    gap: "0.5rem",
    textDecoration: "none",
    fontWeight: "700",
    fontSize: "smaller",
  };

  let documents = [];

  if (selectedLoan.contract) {
    documents.push({
      id: "contract",
      label: t("common:contract"),
      value: selectedLoan.contract,
    });
  }

  if (selectedLoan.invoice) {
    documents.push({
      id: "invoice",
      label: t("common:invoice"),
      value: selectedLoan.invoice,
    });
  }

  if (documents.length === 0) return <></>;
  return (
    <LoanDetailsChapter
      title={t("financing:loans.details.documentChapter.title")}
      icon={<FactCheck />}
    >
      <ul style={{ margin: "unset", lineHeight: "3" }}>
        {documents.length > 0 &&
          documents.map((document) => (
            <li key={document.id}>
              <a style={style} href={document.value}>
                <OpenInBrowser fontSize="medium" />
                <span>{document.label}</span>
              </a>
            </li>
          ))}
      </ul>
    </LoanDetailsChapter>
  );
}

function TabLoanListFilters({ children }) {
  return (
    <div className="LoanFilters" style={{ display: "flex", gap: "0.5rem" }}>
      {children}
    </div>
  );
}

function PartnerFilter({ availablePartners, onChange }) {
  const { t } = useTranslation(["common"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availablePartners.map((partner) => ({
    label: partner,
    value: (loan) => loan.provider === partner,
  }));

  const input = {
    type: "select",
    placeholder: t("common:funder"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"LoanFilter"} {...input} />;
}

function DateFilter({ availableISODates, onChange }) {
  const { t } = useTranslation(["common"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableISODates.map((ISODate) => ({
    label: formatDate(ISODate, i18n.resolvedLanguage),
    value: (loan) =>
      differenceInCalendarDays(
        new Date(loan.creation_datetime),
        new Date(ISODate),
      ) >= 0,
  }));

  const input = {
    type: "select",
    placeholder: t("common:creationDate"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"LoanFilter"} {...input} />;
}

function StatusFilter({ availableStatus, onChange }) {
  const { t } = useTranslation(["common", "financing"]);

  const [value, setValue] = useState(null);
  const handleValueChange = (newValue) => {
    setValue(newValue);
    onChange(newValue ? newValue.value : () => true);
  };

  const options = availableStatus.map((status) => ({
    label: t(`financing:loans.status.${status}`),
    value: (loan) => loan.status === status,
  }));

  const input = {
    type: "select",
    placeholder: t("common:status"),
    value: value,
    onChange: handleValueChange,
    isClearable: true,
    options: options,
  };
  return <Input className={"LoanFilter"} {...input} />;
}

function LoanTabs({ tabs, selectedTab, onSelect }) {
  const { t } = useTranslation(["common", "financing"]);
  return (
    <Tabs
      smallTabs
      tabs={tabs.map((tab) => ({
        id: tab,
        title: t(`financing:loans.tabs.${tab}`),
      }))}
      selectedID={selectedTab}
      onSelect={onSelect}
    />
  );
}

function LoanCounters({ loans }) {
  const { t } = useTranslation(["common", "financing"]);
  const today = new Date().setHours(0, 0, 0, 0);

  const filterA = (loan) => loan.status === "pending";
  const filterB = (loan) => {
    const correctLoanStatus = ["accepted", "closed", "ongoing", "overdue"].some(
      (status) => loan.status === status,
    );

    let correctMonth = true;
    if (loan.response_datetime) {
      correctMonth = getMonth(loan.response_datetime) === getMonth(today);
    }

    return correctLoanStatus && correctMonth;
  };

  const counters = [
    {
      id: "A",
      count: loans.filter(filterA).length.toString(),
      backgroundColor: "var(--color-warning-3)",
      text: t("financing:loans.counterCards.pending"),
    },
    {
      id: "B",
      count: loans.filter(filterB).length.toString(),
      backgroundColor: "var(--color-success-3)",
      text: t("financing:loans.counterCards.ongoing"),
      timeframe: t("financing:loans.counterCards.timeframe"),
    },
  ];

  return (
    <div className="LoanCounters">
      {counters.map((counter, index) => (
        <div
          key={index}
          className="LoanCounter"
          style={{ backgroundColor: counter.backgroundColor }}
        >
          <span className="LoanCounter-count">{counter.count}</span>
          <span className="LoanCounter-text">{counter.text}</span>
          <span className="LoanCounter-timeframe">{counter.timeframe}</span>
        </div>
      ))}
    </div>
  );
}
