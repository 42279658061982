import { formatAddress } from "../../format";
import { useTranslation } from "react-i18next";

export default function Address({ address, complements = false }) {
  const { i18n } = useTranslation();

  return (
    <ul style={{ margin: "0" }}>
      {formatAddress(address, i18n.resolvedLanguage, complements).map(
        (addressLine, index) => (
          <li key={index}>{addressLine}</li>
        ),
      )}
    </ul>
  );
}
