import { useCallback, useEffect, useState } from "react";
import { Content, Header, Main } from "../commons/Template";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/resources";
import Form, { FormLoader } from "../commons/Form";
import routes from "../../routes/routes";
import { Drawer } from "@mui/material";
import Button from "../commons/Button";
import Card, { CardText } from "../commons/Card";
import ImageContainer from "../commons/ImageContainer";

import {
  ArrowForwardRounded,
  ArticleOutlined,
  ChevronRight,
  ExpandMore,
  LocalShipping,
  Receipt,
  Savings,
  Sell,
  SwapHoriz,
} from "@mui/icons-material";
import { i18n } from "../../i18n";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { capitalize } from "../../utils";
import useFormatAmount from "./useFormatAmount";
import clients from "../../assets/needs/clients.png";
import long_term from "../../assets/needs/long_term.png";
import short_term from "../../assets/needs/short_term.png";
import stock from "../../assets/needs/stock.png";
import vendors from "../../assets/needs/vendors.png";
import { DrawerHeader } from "../commons/Drawer";
import usePartners from "./usePartners";

export default function Assessment({ reseller }) {
  // flow
  const goForward = (need) => {
    const target = routes.Financing.unlock;
    const state = {
      need: need,
    };
    navigate(target, { state: state });
  };
  // end flow

  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:assessment.title");
  const navigate = useNavigate();
  const dispatchModal = useModalDispatch();

  const { clientID } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true);

  const yesOption = {
    label: t("common:yes"),
    value: "yes",
  };

  const noOption = {
    label: t("common:no"),
    value: "no",
  };

  const yesNoOptions = [yesOption, noOption];

  const [questions, setQuestions] = useState([
    {
      id: "is_ecommerce",
      value: noOption,
      type: "radio",
      options: yesNoOptions,
    },
    {
      id: "is_commercial",
      value: noOption,
      type: "radio",
      options: yesNoOptions,
    },
    {
      id: "is_organisation",
      value: noOption,
      type: "radio",
      options: yesNoOptions,
    },
    {
      id: "turnover",
      value: "",
      type: "number",
      min: 0,
    },
    {
      id: "equity",
      value: "",
      type: "number",
    },
    {
      id: "gross_operating_profit",
      value: "",
      type: "number",
    },
    {
      id: "net_income",
      value: "",
      type: "number",
    },
  ]);

  const { results, setResults } = useResults(null);

  const dispatchWelcomeModal = () => {
    const note = (
      <Trans
        i18nKey="financing:assessment.modal.note"
        components={{
          emphasis: <strong />,
        }}
      />
    );

    dispatchModal({
      type: "add",
      title: t("financing:assessment.modal.title"),
      message: t("financing:assessment.modal.message"),
      size: "medium",
      note: note,
      component: <WelcomeModal />,
    });
  };

  const initQuestions = (company) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) => {
        const rawValue = company[question.id];
        const finalValue =
          rawValue && typeof rawValue === "boolean"
            ? yesOption
            : !rawValue && typeof rawValue === "boolean"
              ? noOption
              : rawValue !== null
                ? rawValue
                : "";

        return {
          ...question,
          value: finalValue,
          currency: company.currency,
        };
      }),
    );
  };

  const isFirstAssessment = (company) => company.is_commercial === null;

  const init = useCallback(async () => {
    if (!reseller) {
      const response = await api.getActiveCompany();
      if (response.ok) {
        const activeCompany = await response.json();
        setCompanyName(activeCompany.name);
        if (isFirstAssessment(activeCompany)) {
          dispatchWelcomeModal();
        } else {
          const assessResponse = await api.assessActiveCompany();
          if (assessResponse.ok) {
            const assessJsonResponse = await assessResponse.json();
            setResults(assessJsonResponse);
          }
        }
        initQuestions(activeCompany);
        setLoading(false);
      }
    } else {
      const response = await api.getClients();
      if (response.ok) {
        const clients = await response.json();
        const currentClient = clients.filter(
          (client) => client.id.toString() === clientID,
        )[0];

        console.log(currentClient);

        if (!currentClient) {
          navigate(routes.Reseller.clients);
        } else {
          setCompanyName(currentClient.name);
          if (isFirstAssessment(currentClient)) {
            dispatchWelcomeModal();
          } else {
            const assessResponse = await api.assessClient(clientID);
            if (assessResponse.ok) {
              const assessJsonResponse = await assessResponse.json();
              setResults(assessJsonResponse);
            }
          }
          initQuestions(currentClient);
          setLoading(false);
        }
      }
    }
  }, [clientID, navigate]);

  useEffect(() => {
    init();
  }, [init]);

  const handleQuestionChange = (value, questionID) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((prevQuestion) =>
        prevQuestion.id === questionID
          ? {
              ...prevQuestion,
              value: value,
            }
          : prevQuestion,
      ),
    );
  };

  const handleSubmit = async () => {
    const entries = questions.map((question) => [question.id, question.value]);
    const objectUpdatedCompany = Object.fromEntries(entries);
    const updatedCompany = {
      ...objectUpdatedCompany,
      is_ecommerce: objectUpdatedCompany.is_ecommerce.value,
      is_commercial: objectUpdatedCompany.is_commercial.value,
      is_organisation: objectUpdatedCompany.is_organisation.value,
    };
    const response = reseller
      ? await api.assessClient(clientID, updatedCompany)
      : await api.assessActiveCompany(updatedCompany);
    if (response.ok) {
      const jsonResponse = await response.json();
      setResults(jsonResponse);
      init();
    }
  };

  const heading = reseller
    ? `${t("financing:assessment.title")} ${t("common:for")} ${companyName}`
    : t("financing:assessment.title");

  return (
    <Main themeSwitch>
      <Header
        heading={heading}
        subheading={t("financing:assessment.subheading")}
      />
      <Content>
        {loading ? (
          <FormLoader />
        ) : !results ? (
          <Questions
            questions={questions}
            onChange={handleQuestionChange}
            onSubmit={handleSubmit}
            onBack={() => init()}
          />
        ) : (
          <Results
            results={results}
            onClear={() => setResults(null)}
            reseller={reseller}
            onNext={goForward}
          />
        )}
      </Content>
    </Main>
  );
}

function WelcomeModal() {
  const dispatchModal = useModalDispatch();
  const { t } = useTranslation(["common", "financing"]);
  return (
    <Form
      button={t("financing:assessment.modal.button")}
      onSubmit={() => dispatchModal({ type: "success" })}
    />
  );
}

function Questions({ questions, onChange, onSubmit, onBack }) {
  const { t } = useTranslation(["common", "financing"]);
  const [index, setIndex] = useState(0);

  const goBack = () => {
    index > 0
      ? setIndex((prevIndex) => (prevIndex >= 1 ? prevIndex - 1 : prevIndex))
      : onBack();
  };

  const goForward = () => {
    setIndex((prevIndex) =>
      prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex,
    );
  };

  const handleForward = () =>
    index < questions.length - 1 ? goForward() : onSubmit();

  return (
    <div className="Questions">
      <h4>{t("financing:assessment.questions.title")}</h4>
      <ProgressBar length={questions.length} index={index} />
      {questions.map((question, displayIndex) => (
        <Question
          key={displayIndex}
          displayed={index === displayIndex}
          {...question}
          question={t(
            ["financing:assessment.questions.keys", question.id].join("."),
          )}
          onChange={onChange}
          onForward={handleForward}
          onBackward={goBack}
          button={
            index < questions.length - 1
              ? t("financing:assessment.questions.buttons.continue")
              : t("financing:assessment.questions.buttons.end")
          }
          onSubmit={onSubmit}
        />
      ))}
    </div>
  );
}

function Results({
  reseller,
  results = {
    financialNeed1: {
      partner1: true,
      partner2: false,
    },
    financialNeed2: {
      partner1: true,
      partner2: false,
      partner3: true,
    },
  },
  onClear,
  onNext,
}) {
  const { t } = useTranslation(["common", "financing"]);
  const [selectedResult, setSelectedResult] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => setDrawer((drawer) => !drawer);

  const handleUnlock = (id) => onNext(id);

  const handleInfo = (id) => {
    setSelectedResult(id);
    toggleDrawer();
  };

  useEffect(() => {
    if (!drawer) {
      setSelectedResult("");
    }
  }, [drawer]);

  const resultIcons = [
    {
      id: "clients",
      icon: <Receipt />,
    },
    {
      id: "long_term",
      icon: <Savings />,
    },
    {
      id: "short_term",
      icon: <SwapHoriz />,
    },
    {
      id: "stock",
      icon: <LocalShipping />,
    },
    {
      id: "vendors",
      icon: <Sell />,
    },
  ];

  const resultImages = [
    {
      id: "clients",
      img: clients,
    },
    {
      id: "long_term",
      img: long_term,
    },
    {
      id: "short_term",
      img: short_term,
    },
    {
      id: "stock",
      img: stock,
    },
    {
      id: "vendors",
      img: vendors,
    },
  ];

  const navigate = useNavigate();

  delete results.long_term; // temp. - waiting to have a use-case in such need

  return (
    <div className="Results">
      <div className="Results-container">
        {Object.entries(results)
          .sort(([result]) =>
            Object.values(result).some((value) => value) ? -1 : 1,
          ) // sort by eligible first
          .map(([id, result]) => (
            <Result
              key={id}
              id={id}
              name={t(`financing:assessment.results.keys.${id}.name`)}
              description={t(
                `financing:assessment.results.keys.${id}.description`,
              )}
              img={resultImages.find((result) => result.id === id).img}
              icon={resultIcons.filter((result) => result.id === id)[0].icon}
              result={result}
              selected={selectedResult === id}
              onUnlock={handleUnlock}
              onInfo={handleInfo}
              reseller={reseller}
              eligible={Object.values(result).some((value) => value)}
            />
          ))}
      </div>

      <div className="Results-after">
        <div className="Results-after-text">
          <p className="Results-after-text-title">
            {t("financing:assessment.results.restart.title")}
          </p>
          <p className="Results-after-text-subtitle">
            {t("financing:assessment.results.restart.subtitle")}
          </p>
        </div>

        <div className="Results-after-buttons">
          <Button
            text={t("financing:assessment.results.restart.buttons.restart")}
            variant="secondary_b"
            onClick={onClear}
          />
          {reseller && (
            <Button
              text={t(
                "financing:assessment.results.restart.buttons.backToClients",
              )}
              variant="primary_a"
              onClick={() => navigate(routes.Reseller.clients)}
            />
          )}
        </div>
      </div>
      <Partners
        resultType={selectedResult}
        result={selectedResult && Object.entries(results[selectedResult])}
        open={drawer}
        onClose={toggleDrawer}
        eligible={
          selectedResult &&
          Object.values(results[selectedResult]).some((value) => value)
        }
      />
    </div>
  );
}

function Partners({
  result = [
    ["partnerA", true],
    ["partnerB", false],
    ["partnerC", true],
  ],
  open,
  onClose,
}) {
  const { t } = useTranslation(["common", "financing"]);
  const [selectedPartner, setSelectedPartner] = useState("");

  // expand first partner in the list on init
  const openFirstPartner = false;
  useEffect(() => {
    if (openFirstPartner) {
      if (result) setSelectedPartner(result[0][0]);
    }
  }, [result]);

  const handleSelectPartner = (newPartner) => {
    setSelectedPartner((prevPartner) =>
      prevPartner === newPartner ? "" : newPartner,
    );
  };

  const partnerTransKey = (partner) =>
    `financing:assessment.partners.${partner}`;

  const getSection = (partner, section) => {
    return {
      id: section,
      title: t([partnerTransKey(partner), section, "title"].join(".")),
      items: t([partnerTransKey(partner), section, "items"].join("."), {
        returnObjects: true,
      }),
    };
  };

  const partnersData = usePartners();

  const partners = partnersData
    .map((partner) => partner.id)
    .map((partnerID) => ({
      id: partnerID,
      name: partnersData.filter((partner) => partner.id === partnerID)[0].name,
      summary: t([partnerTransKey(partnerID), "summary"].join(".")),
      logo: {
        src: partnersData.filter((partner) => partner.id === partnerID)[0].logo,
        alt: `logo-${partnerID}`,
      },
      sections: ["about", "conditions", "costs"].map((section) =>
        getSection(partnerID, section),
      ),
    }));

  const handleCloseDrawer = () => {
    setSelectedPartner("");
    onClose();
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleCloseDrawer}
      className="PartnersDrawer"
    >
      <DrawerHeader
        title={t("financing:assessment.drawer.title")}
        subtitle={t("financing:assessment.drawer.message")}
        onClose={handleCloseDrawer}
      />

      <div className="PartnersDrawer-content">
        <div className="PartnersDrawer-content-partners">
          {result &&
            result
              .sort((resultA, resultB) => (resultA[0] < resultB[0] ? -1 : 1)) // sort partner ids alphabetically
              .map(([partnerID, eligible]) => (
                <Partner
                  key={partnerID}
                  eligible={eligible}
                  selected={selectedPartner === partnerID}
                  onSelect={handleSelectPartner}
                  {...partners.filter(
                    (partner) =>
                      partner.id.toLowerCase() === partnerID.toLowerCase(),
                  )[0]}
                />
              ))}
        </div>
      </div>
    </Drawer>
  );
}

function Result({
  id = "stock",
  name = "Dépenses fournisseurs (stock)",
  description,
  img,
  onUnlock,
  onInfo,
  eligible = false,
  reseller,
}) {
  const { t } = useTranslation(["common"]);

  const heading = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>{name}</div>
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <Button
          style={{ color: "var(--color-leano-1)" }}
          icon={<ArticleOutlined />}
          onClick={() => onInfo(id)}
          title={t("financing:assessment.drawer.title")}
        />
        {!reseller && eligible && (
          <Button
            icon={<ArrowForwardRounded />}
            onClick={() => onUnlock(id)}
            title={t("financing:unlock.title")}
          />
        )}
      </div>
    </div>
  );

  return (
    <Card className="Result" title={name}>
      <ImageContainer
        className="Result-img"
        src={img}
        alt={name}
        backgroundColor={`var(--color-need-${id})`}
      />
      <CardText heading={heading} subheading={description} />
      <div style={{ position: "absolute", top: "1rem", right: "1rem" }}>
        <Pill
          text={
            eligible
              ? capitalize(t("common:available"))
              : capitalize(t("common:unavailable"))
          }
          color={eligible ? "green" : "red"}
        />
      </div>
    </Card>
  );
}

function Partner({
  selected,
  onSelect,
  eligible,
  summary,
  id,
  name,
  logo,
  sections,
}) {
  const { t } = useTranslation(["common", "financing"]);

  return (
    <div className="Partner">
      <div
        className="Partner-wrapper"
        onClick={() => onSelect(id)}
        style={{
          borderBottom: !selected
            ? "1px solid var(--color-neutral-2)"
            : "1px dashed var(--color-neutral-2)",
        }}
      >
        <div className="Partner-wrapper-header">
          <span className="Partner-wrapper-header-name">{name}</span>
          <Pill
            text={
              eligible
                ? t("financing:assessment.partners.common.eligible")
                : t("financing:assessment.partners.common.nonEligible")
            }
            color={eligible ? "green" : "red"}
          />
        </div>
      </div>

      <PartnerDetails
        key={id}
        eligible={eligible}
        selected={selected}
        id={id}
        logo={logo}
        summary={summary}
        sections={sections}
      />
    </div>
  );
}

export function Pill({
  className,
  text,
  color,
  selected,
  useSelection = false,
}) {
  const variantEnum = { green: "green", red: "red", yellow: "yellow" };
  const [variantID] = useState(variantEnum[color] || "default");
  const variants = [
    {
      id: "default",
      color: "var(--color-leano-2)",
      backgroundColor: "var(--color-leano-6)",
    },
    {
      id: "green",
      color: "var(--color-success-1)",
      backgroundColor: "var(--color-success-2)",
    },
    {
      id: "red",
      color: "var(--color-error-1)",
      backgroundColor: "var(--color-error-2)",
    },
    {
      id: "yellow",
      color: "var(--color-warning-1)",
      backgroundColor: "var(--color-warning-2)",
    },
  ];
  return (
    <span
      className={["Pill", className].filter(Boolean).join(" ")}
      style={{
        borderColor: variants.filter((variant) => variant.id === variantID)[0]
          .color,
        color: variants.filter((variant) => variant.id === variantID)[0].color,
        backgroundColor: variants.filter(
          (variant) => variant.id === variantID,
        )[0].backgroundColor,
        cursor: useSelection ? "pointer" : "unset",
      }}
    >
      {text}
      {useSelection ? (
        !selected ? (
          <ChevronRight style={{ fontSize: "small" }} />
        ) : (
          <ExpandMore style={{ fontSize: "small" }} />
        )
      ) : null}
    </span>
  );
}

function PartnerDetails({ selected, logo, summary, sections }) {
  return (
    <div
      className="Partner-details"
      style={{
        display: selected ? "block" : "none",
        borderBottom: selected ? "1px solid var(--color-neutral-2)" : "none",
      }}
    >
      <div style={{ display: "none" }}>
        <ImageContainer
          className="Partner-details-logo"
          alt={logo.alt}
          src={logo.src}
        />
      </div>

      <p className="Partner-details-summary">{summary}</p>

      <div style={{ marginTop: "1rem" }}>
        {sections &&
          sections.map((section) => (
            <PartnerSection key={section.title} {...section} />
          ))}
      </div>
    </div>
  );
}

function PartnerSection({ title, items }) {
  return (
    <details>
      <summary style={{ margin: "0.5rem 0", cursor: "pointer" }}>
        {title}
      </summary>
      <ul>
        {Array.isArray(items) &&
          items.map((item, index) => <li key={index}>{item}</li>)}
      </ul>
    </details>
  );
}

export function ProgressBar({ length = 5, index = 1, status = false }) {
  return (
    <div className="ProgressBar">
      <span className="ProgressBar-numerical">
        {`${status ? index : index + 1}/${length}`}
      </span>
      <div className="ProgressBar-wrapper">
        <div
          className="ProgressBar-wrapper-progress"
          style={{
            width: `${((status ? index : index + 1) * 100) / length}%`,
            backgroundColor:
              (status && index === length) || (!status && index + 1 === length)
                ? "var(--color-success-1)"
                : "var(--color-leano-5)",
          }}
        ></div>
      </div>
    </div>
  );
}

function Question({
  displayed,
  id,
  question,
  value,
  currency,
  min,
  type,
  options,
  onChange,
  button,
  onForward,
  onBackward,
}) {
  const { t } = useTranslation(["common", "financing"]);

  const inputs = [
    {
      type: type,
      name: id,
      label: question,
      value: value,
      min: min,
      onChange: (value) => onChange(value, id),
      required: true,
      options: options,
      onFormatNumber:
        type === "number"
          ? (value) => useFormatAmount(value, i18n.resolvedLanguage, currency)
          : null,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    onSubmit: onForward,
    onCancel: onBackward,
    button: button,
    cancelButton: t("common:back"),
    resetLoader: true,
  };
  return (
    <div style={!displayed ? { display: "none" } : null}>
      <Form {...form} />
    </div>
  );
}

function useResults(
  init = {
    long_term: {
      Defacto: true,
      Silvr: false,
    },
    short_term: {
      Defacto: true,
      Silvr: false,
      Karmen: true,
    },
  },
) {
  const [results, setResults] = useState(init);
  return { results, setResults };
}
