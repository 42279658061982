const moment = require("moment");

/**
 * Given a list of bank statements (of a given bank account), return the dates (as ISO string) of
 * months not yet covered by a bank statement over the given period (intended as number of months),
 * excluding those preceeding the company creation.
 * @param {Object} data the fetched bank-statement list
 * @param {String} startingDate date at which statement-check should start
 * (immatriculation date or account-opening date)
 * @param {Number} period the number of last months to be checked
 * @returns {Array} the array of ISO-string dates not covered by a bank statement
 */
export function getMissingBankStatements(data, startingDate, period = 12) {
  let today = moment();
  let months = Array.from({ length: period }, (_, i) =>
    moment(today)
      .subtract(i + 1, "months")
      .date(15)
      .toISOString()
      .substring(0, 10),
  );
  const imm_year_month = startingDate.substring(0, 7);
  let coveredMonths = data.map((statement) => statement.period);
  return months.filter(
    (month) => month >= imm_year_month && !coveredMonths.includes(month),
  );
}

/**
 * Given a list of financial statements (of a given company), return the years (as string) not yet
 * covered by a statement over the given period (intended as number of years), excluding those
 * preceeding the company creation.
 * @param {Object} data the fetched statement list
 * @param {String} immatriculationDate the immatriculation date of the company
 * @param {Number} period the number of years to be checked
 * @returns {Array} the array of years as string not covered by a financial statement
 */
export function getMissingFinancialStatements(
  data,
  immatriculationDate,
  period = 3,
) {
  let today = moment();
  let years = Array.from({ length: period }, (_, i) =>
    moment(today)
      .subtract(i + 1, "years")
      .year()
      .toString(),
  );
  const imm_year = immatriculationDate.substring(0, 4);
  let coveredYears = data.map((statement) => statement.year);
  return years.filter(
    (year) => year >= imm_year && !coveredYears.includes(year),
  );
}

/**
 * Capitalize string
 * @param {string} str string
 * @returns capitalized string
 */
export function capitalize(str = "hello") {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
