import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Header, Heading, Main, SubHeading } from "../commons/Template";
import authAPI from "../../api/authentication";
import routes, { dashboardRoute } from "../../routes/routes";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { useAuth } from "../../auth/AuthContext";

// Perform e-mail address validation upon user click on e-mail button;
// if validation fails show message, else redirect to log-in page.
export default function EmailConfirmation() {
  const { t } = useTranslation(["common", "account"]);
  const { key } = useParams();
  const [unvalidKey, setUnvalidKey] = useState(false);
  const [next, setNext] = useState(false);
  const navigate = useNavigate();
  document.title = t("account:emailConfirmation.title");
  const dispatchToast = useToastsDispatch();
  const { loadingLogged, logged } = useAuth();

  const confirmEmail = async () => {
    const response = await authAPI.verifyEMailAddress(key);
    if (response.status === 400) {
      setUnvalidKey(true);
    } else {
      setNext(true);
    }
  };

  useEffect(() => {
    confirmEmail();
  }, []);

  const handleNext = () => {
    const toast = {
      type: "add",
      variant: "success",
      heading: t("account:emailConfirmation.message.title"),
      subheading: t("account:emailConfirmation.message.content"),
    };
    if (!loadingLogged) {
      if (logged) {
        navigate(dashboardRoute);
        dispatchToast(toast);
      } else {
        dispatchToast(toast);
        navigate(routes.Account.Login);
      }
    }
  };

  useEffect(() => {
    if (next) handleNext();
  }, [next]);

  if (loadingLogged) return <></>;
  return unvalidKey ? (
    <Main>
      <Header>
        <Heading>
          <Trans
            t={t}
            i18nKey="account:emailConfirmation.NOK.heading"
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
        <SubHeading>
          <>
            <span>
              <Trans
                t={t}
                i18nKey="account:emailConfirmation.NOK.subheading"
                components={{
                  // eslint-disable-next-line
                  anchor: <a href={routes.Settings} />,
                }}
              />
            </span>
          </>
        </SubHeading>
      </Header>
    </Main>
  ) : null;
}
