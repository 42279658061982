import { useState, useEffect, Fragment } from "react";
import Notification from "./Notification";
import { Header, Content, Main } from "../commons/Template";
import { FormLoader } from "../commons/Form";
import { useTranslation, Trans } from "react-i18next";
import Input from "../commons/Input";
import { useAuth } from "../../auth/AuthContext";
import api from "../../api/resources";
import routes, { dashboardRoute } from "../../routes/routes";
import { CheckBoxOutlined, Close } from "@mui/icons-material";
import Button from "../commons/Button";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";

export default function Notifications() {
  const { t } = useTranslation(["notifications"]);
  document.title = t("notifications:title");
  const { onNotificationRead } = useAuth();
  const dispatchToast = useToastsDispatch();
  const [notifications, setNotifications] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [selectAllToggle, setSelectAllToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectAllShown, setSelectAllShown] = useState(true);
  const [menuShown, setMenuShown] = useState(false);

  const sortNotifications = (notifications) => {
    const unreadNotifications = notifications.filter(
      (notification) => !notification.read,
    );
    const readNotifications = notifications.filter(
      (notification) => notification.read,
    );
    const sortByDescendingDate = (a, b) => (a.timestamp < b.timestamp ? 1 : -1);
    unreadNotifications.sort(sortByDescendingDate);
    if (unreadNotifications.length === 0) setSelectAllShown(false);
    readNotifications.sort(sortByDescendingDate);

    return [...unreadNotifications, ...readNotifications];
  };

  useEffect(() => {
    const convertDataIntoProps = (rawData) => {
      const { key, ...rest } = rawData;

      const commonProps = {
        title: t(`notifications:keys.${key}.title`),
        message: (
          <Trans
            shouldUnescape
            t={t}
            i18nKey={`notifications:keys.${key}.message`}
            values={rest}
          />
        ),
        button: t(`notifications:keys.${key}.button`),
      };

      switch (key) {
        case "invitation_expired":
          return {
            ...commonProps,
            url: routes.Reseller.clients,
            isExternalURL: false,
          };
        case "bank_synchronisation":
          return {
            ...commonProps,
            url: null, // set in `getNotifications` below to be able to await API call for final URL
            isExternalURL: true,
          };

        case "SEPA_verification_failed":
          return {
            ...commonProps,
            url: routes.Stripe.root,
            isExternalURL: true,
          };

        case "duplicated_account":
          return {
            ...commonProps,
            url: "",
            isExternalURL: false,
          };
        case "loan_refused":
          return {
            ...commonProps,
            url: dashboardRoute,
            isExternalURL: false,
          };
        case "loan_accepted":
          return {
            ...commonProps,
            url: dashboardRoute,
            isExternalURL: false,
          };
        case "creditLine_created":
          return {
            ...commonProps,
            url: dashboardRoute,
            isExternalURL: false,
          };
        case "creditLine_updated":
          return {
            ...commonProps,
            url: dashboardRoute,
            isExternalURL: false,
          };
        case "new_admin":
          return {
            ...commonProps,
            url: routes.Settings.users,
            isExternalURL: false,
          };
        case "join_company":
          return {
            ...commonProps,
          };

        default:
          return {
            ...commonProps,
            url: "",
            isExternalURL: "",
          };
      }
    };

    const otherCommonProps = {
      onAdd: (id) => setSelectedIDs((prevIDs) => [...prevIDs, id]),
      onRemove: (id) =>
        setSelectedIDs((prevIDs) => prevIDs.filter((prevID) => prevID !== id)),
    };

    const getNotifications = async () => {
      const response = await api.getNotifications();
      if (response.ok) {
        const rawNotifications = await response.json();

        const sortedNotifications = sortNotifications(rawNotifications);

        let finalNotifications = [];
        // never use forEach for mutliple async!
        for (const notif of sortedNotifications) {
          const data = notif.data;
          let url = "";
          if (data.key === "bank_synchronisation") {
            const bankID = notif.id;
            const response = await api.getResyncBankURL(bankID);
            if (response.ok) {
              let jsonResponse = await response.json();
              url = jsonResponse.URL;
            }
          }
          const newNotif = {
            ...otherCommonProps,
            id: notif.id,
            timestamp: notif.timestamp,
            read: notif.read,
            ...convertDataIntoProps(data),
            url: url,
          };

          finalNotifications = [...finalNotifications, newNotif];
        }

        setNotifications(finalNotifications);

        setLoading(false);
      }
    };

    getNotifications();
  }, [t]);

  // useEffect(() => {
  //   if (selectedIDs.length !== 0) {
  //     setNotifications((prevNotifications) =>
  //     sortNotifications(prevNotifications)
  //   );
  //   }

  // }, [notifications, selectedIDs]);

  // Test notifications
  // useEffect(() => {
  //   setNotifications([
  //     {
  //       id: "id1",
  //       title: "Hello",
  //       timestamp: "2023-12-04",
  //       message: "hello",
  //       url: "www.google.com",
  //       isExternalURL: true,
  //       selectedByControl: false,
  //       read: false,
  //       onAdd: (id) => setSelectedIDs((prevIDs) => [...prevIDs, id]),
  //       onRemove: (id) =>
  //         setSelectedIDs((prevIDs) =>
  //           prevIDs.filter((prevID) => prevID !== id)
  //         ),
  //     },
  //     {
  //       id: "id2",
  //       title: "Bonjour",
  //       timestamp: "2023-12-04",
  //       message: "bonjour",
  //       url: "www.microsoft.com",
  //       isExternalURL: true,
  //       selectedByControl: false,
  //       read: false,
  //       onAdd: (id) => setSelectedIDs((prevIDs) => [...prevIDs, id]),
  //       onRemove: (id) =>
  //         setSelectedIDs((prevIDs) =>
  //           prevIDs.filter((prevID) => prevID !== id)
  //         ),
  //     },
  //   ]);
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    if (selectAllToggle) {
      setNotifications((prevNotifications) => [
        ...prevNotifications.map((prevNotification) => ({
          ...prevNotification,
          selectedByControl: true,
        })),
      ]);
    } else {
      setNotifications((prevNotifications) => [
        ...prevNotifications.map((prevNotification) => ({
          ...prevNotification,
          selectedByControl: false,
        })),
      ]);
    }
  }, [selectAllToggle]);

  useEffect(() => {
    if (selectedIDs.length === 0) {
      setSelectAllToggle(false);
    } else if (selectedIDs.length === notifications.length) {
      setSelectAllToggle(true);
    }
  }, [selectedIDs, notifications.length]);

  const handleMarkAsRead = () => {
    selectedIDs.forEach(async (selectedID) => {
      const response = await api.readNotification(selectedID);
      if (response.ok) {
        // Mark as read in state
        setNotifications((prevNotifications) => [
          ...prevNotifications.map((prevNotification) =>
            prevNotification.id === selectedID
              ? { ...prevNotification, read: true }
              : prevNotification,
          ),
        ]);
        // Remove read ID from selected ids
        setSelectedIDs((prevIDs) => prevIDs.filter((ID) => ID !== selectedID));
        // Sort notifications after mark as read // maybe better to do this in a use-effect observing a state?
        setNotifications((prevNotifications) =>
          sortNotifications(prevNotifications),
        );
        onNotificationRead();
      } else {
        dispatchToast({
          type: "add",
          variant: "warning",
          subheading: t("notifications:ui.toasts.error.subheading"),
        });
      }
    });

    // // Unselect all after mark all as read (bonus feature / can be disabled by commenting next setNotifications)
    // setNotifications((prevNotifications) => [
    //   ...prevNotifications.map((prevNotification) => ({
    //     ...prevNotification,
    //     selectedByControl: false,
    //   })),
    // ]);
  };

  return (
    <Main themeSwitch>
      <Header
        heading={t("notifications:heading")}
        subheading={t("notifications:subheading")}
      />
      <Content>
        {loading ? (
          <FormLoader />
        ) : (
          <div className="Notifications">
            {selectAllShown ? (
              <div className="Notifications-buttons-container">
                {menuShown ? (
                  <div className="Notifications-buttons">
                    {selectAllShown ? (
                      <Input
                        type="checkbox"
                        label={
                          selectedIDs.length > 0
                            ? `${selectedIDs.length}`
                            : t("notifications:ui.selectAll")
                        }
                        value={selectAllToggle}
                        onChange={setSelectAllToggle}
                      />
                    ) : null}
                    {selectedIDs.length > 0 ? (
                      <Button
                        onClick={handleMarkAsRead}
                        text={
                          selectedIDs.length === 1
                            ? t("notifications:ui.mark1AsRead")
                            : t("notifications:ui.markAsRead")
                        }
                        variant="secondary_b"
                      />
                    ) : null}
                  </div>
                ) : null}

                {selectAllShown ? (
                  <Button
                    onClick={() => setMenuShown((prev) => !prev)}
                    icon={menuShown ? <Close /> : <CheckBoxOutlined />}
                    className={"Notifications-buttons-activation"}
                  />
                ) : null}
              </div>
            ) : null}

            <div className="Notifications-list">
              {notifications.map((notification) => (
                <Fragment key={notification.id}>
                  <Notification
                    {...notification}
                    selectAllShown={menuShown && selectAllShown}
                    selectedByControl={
                      notification.read ? false : notification.selectedByControl
                    }
                  />
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </Content>
    </Main>
  );
}
