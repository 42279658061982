import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import frLocale from "i18n-iso-countries/langs/fr.json";
import itLocale from "i18n-iso-countries/langs/it.json";

export function useCountries() {
  countries.registerLocale(enLocale);
  countries.registerLocale(frLocale);
  countries.registerLocale(itLocale);
  return countries;
}
