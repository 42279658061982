import { createContext, useContext, useReducer } from "react";

const ModalContext = createContext(null);

const ModalDispatchContext = createContext(null);

export function ModalProvider({ children }) {
  const [modal, dispatchModal] = useReducer(modalReducer, initialModal);

  return (
    <ModalContext.Provider value={modal}>
      <ModalDispatchContext.Provider value={dispatchModal}>
        {children}
      </ModalDispatchContext.Provider>
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}

export function useModalDispatch() {
  return useContext(ModalDispatchContext);
}

function modalReducer(existingModal, action) {
  const { type, ...newModal } = action;

  switch (type) {
    case "add": {
      return { ...newModal, success: false };
    }
    case "success": {
      return {
        ...existingModal,
        onSuccess: newModal.onSuccess
          ? newModal.onSuccess
          : existingModal.onSuccess
            ? existingModal.onSuccess
            : null,
        success: true,
      };
    }
    case "delete": {
      return null;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

const initialModal = null;
