import { useEffect, useState } from "react";

export default function FancyButton({
  heading,
  subheading,
  icon,
  iconConf,
  onClick,
  primary,
  useHover = false,
  activeButton,
  setActiveButton,
}) {
  const [hoverClassName, setHoverClassName] = useState("");

  useEffect(() => {
    if (useHover) {
      if (primary && !activeButton) {
        setHoverClassName("FancyButton-hover");
      } else {
        setHoverClassName("");
      }
    }
  }, [useHover, activeButton, primary]);

  return (
    <div
      className={`FancyButton ${hoverClassName}`}
      onClick={onClick}
      onMouseOver={() => {
        if (useHover) {
          if (!primary) {
            setHoverClassName("FancyButton-hover");
            setActiveButton(true);
          }
        }
      }}
      onMouseLeave={() => {
        if (useHover) {
          if (!primary) {
            setActiveButton(false);
          }
        }
      }}
    >
      <div className="FancyButton-icon">{icon}</div>
      <div className="FancyButton-text">
        {heading ? <h3>{heading}</h3> : null}
        {subheading ? <span>{subheading}</span> : null}
      </div>
      <div className="FancyButton-icon FancyButton-icon-conf ">{iconConf}</div>
    </div>
  );
}
