import { useEffect, useState } from "react";
import { Setting, SettingDisplay } from "../Settings";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../../i18n";
import LangSwitch from "../../commons/LangSwitch";

export default function LangSet() {
  const [language, setLanguage] = useState("");
  const { i18n } = useTranslation();
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.language.label");

  const update = () => setLanguage(availableLanguages[i18n.resolvedLanguage]);

  useEffect(() => {
    update();
  }, [i18n.resolvedLanguage]);

  return (
    <Setting>
      <SettingDisplay label={label} value={language} />
      <LangSwitch />
    </Setting>
  );
}
