import { Setting, SettingDisplay, SettingSwitcher } from "../Settings";
import { useTranslation } from "react-i18next";

export default function EcomSet({ value, onChange }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.company.ecom.label");

  return (
    <Setting>
      <SettingDisplay
        label={label}
        value={value ? t("common:yes") : t("common:no")}
      />
      <SettingSwitcher value={value} onSwitch={onChange} />
    </Setting>
  );
}
