import { useEffect, useRef, useState } from "react";
import { Eye, EyeOff } from "react-feather";
import PhoneInput from "react-phone-number-input";
import CustomSelect from "./CustomSelect";
import FileDrop from "./FileDrop";
import { Radio, RadioGroup as MuiRadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

// Input-component Container (label + input + errors container)
export default function Input({
  label,
  type,
  name,
  autoComplete,
  value = "",
  minLength,
  maxLength,
  min,
  max,
  step,
  onChange,
  errors = [],
  className,
  disabled,
  placeholder = "",
  required = true,
  options,
  isSearchable,
  pattern,
  noOptionsMessage,
  isMulti,
  checkedIcon,
  onFormatNumber,
  isClearable,
  rowRadio,
}) {
  return (
    <div
      className={[
        "Input",
        className,
        type === "checkbox" && "CheckboxInput",
        rowRadio && "rowRadio",
      ]
        .filter(Boolean)
        .join(" ")}
    >
      <label>
        {label && <span>{label}</span>}
        <InputType
          type={type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          options={options}
          isSearchable={isSearchable}
          maxLength={maxLength}
          minLength={minLength}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
          noOptionsMessage={noOptionsMessage}
          isMulti={isMulti}
          checkedIcon={checkedIcon}
          onFormatNumber={onFormatNumber}
          isClearable={isClearable}
        />
      </label>

      {errors.length > 0 && (
        <ul className="Input-errors">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

// Helper component to choose type
function InputType({
  type,
  name,
  autoComplete,
  value,
  onChange,
  disabled,
  placeholder,
  required,
  options,
  isSearchable,
  maxLength,
  minLength,
  min,
  max,
  step,
  pattern,
  noOptionsMessage,
  isMulti,
  checkedIcon,
  onFormatNumber = null,
  isClearable,
}) {
  // number
  const [formattedValue, setFormattedValue] = useState(
    onFormatNumber ? onFormatNumber(value) : value,
  );
  const [focused, setFocused] = useState(false);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => {
    // value is custom validated
    if (onFormatNumber) validateNumber(value);
    setFocused(false);
  };

  const numberInputRef = useRef(null);

  const { t } = useTranslation(["common", "errors"]);

  const validateNumber = (value) => {
    // clear
    numberInputRef.current.setCustomValidity("");

    // check 1
    if (value < min) {
      numberInputRef.current.setCustomValidity(
        t("errors:inputs.min", { min: min }),
      );
    }

    // check 2
    if (value > max) {
      numberInputRef.current.setCustomValidity(
        t("errors:inputs.max", { min: max }),
      );
    }
  };

  const handleNumberChange = ({ target: { value } }) => {
    // raw value is conserved
    onChange(value);
    // formatted value is updated
    if (onFormatNumber) setFormattedValue(onFormatNumber(value));
  };

  // formatted value is also updated in case of onFormatNumber fn change (example: currency select change)
  useEffect(() => {
    if (onFormatNumber) setFormattedValue(onFormatNumber(value));
  }, [onFormatNumber]);

  // checkbox
  const handleCheck = ({ target: { checked } }) => onChange(checked);

  // all values
  const handleValueChange = ({ target: { value } }) => onChange(value);

  switch (type) {
    case "password":
      return (
        <PasswordInput
          autoComplete={autoComplete}
          value={value}
          name={name}
          minLength={8}
          maxLength={50}
          onChange={handleValueChange}
          required={required}
        />
      );
    case "tel":
      return (
        <PhoneInput
          value={value}
          onChange={onChange}
          international
          name="phone"
          required={required}
        />
      );
    case "checkbox":
      return (
        <input
          required={required}
          type="checkbox"
          checked={value}
          disabled={disabled}
          onChange={handleCheck}
          name={name}
        />
      );
    case "select":
      return (
        <CustomSelect
          value={value}
          options={options}
          onSelect={onChange}
          placeholder={placeholder}
          required={required}
          isSearchable={isSearchable}
          disabled={disabled}
          noOptionsMessage={noOptionsMessage}
          isMulti={isMulti}
          isClearable={isClearable}
        />
      );
    case "file":
      return <FileDrop value={value} onChange={onChange} />;
    case "radio":
      return (
        <RadioGroup
          name={name}
          required={required}
          value={value}
          options={options}
          onChange={onChange}
          checkedIcon={checkedIcon}
        />
      );
    case "number":
      return (
        <input
          ref={numberInputRef}
          required={required}
          type={onFormatNumber ? (focused ? "number" : "text") : type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          value={onFormatNumber ? (focused ? value : formattedValue) : value}
          onChange={handleNumberChange}
          disabled={disabled}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      );
    default:
      return (
        <input
          required={required}
          type={type}
          name={name}
          id={name}
          autoComplete={autoComplete}
          value={value}
          onChange={handleValueChange}
          disabled={disabled}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
        />
      );
  }
}

// Special password component with password toggle logic
function PasswordInput({ autoComplete, value, onChange, name, required }) {
  const [shownPassword, setShownPassword] = useState(false);
  const togglePassword = () => setShownPassword(!shownPassword);

  return (
    <div className="PasswordInput">
      <input
        className="PasswordInputInput"
        name={name}
        id={name}
        required={required}
        type={shownPassword ? "text" : "password"}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
      <div onClick={togglePassword} className="PasswordInputToggle">
        {shownPassword ? <EyeOff /> : <Eye />}
      </div>
    </div>
  );
}

function RadioGroup({
  name,
  required,
  options,
  value = null,
  onChange,
  checkedIcon,
}) {
  const [radioValue, setRadioValue] = useState(
    value ? options.find((option) => option.value === value.value).value : "",
  );

  const handleRadioValueChange = ({ target: { value } }) => {
    const localValue = value.toString();
    setRadioValue(localValue);
    const selectedOption = options.find(
      (option) => option.value.toString() === localValue,
    );
    onChange(selectedOption);
  };

  return (
    <MuiRadioGroup
      className="RadioGroup"
      name={name}
      value={radioValue}
      onChange={handleRadioValueChange}
      required={required}
    >
      {options.map((option, index) => (
        <div key={index} className="RadioGroup-Input">
          {option.label && (
            <label>
              <Radio
                required={required}
                sx={{ padding: 0 }}
                checkedIcon={checkedIcon}
                value={option.value}
                label={option.label}
              />
              {option.label}
            </label>
          )}
        </div>
      ))}
    </MuiRadioGroup>
  );
}
