import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../auth/AuthContext";
import { VerifyPasswordModal } from "../Settings/Settings";
import { useModalDispatch } from "./Modal/ModalContext";

export default function PasswordProtector({ children }) {
  const { t } = useTranslation(["common", "settings"]);
  const [pass, setPass] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { allowed, onAllow } = useAuth();
  const dispatchModal = useModalDispatch();

  useEffect(() => {
    // isInSameSubDirectory
    let prevPathname = "";
    if (location.state) {
      if ("prevPathname" in location.state) {
        prevPathname = location.state.prevPathname;
      }
    }
    const currentSubDirectory = location.pathname.split("/")[1];
    const isInSameSubDirectory = prevPathname.includes(currentSubDirectory);

    if (isInSameSubDirectory || allowed) {
      onAllow(false); // resets global allow for next come back
      setPass(true);
    } else {
      const modal = {
        title: t("settings:modals.verifyPassword.title"),
        message: t("settings:modals.verifyPassword.message"),
        component: <VerifyPasswordModal />,
        onSuccess: () => setPass(true),
        onClose: () => navigate(routes.Home),
      };
      dispatchModal({ type: "add", ...modal });
    }

    // eslint-disable-next-line
  }, []);

  if (pass) return children;
  return <></>;
}

export function PasswordProtectorMirror() {
  const { onAllow } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    onAllow(true);
    navigate(routes.Vault.bank);
  }, [onAllow, navigate]);

  return <></>;
}
