import { del, get, post } from "./requests";

const root = "api/allauth/browser/v1/";
const auth = root + "auth/";
const account = root + "account/";

const endPoints = {
  session: auth + "state",
  signUp: auth + "sign-up",
  verifyEMail: auth + "email/verify",
  logIn: auth + "login",
  reauthenticate: auth + "reauthenticate",
  requestPasswordReset: auth + "password/request",
  verifyPasswordKey: auth + "password/verify",
  resetPassword: auth + "password/reset",
  changeEMailAddress: account + "email",
  changePassword: account + "password/change",
};

const api = {
  /**
   * Check user status
   * @returns {Promise<Boolean>} tell if user is authenticated
   */
  async getUserInfo() {
    return get(endPoints.session).then((resp) => resp.json());
  },

  /**
   * Submit data of signing-up user
   * @param {Object} newUserData user data
   * @returns {Promise<Response>} server response
   */
  signUp(newUserData) {
    return post(endPoints.signUp, newUserData);
  },

  /**
   * Confirm user e-mail address
   * @param {string} key verification key
   * @returns {Promise<Response>} server response
   */
  async verifyEMailAddress(key) {
    return post(endPoints.verifyEMail, { key: key });
  },

  /**
   * Request password-reset e-mail
   * @param {String} eMail user e-mail address
   * @returns {Promise<Response>} server response
   */
  requestPasswordResetEMail(eMail) {
    return post(endPoints.requestPasswordReset, { email: eMail });
  },

  /**
   * Reset user password
   * @param {String} key validation key
   * @param {newPassword} newPassword new password
   * @returns {Promise<Response>} server response
   */
  verifyResetKey(key) {
    return get(endPoints.verifyPasswordKey + "?key=" + key);
  },

  /**
   * Reset user password
   * @param {String} key validation key
   * @param {newPassword} newPassword new password
   * @returns {Promise<Response>} server response
   */
  resetPassword(key, newPassword) {
    return post(endPoints.resetPassword, { key: key, password: newPassword });
  },

  /**
   * Log user in
   * @param {Object} credentials user credentials
   * @returns {Promise<Response>} server response
   */
  signIn(credentials) {
    return post(endPoints.logIn, credentials);
  },

  /**
   * Re-verify user password
   * @param {String} user passeord
   * @returns {Promise<Response>} server response
   */
  reauthenticate(password) {
    return post(endPoints.reauthenticate, { password: password });
  },

  /**
   * Log user out
   * @returns {Promise<Response>} server response
   */
  signOut() {
    return del(endPoints.session);
  },

  /**
   * Change user e-mail address
   * @param {String} eMail new e-mail address
   */
  changeEMailAddress(eMail) {
    return post(endPoints.changeEMailAddress, { email: eMail });
  },

  /**
   * Modify user password
   * @param {String} currentPassword current password
   * @param {String} newPassword new password
   */
  changePassword(currentPassword, newPassword) {
    return post(endPoints.changePassword, {
      current_password: currentPassword,
      new_password: newPassword,
    });
  },
};

/**
 * @param {Array} errors the errors array as returned by the authentication API
 * @returns {Object} an ocject containing an entry for each erroneous field, with a list of all the
 * associated error messages
 */
export function mapAuthErrors(errors) {
  var mappedData = {};
  for (let error of errors) {
    mappedData[error.param] ||= [];
    mappedData[error.param].push(error.message);
  }
  return mappedData;
}

export default api;
