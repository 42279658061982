import { useHelper, useHelperDispatch } from "./HelperContext";
import Card, { CardText } from "../Card";
import ImageContainer from "../ImageContainer";
import Button from "../Button";
import helperImg from "../../../assets/helper/helper.png";
import { CloseFullscreen } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Helper() {
  const helper = useHelper();
  const navigate = useNavigate();

  return (
    helper && (
      <div className="Helper-wrapper">
        <HelperComponent>
          <HelperCard
            heading={helper.heading}
            subheading={helper.subheading}
            button={helper.button}
            onClick={() => {
              const target = helper.navigate.target;
              const state = helper.navigate.state;
              navigate(target, { state: state });
            }}
          />
        </HelperComponent>
      </div>
    )
  );
}

export function HelperComponent({ children = <HelperCard /> }) {
  return (
    <div className="Helper">
      {children}
      <HelperToggle />
    </div>
  );
}

export function HelperCard({
  heading = "Hello",
  subheading = "Need help?",
  button = "Help",
  onClick = () => alert("Important info"),
}) {
  const helper = useHelper();
  return (
    <div style={!helper.expand ? { display: "none" } : null}>
      <Card className={"HelperCard"} pop>
        <ImageContainer src={helperImg} />
        <CardText heading={heading} subheading={subheading}>
          <Button
            style={{ marginTop: "1rem" }}
            text={button}
            variant={"primary_a"}
            onClick={onClick}
          />
        </CardText>
      </Card>
    </div>
  );
}

function HelperToggle() {
  const dispatchHelper = useHelperDispatch();
  const helper = useHelper();

  return (
    <Button
      icon={
        <CloseFullscreen
          style={!helper.expand ? { visibility: "hidden" } : null}
          fontSize="x-small"
        />
      }
      className="HelperToggle"
      onClick={() => dispatchHelper({ type: "toggle" })}
    />
  );
}
