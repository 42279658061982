import { Fragment, useState } from "react";
import { Edit, Lock } from "react-feather";
import { Link, Outlet } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Content, Header, Main } from "../commons/Template";
import Form from "../commons/Form";
import { Pill } from "../Financing/Assessment";
import authAPI from "../../api/authentication";
import routes from "../../routes/routes";
import { switchTheme } from "../commons/Switch";
import { useAuth } from "../../auth/AuthContext";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import useRootRedirect from "../../hooks/commons/useRootRedirect";

export default function Settings() {
  const { t } = useTranslation(["common", "navigation", "settings"]);
  const { user, activeCompany } = useAuth();

  const tabKey = "settings";

  const header = {
    heading: t(`navigation:tabs.${tabKey}.title`),
    subheading: t(`navigation:tabs.${tabKey}.subheading`),
  };

  const tabs = [
    {
      to: routes.Settings.profile,
      title: t("settings:tabs.profile.title"),
    },
    {
      to: routes.Settings.password,
      title: t("settings:tabs.password.title"),
    },
    {
      to: routes.Settings.users,
      title: t("settings:tabs.users.title"),
      hidden: !user.is_admin || !activeCompany,
    },
    {
      to: routes.Settings.company,
      title: t("settings:tabs.company.title"),
      hidden: !activeCompany,
    },
    {
      to: routes.Settings.subscription,
      title: t("settings:tabs.subscription.title"),
      hidden: !activeCompany,
    },
  ];

  useRootRedirect(routes.Settings.root, routes.Settings.profile);

  return (
    <Main className="Settings" themeSwitch>
      <Header {...header} tabs={tabs} />
      <Content>
        <MobileSettings />
        <DesktopSettings />
      </Content>
    </Main>
  );
}

function MobileSettings() {
  return (
    <div className="MobileSettings">
      <Outlet />
      <InfoShare />
    </div>
  );
}

function DesktopSettings() {
  return (
    <div className="DesktopSettings">
      <Outlet />
      <div className="DesktopSettings-InfoWrapper">
        <InfoShare />
      </div>
    </div>
  );
}

function InfoShare() {
  const { t } = useTranslation(["common", "settings"]);

  return (
    <Info
      className="InfoShare"
      title={t("settings:info.share.title")}
      text={t("settings:info.share.text")}
    />
  );
}

function Info({ className = "", title = "", text = "" }) {
  return (
    <aside className={"Info " + className}>
      <strong className="Info-title">{title}</strong>
      <p className="Info-text">{text}</p>
    </aside>
  );
}

export function SettingList({ children }) {
  return <div className="SettingList">{children}</div>;
}

export function Setting({ children }) {
  return <div className="Setting">{children}</div>;
}

export function SettingDisplay({ label, value, tag, tagColor }) {
  return (
    <div className="SettingDisplay">
      <div className="SettingDisplay-label">
        <span>{label}</span>
        {tag && <Pill text={tag} color={tagColor} />}
      </div>
      <div className="SettingDisplay-value">
        <span>{value}</span>
      </div>
    </div>
  );
}

function SettingChanger({ className, icon, action, onClick, children }) {
  const { t } = useTranslation(["common"]);
  return (
    <div
      onClick={onClick}
      className={["SettingChanger", className].filter(Boolean).join(" ")}
    >
      {children ? (
        children
      ) : (
        <Fragment>
          <div>{icon}</div> {action && <span>{t(`common:${action}`)}</span>}
        </Fragment>
      )}
    </div>
  );
}

export function SettingLocker() {
  const lockedDataModal = useLockedDataModal();
  const dispatchModal = useModalDispatch();

  return (
    <SettingChanger
      icon={<Lock />}
      className="Locker"
      onClick={() => dispatchModal({ type: "add", ...lockedDataModal })}
    />
  );
}

export function SettingSwitcher({ value, onSwitch }) {
  const handleSwitchChange = ({ target: { checked } }) => onSwitch(checked);

  return (
    <SettingChanger className={"Switcher"}>
      <ThemeProvider theme={switchTheme}>
        <Switch
          size="small"
          checked={value}
          onChange={(newValue) => handleSwitchChange(newValue)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </ThemeProvider>
    </SettingChanger>
  );
}

export function SettingModder({
  icon = <Edit />,
  action = "modify",
  modal,
  onClick,
}) {
  const dispatchModal = useModalDispatch();
  const { t } = useTranslation(["common"]);
  return (
    <SettingChanger
      className={"Modder"}
      icon={icon}
      action={action}
      onClick={
        modal
          ? () =>
              dispatchModal({
                type: "add",
                title: t("common:ModifyTitle"),
                ...modal,
              })
          : () => onClick()
      }
    />
  );
}

function useLockedDataModal() {
  const { t } = useTranslation(["common", "settings"]);

  const modal = {
    title: t("settings:modals.lockedData.title"),
    message: (
      <Trans
        t={t}
        i18nKey="settings:modals.lockedData.message"
        components={{
          anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
        }}
      />
    ),
  };

  return modal;
}

export function VerifyPasswordModal({ onNextModal = null }) {
  const dispatchModal = useModalDispatch();
  const { t } = useTranslation(["common", "settings"]);
  const [password, setPassword] = useState("");
  const [errorsObj, setErrorsObj] = useState({});

  const handlePasswordCheck = async () => {
    setErrorsObj({});

    const response = await authAPI.reauthenticate(password);
    if (response.ok) {
      setPassword("");
      if (onNextModal) {
        onNextModal();
      } else {
        dispatchModal({ type: "success" });
      }
    } else {
      setErrorsObj({
        password: [t("settings:modals.verifyPassword.component.form.error")],
      });
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const passwordInput = [
    {
      label: t("settings:items.password.label"),
      name: "current-password",
      autoComplete: "current-password",
      type: "password",
      value: password,
      onChange: setPassword,
      errors: getErrors("password"),
    },
  ];

  return (
    <Form
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handlePasswordCheck}
      inputs={[{ data: passwordInput }]}
      errors={getErrors("non_field_errors")}
    />
  );
}
