import { dashboardRoute } from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { Navigate } from "react-router-dom";

export default function ResellerRoute({ children }) {
  const { activeCompany, loadingActiveCompany } = useAuth();

  if (loadingActiveCompany) return <></>;
  if (!activeCompany.is_reseller) return <Navigate to={dashboardRoute} />;
  return children;
}
