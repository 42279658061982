import { useEffect } from "react";
import routes, { dashboardRoute } from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { Navigate } from "react-router-dom";

export default function PublicRouteWrapper({ children }) {
  const { loadingLogged, logged, loadingActiveCompany, activeCompany } =
    useAuth();

  const CustomNavigate = ({ path }) => {
    useEffect(() => {
      window.location.href = path;
    }, [path]);
    return <></>;
  };

  if (loadingLogged) return <></>;
  if (logged) {
    if (loadingActiveCompany) return <></>;
    if (!activeCompany) {
      return <Navigate to={routes.Company.root} />;
    }
    return <CustomNavigate path={dashboardRoute} />; // not using Navigate because needs refresh
  }
  return children;
}
