import { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Content,
  Header,
  Heading,
  Main,
  SubHeading,
} from "../commons/Template";
import Form from "../commons/Form";
import Input from "../commons/Input";
import authAPI from "../../api/authentication";
import routes from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";

// Allow user to insert e-mail address to request a password-reset e-mail
export default function PasswordResetKickOff() {
  const { t } = useTranslation(["common", "account"]);
  const { logged } = useAuth();

  document.title = t("account:passwordResetKickOff.title");
  const [pending, setPending] = useState(false);

  return (
    <Main>
      <Header>
        <Heading>
          <Trans
            t={t}
            i18nKey="account:passwordResetKickOff.heading"
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
        <SubHeading>
          {!pending ? (
            <>
              <span>
                <Trans
                  t={t}
                  i18nKey="account:passwordResetKickOff.kickoff.subheading"
                  components={{
                    anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
                  }}
                />
              </span>
              <CheckLogged logged={logged} />
            </>
          ) : (
            <>
              <span>
                <Trans
                  t={t}
                  i18nKey="account:passwordResetKickOff.pending.subheading"
                  components={{
                    anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
                  }}
                />
              </span>
              <CheckLogged logged={logged} />
            </>
          )}
        </SubHeading>
      </Header>
      <Content>
        {!pending ? <FormLocal setPending={setPending} /> : null}
      </Content>
    </Main>
  );
}

function FormLocal({ setPending }) {
  const { t } = useTranslation(["common", "account"]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState([]);

  const passwordResetRequest = async (email) => {
    const response = await authAPI.requestPasswordResetEMail(email);

    if (response.ok) {
      setPending(true);
    } else {
      setEmailError(["Une erreur s'est produite."]);
    }
  };

  const handlePasswordResetRequest = () => {
    passwordResetRequest(email);
  };

  const emailInput = {
    label: t("account:passwordResetKickOff.kickoff.form.email"),
    name: "email",
    type: "email",
    value: email,
    onChange: setEmail,
  };

  return (
    <Form
      button={t("account:passwordResetKickOff.kickoff.form.button")}
      errors={emailError}
      onSubmit={handlePasswordResetRequest}
    >
      <Input {...emailInput} />
    </Form>
  );
}

// Add "remark" component with current logged user, if any
function CheckLogged({ logged }) {
  const [user, setUser] = useState("");
  const { t } = useTranslation();

  const getUser = async () => {
    const response = await authAPI.getUserInfo();
    if (response.status === 200) {
      setUser(`${response.data.user.name} ${response.data.user.surname}`);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (logged) {
    return (
      <strong>
        {t("account:passwordResetKickOff.remark")}{" "}
        <span className="user">{user}</span>
      </strong>
    );
  }

  return <></>;
}
