import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "react-phone-number-input/style.css";
import {
  Content,
  Header,
  Heading,
  Main,
  SubHeading,
} from "../commons/Template";
import Form, { FormLoader } from "../commons/Form";
import Input from "../commons/Input";
import Inputs from "../commons/Inputs";
import api from "../../api/resources";
import authAPI from "../../api/authentication";
import { mapAuthErrors } from "../../api/authentication";
import routes from "../../routes/routes";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";

// Sign-up page
export default function SignUp() {
  // translation and page title
  const { t } = useTranslation(["common", "account"]);
  document.title = t("account:signup.title");

  const dispatchModal = useModalDispatch();

  // Component pivot
  const [confirmEmail, setConfirmEmail] = useState(false);

  // Get e-mail address from URL
  let [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [loadingInvitation, setLoadingInvitation] = useState(true);
  const [autoEmail, setAutoEmail] = useState(false);

  const getURLSearchParam = useCallback(
    (key) => {
      const searchParamsObj = Object.fromEntries(searchParams);
      return searchParamsObj[key];
    },
    [searchParams],
  );

  const confirmInvitation = useCallback(
    async (key) => {
      const response = await api.getInvitation(key);
      if (response.ok) {
        const invitation = await response.json();
        if (invitation) {
          setEmail(invitation.email);
          setAutoEmail(true);
        } else {
          const expiredInvitationModal = {
            title: t("account:signup.expiredInviteModal.title"),
            message: t("account:signup.expiredInviteModal.message"),
          };
          dispatchModal({ type: "add", ...expiredInvitationModal });
        }
        setLoadingInvitation(false);
      }
    },
    [dispatchModal, t],
  );

  useEffect(() => {
    const key = getURLSearchParam("key");
    if (key) {
      confirmInvitation(key);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Main>
      <Header>
        <Heading>
          {!confirmEmail ? (
            <Trans
              t={t}
              i18nKey="account:signup.start.heading"
              components={{
                emphasis: <span />,
              }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="account:signup.confirm.heading"
              components={{
                emphasis: <span />,
              }}
            />
          )}
        </Heading>
        <SubHeading>
          {!confirmEmail ? (
            <span>
              <Trans
                t={t}
                i18nKey="account:signup.start.subheading"
                components={{
                  anchor: <Link to={routes.Account.Login} />,
                }}
              />
            </span>
          ) : (
            <>
              <p>{t("account:signup.confirm.subheading.p1")}</p>
              <p>
                <Trans
                  t={t}
                  i18nKey="account:signup.confirm.subheading.p2"
                  components={{
                    anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
                  }}
                />
              </p>
            </>
          )}
        </SubHeading>
      </Header>
      <Content>
        {getURLSearchParam("key") && loadingInvitation ? (
          <FormLoader />
        ) : !confirmEmail ? (
          <FormLocal
            email={email}
            setEmail={setEmail}
            setConfirmEmail={setConfirmEmail}
            autoEmail={autoEmail}
          />
        ) : null}
      </Content>
    </Main>
  );
}

function FormLocal({ setConfirmEmail, email, setEmail, autoEmail }) {
  const dispatchToast = useToastsDispatch();

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState([]);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState([]);

  const [emailError, setEmailError] = useState([]);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState([]);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState([]);

  const [reseller, setReseller] = useState(null);

  const [legalConditions, setLegalConditions] = useState(false);

  const [formError, setFormError] = useState([]);

  const [errorsObj, setErrorsObj] = useState({});

  const navigate = useNavigate();
  const { t } = useTranslation(["common", "account"]);

  async function register(newUserData) {
    const response = await authAPI.signUp(newUserData);

    // Map back-end field names to functions for error messages
    const mapErrorFunctions = {
      first_name: setFirstNameError,
      last_name: setNameError,
      email: setEmailError,
      telephone: setPhoneError,
      password1: setPasswordError,
      undefined: setFormError,
    };

    // According to status code, either navigate to next page or set errors
    switch (response.status) {
      case 200: // user successfully created
        await authAPI.signOut(); // user is automatically logged in if no verification issued
        dispatchToast({
          type: "add",
          variant: "success",
          heading: t("account:signup.messages.created.title"),
          subheading: t("account:signup.messages.created.content"),
        });
        navigate(routes.Account.Login);
        break;
      case 400: {
        // Errors in sign-up form
        const data = await response.json();
        const errorsObject = mapAuthErrors(data.errors);
        setErrorsObj(errorsObject);
        Object.entries(errorsObject).forEach((nameErrors) => {
          mapErrorFunctions[nameErrors[0]](nameErrors[1]);
        });
        break;
      }
      case 401: // e-mail confirmation sent
        setConfirmEmail(true);
        break;
      case 409: // user already exists
        dispatchToast({
          type: "add",
          variant: "info",
          heading: t("account:signup.messages.existing.title"),
          subheading: t("account:signup.messages.existing.content"),
        });
        navigate(routes.Account.Login);
        break;
      default:
        console.log(response);
        break;
    }
  }

  const handleRegister = () => {
    setFirstNameError([]);
    setNameError([]);
    setEmailError([]);
    setPhoneError([]);
    setPasswordError([]);
    setFormError([]);

    register({
      first_name: firstName,
      last_name: name,
      email: email,
      telephone: phone,
      password: password,
      is_reseller: reseller.value,
    });
  };

  const isResellerInput = {
    label: t("account:signup.form.reseller.label"),
    type: "select",
    name: "is_reseller",
    options: [
      {
        label: t("account:signup.form.reseller.option.manager"),
        value: false,
      },
      {
        label: t("account:signup.form.reseller.option.reseller"),
        value: true,
      },
    ],
    value: reseller,
    onChange: setReseller,
    required: true,
  };

  const userDataInputs1 = [
    {
      label: t("account:signup.form.firstName"),
      type: "text",
      name: "first_name",
      autoComplete: "given-name",
      value: firstName,
      maxLength: 50,
      onChange: setFirstName,
      errors: firstNameError,
    },
    {
      label: t("account:signup.form.name"),
      type: "text",
      name: "last_name",
      autoComplete: "family-name",
      value: name,
      maxLength: 50,
      onChange: setName,
      errors: nameError,
    },
  ];

  const userDataInputs2 = [
    {
      label: t("account:signup.form.email"),
      type: "email",
      name: "email",
      autoComplete: "email",
      value: email,
      maxLength: 320,
      disabled: autoEmail && email ? true : false,
      onChange: setEmail,
      errors: emailError,
    },
    {
      label: t("account:signup.form.tel"),
      type: "tel",
      value: phone,
      onChange: setPhone,
      errors: phoneError,
    },
  ];

  const passwordInput = {
    label: t("account:signup.form.password"),
    type: "password",
    value: password,
    name: "password",
    autoComplete: "new-password",
    minLength: 8,
    maxLength: 50,
    onChange: setPassword,
    errors: passwordError,
  };

  const conditionsInput = {
    label: (
      <span>
        <Trans
          t={t}
          i18nKey="account:signup.form.conditions"
          components={{
            anchor1: <Link to={routes.TermsOfService} target="_blank" />,
            anchor2: <Link to={routes.PrivacyPolicy} target="_blank" />,
          }}
        />
      </span>
    ),
    type: "checkbox",
    name: "checkbox",
    value: legalConditions,
    onChange: setLegalConditions,
  };

  return (
    <Form
      errors={formError}
      inputErrors={Object.keys(errorsObj) ? true : false}
      grid
      button={t("account:signup.form.button")}
      onSubmit={handleRegister}
    >
      <Inputs grid>
        {userDataInputs1.map((input, index) => (
          <Input key={index} {...input} />
        ))}
      </Inputs>
      <Inputs grid>
        {userDataInputs2.map((input, index) => (
          <Input key={index} {...input} />
        ))}
      </Inputs>

      <Input {...passwordInput} />
      <Input {...isResellerInput} />

      <Input {...conditionsInput} />
    </Form>
  );
}
