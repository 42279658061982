import { useTranslation } from "react-i18next";
import routes, { dashboardRoute } from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { useState, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  AccountBalance,
  ArrowBack,
  BarChart,
  KeyboardArrowRight,
  Lock,
  Settings,
  TableRows,
} from "@mui/icons-material";
import Button from "../commons/Button";
import platformTour from "../../assets/platform/platformTour2.png";

export default function Navigation({ onNavigate = () => null }) {
  const { t } = useTranslation(["common", "settings", "vault", "navigation"]);

  let location = useLocation();
  const { loadingActiveCompany, activeCompany, loadingUser } = useAuth();

  const [selectedTab, setSelectedTab] = useState("");

  const tabs = [
    // Dashboard
    {
      title: t("navigation:tabs.dashboard.title"),
      subheading: t("navigation:tabs.dashboard.subheading"),
      path: dashboardRoute,
      icon: <BarChart />,
      hidden: !activeCompany || activeCompany.is_reseller,
    },
    {
      title: t("navigation:tabs.dashboard.title"),
      subheading: t("navigation:tabs.dashboard.subheadingReseller"),
      path: routes.Reseller.clients,
      icon: <BarChart />,
      hidden: !activeCompany || !activeCompany.is_reseller,
    },
    // end dashboard
    {
      title: t("navigation:tabs.loans.title"),
      subheading: t("navigation:tabs.loans.subheading"),
      path: routes.Financing.loans,
      icon: <TableRows />,
      hidden: !activeCompany || activeCompany.is_reseller,
    },

    {
      title: t("navigation:tabs.vault.title"),
      subheading: t("navigation:tabs.vault.subheading"),
      path: routes.Vault.root,
      icon: <Lock />,
      hidden: !activeCompany,
    },
    {
      title: t("navigation:tabs.treasury.title"),
      subheading: t("navigation:tabs.treasury.subheading"),
      path: routes.Banking.treasury,
      icon: <AccountBalance />,
      hidden:
        !activeCompany ||
        activeCompany.is_reseller ||
        !activeCompany.has_banking,
      isExternalURL: true,
    },
    {
      title: t("navigation:tabs.settings.title"),
      subheading: t("navigation:tabs.settings.subheading"),
      path: routes.Settings.root,
      icon: <Settings />,
    },
  ];

  if (loadingActiveCompany || loadingUser) return <></>;

  return (
    <div className="Navigation_wrapper">
      <div className="Navigation_aside">
        <PlatformTour
          button={
            <Button
              className="NavigationBackButton"
              onClick={() => setSelectedTab("")}
              icon={<ArrowBack style={{ fontSize: "medium" }} />}
              text={t("navigation:backToMainMenu")}
              iconLeft
            />
          }
          full={!selectedTab}
        />
      </div>
      <div className="Navigation_container">
        <nav
          className="Navigation"
          style={
            {
              // gridTemplateColumns: `repeat(${calculateColumns()}, 1fr)`,
            }
          }
        >
          {selectedTab ? (
            <Fragment>
              {tabs
                .filter((tab) => tab.path === selectedTab)[0]
                .children.filter((tabChild) => !tabChild.hidden)
                .map((tabChild, index) => (
                  <Fragment key={index}>
                    <NavigationTabLink
                      location={location}
                      onNavigate={onNavigate}
                      tab={tabChild}
                    />
                  </Fragment>
                ))}
            </Fragment>
          ) : (
            <Fragment>
              {tabs
                .filter((tab) => !tab.hidden)
                .map((tab, index) => (
                  <Fragment key={index}>
                    <NavigationTab
                      onNavigate={onNavigate}
                      tab={tab}
                      active={location.pathname.includes(tab.path)}
                      onExpand={() => setSelectedTab(tab.path)}
                    />
                  </Fragment>
                ))}
            </Fragment>
          )}
        </nav>
      </div>
    </div>
  );
}

function PlatformTour({ full, button }) {
  const { t } = useTranslation(["common", "navigation"]);
  return (
    <div className="PlatformTour">
      {full && (
        <Fragment>
          <img src={platformTour} alt="Platform" />
          <p className="PlatformTour-title">
            {t("navigation:platformTour.title")}
          </p>
          <p className="PlatformTour-subtitle">
            {t("navigation:platformTour.subtitle")}
          </p>
        </Fragment>
      )}
      {full ? (
        <Button
          icon={<KeyboardArrowRight />}
          text={t("navigation:platformTour.button")}
        />
      ) : (
        button
      )}
    </div>
  );
}

// NavigationTab either expands its children or navigates to a path
function NavigationTab({ tab, active, onNavigate, onExpand }) {
  const location = useLocation();

  if (tab.children)
    return (
      <div className="NavigationTab">
        <NavigationTabTitle
          tab={tab}
          active={active}
          onExpand={() => {
            onExpand();
          }}
        />
      </div>
    );

  return (
    // Note: NavLink adds its own active class to anchor based on current path
    <NavLink
      className="NavigationTab"
      onClick={() => onNavigate()}
      state={{ prevPathname: location.pathname }} // state doesn't work when page is reloaded
      to={tab.path}
      reloadDocument={tab.path === routes.Banking.treasury} // fix for external urls
    >
      <NavigationTabTitle tab={tab} />
    </NavLink>
  );
}

function NavigationTabTitle({ tab, active = false, onExpand = null }) {
  return (
    <div
      className={[
        "NavigationTab-title-container",
        "NavigationTab-button",
        active && "active",
      ]
        .filter(Boolean)
        .join(" ")}
      onClick={onExpand}
    >
      <NavigationTabContent
        icon={tab.icon}
        title={tab.title}
        subheading={tab.subheading}
      />
    </div>
  );
}

function NavigationTabLink({ location, tab, onNavigate }) {
  return (
    <Fragment>
      {/* Note: NavLink adds its own active class to anchor based on current path */}
      <NavLink
        onClick={onNavigate}
        state={{ prevPathname: location.pathname }} // state doesn't work when page is reloaded
        className="NavigationTab-link NavigationTab-button"
        to={
          tab.path !== routes.Settings.root ? tab.path : { pathname: tab.path } // fix for external urls
        }
        reloadDocument={tab.path === routes.Banking.treasury} // fix for external urls
      >
        <NavigationTabContent
          icon={tab.icon}
          title={tab.title}
          subheading={tab.subheading}
        />
      </NavLink>
    </Fragment>
  );
}

function NavigationTabContent({ icon, title, subheading }) {
  return (
    <div className="NavigationTabContent">
      <div className="NavigationTabContent-icon">{icon}</div>
      <div className="NavigationTabContent-text">
        <p className="NavigationTabContent-text-title">{title}</p>
        <p className="NavigationTabContent-text-subheading">{subheading}</p>
      </div>
    </div>
  );
}
