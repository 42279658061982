import { Fragment, useState } from "react";
import { Briefcase, ArrowRight } from "react-feather";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  Header,
  Content,
  SubHeading,
  Heading,
  Main,
} from "../commons/Template";
import FancyButtons from "../commons/FancyButtons";
import Form from "../commons/Form";
import api from "../../api/resources";
import routes from "../../routes/routes";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import Address from "../commons/Address";
import { useCountries } from "../../hooks/commons/useCountries";

// Company search, select and correct
export default function CompanyDetails() {
  const { i18n, t } = useTranslation(["common", "company"]);
  document.title = t("company:details.title");
  const dispatchToast = useToastsDispatch();
  const [company, setCompany] = useState(null);
  const [manualFill, setManualFill] = useState(false);
  const [results, setResults] = useState(null);
  const countries = useCountries();

  const handleManualFill = (notify = false, key = "") => {
    setManualFill(true);
    if (notify) {
      dispatchToast({
        type: "add",
        variant: "info",
        heading: t("company:details.search.form.message.title"),
        subheading: t("company:details.search.form.message.content"),
      });
    }
    setCompany({
      name: parseInt(key) ? "" : key,
      code: parseInt(key) ? key : "",
      category: "",
      immatriculation_date: "",
      address: {
        line_1: "",
        line_2: "",
        line_3: "",
        code: "",
        town: "",
        country: null,
      },
      is_ecommerce: false,
    });
  };

  const handleSelectResult = (company) => {
    setCompany({
      ...company,
      // Convert country code into label/value object for select component
      address: {
        ...company.address,
        country: {
          label: countries.getName(
            company.address.country,
            i18n.resolvedLanguage,
          ),
          value: company.address.country,
        },
      },
    });
  };

  const handleSearchSuccess = (newResults) => setResults(newResults);

  const handleCompanyChange = (newValue, targetField, isAddress = false) =>
    isAddress
      ? setCompany((prev) => ({
          ...prev,
          address: {
            ...prev.address,
            [targetField]: newValue,
          },
        }))
      : setCompany((prev) => ({
          ...prev,
          [targetField]: newValue,
        }));

  return (
    <Fragment>
      {company ? (
        <Verification
          company={company}
          onChange={handleCompanyChange}
          manualFill={manualFill}
          onClear={() => {
            setCompany(null);
            setManualFill(false);
          }}
        />
      ) : results ? (
        <Results
          results={results}
          onClear={() => setResults(null)}
          onManualFill={handleManualFill}
          onSelect={handleSelectResult}
        />
      ) : (
        <Search
          onSuccess={handleSearchSuccess}
          onManualFill={handleManualFill}
        />
      )}
    </Fragment>
  );
}

function Search({ onSuccess, onManualFill }) {
  const { t } = useTranslation(["common", "company"]);
  const [term, setTerm] = useState("");
  const [termErrors, setTermErrors] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [previousTerm, setPreviousTerm] = useState("");
  const [searchSuccess, setSearchSuccess] = useState(true);

  const handleSearch = async () => {
    setSearchSuccess(true);
    setTermErrors([]);
    setFormErrors([]);
    try {
      const response = await api.searchCompanies({ key: term });
      if (response.ok) {
        const companies = await response.json();
        if (companies.length === 0) {
          setPreviousTerm(term);
          setSearchSuccess(false);
        } else {
          onSuccess(companies);
        }
      } else {
        onManualFill(true, term);
      }
    } catch (error) {
      console.log(error);
      onManualFill(true, term);
    }
  };

  const termInput = [
    {
      label: t("company:details.search.form.label"),
      type: "text",
      name: "term",
      value: term,
      onChange: setTerm,
      errors: termErrors,
    },
  ];

  return (
    <Main>
      <Header>
        <Heading>
          <Trans
            t={t}
            i18nKey={"company:details.search.heading"}
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
        <SubHeading>
          {searchSuccess ? null : (
            <span>
              <Trans
                t={t}
                i18nKey={"company:details.search.subheading"}
                values={{ searchTerm: previousTerm }}
                components={{
                  anchor: <Link onClick={() => onManualFill(false)} />,
                  emphasis: <strong />,
                }}
              />
            </span>
          )}
        </SubHeading>
      </Header>
      <Content>
        <Form
          resetLoader={!searchSuccess}
          errors={formErrors}
          onSubmit={handleSearch}
          button={t("company:details.search.form.button")}
          inputs={[{ data: termInput }]}
        />
      </Content>
    </Main>
  );
}

function Results({ results, onClear, onManualFill, onSelect }) {
  const { t } = useTranslation(["common", "company"]);

  return (
    <Main>
      <Header>
        <Heading onBackButtonClick={onClear}>
          <Trans
            t={t}
            i18nKey={"company:details.searchResults.heading"}
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
        <SubHeading>
          <span>
            <Trans
              t={t}
              i18nKey={"company:details.searchResults.subheading"}
              components={{
                anchor: <Link onClick={() => onManualFill(false)} />,
              }}
            />
          </span>
        </SubHeading>
      </Header>
      <Content>
        <FancyButtons grid>
          {results.map((searchResult, index) => {
            return (
              <Form
                key={index}
                fancyButton
                heading={searchResult.name}
                subheading={<Address address={searchResult.address} />}
                icon={<Briefcase />}
                iconConf={<ArrowRight />}
                onSubmit={() => onSelect(searchResult)}
              />
            );
          })}
        </FancyButtons>
      </Content>
    </Main>
  );
}

function Verification({ company, onChange, manualFill, onClear }) {
  const { i18n, t } = useTranslation(["common", "company"]);
  const countries = useCountries();
  const dispatchToast = useToastsDispatch();
  const navigate = useNavigate();
  const [errorsObj, setErrorsObj] = useState({});
  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleCreateCompany = async () => {
    setErrorsObj({});

    const response = await api.createCompany({
      name: company.name,
      code: company.code,
      category: company.category,
      immatriculation_date: company.immatriculation_date,
      address: { ...company.address, country: company.address.country.value },
      is_ecommerce: company.is_ecommerce,
      is_commercial: company.is_commercial,
      turnover: company.turnover,
      equity: company.equity,
      gross_operating_profit: company.gross_operating_profit,
      net_income: company.net_income,
    });

    if (response.ok && !manualFill) {
      const { representants } = company;
      for (const rep of representants) {
        await api.createRep(rep);
      }
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("company:details.verifyCompany.form.message.create.title"),
        subheading: t(
          "company:details.verifyCompany.form.message.create.content",
        ),
      });
      navigate(routes.Company.reps);
    } else {
      const errors = await response.json();
      console.log(errors);
      setErrorsObj(errors);
    }
  };

  const companyDetailsInputs1 = [
    {
      label: t("company:details.verifyCompany.form.name"),
      type: "text",
      name: "name",
      value: company.name,
      onChange: (value) => onChange(value, "name"),
      errors: getErrors("name"),
    },
    {
      label: t("company:details.verifyCompany.form.date"),
      type: "date",
      name: "date",
      value: company.immatriculation_date,
      onChange: (value) => onChange(value, "immatriculation_date"),
      disabled: !manualFill && company.immatriculation_date,
      errors: getErrors("immatriculation_date"),
    },
  ];

  const companyDetailsInputs2 = [
    {
      label: t("company:details.verifyCompany.form.code"),
      type: "text",
      name: "code",
      value: company.code,
      onChange: (value) => onChange(value, "code"),
      disabled: !manualFill && company.code,
      errors: getErrors("code"),
    },
    {
      label: t("company:details.verifyCompany.form.category"),
      type: "text",
      name: "category",
      value: company.category,
      onChange: (value) => onChange(value, "category"),
      disabled: !manualFill && company.category,
      errors: getErrors("category"),
    },
  ];

  const companyAddressInputs1 = [
    {
      label: t("company:details.verifyCompany.form.address.title"),
      placeholder: t("company:details.verifyCompany.form.address.line_1"),
      type: "text",
      name: "street",
      value: company.address.line_1,
      onChange: (value) => onChange(value, "line_1", true),
      errors: getErrors("address"),
    },
  ];

  const companyAddressInputs2 = [
    {
      placeholder: t("company:details.verifyCompany.form.address.line_2"),
      required: false,
      type: "text",
      name: "complement",
      value: company.address.line_2,
      onChange: (value) => onChange(value, "line_2", true),
    },
  ];

  const companyAddressInputs3 = [
    {
      placeholder: t("company:details.verifyCompany.form.address.code"),
      type: "text",
      name: "postalCode",
      value: company.address.code,
      onChange: (value) => onChange(value, "code", true),
    },
    {
      placeholder: t("company:details.verifyCompany.form.address.town"),
      type: "text",
      name: "town",
      value: company.address.town,
      onChange: (value) => onChange(value, "town", true),
    },
  ];
  const companyAddressInputs4 = [
    {
      type: "select",
      name: "country",
      value: company.address.country,
      onChange: (value) => onChange(value, "country", true),
      options: Object.entries(
        countries.getNames(i18n.resolvedLanguage, { select: "official" }),
      ).map(([alpha2Code, name]) => {
        return {
          label: name,
          value: alpha2Code,
        };
      }),
      isSearchable: true,
      placeholder: company.address.country
        ? countries.getName(company.address.country, i18n.resolvedLanguage)
        : t("company:details.verifyCompany.form.address.country"),
      disabled: !manualFill && company.address.country,
      required: true,
    },
  ];

  const ecomInput = [
    {
      type: "checkbox",
      name: "is_ecommerce",
      value: company.is_ecommerce,
      onChange: (value) => onChange(value, "is_ecommerce"),
      label: t("company:details.verifyCompany.form.ecom"),
      required: false,
    },
  ];

  return (
    <Main>
      <Header>
        <Heading onBackButtonClick={onClear}>
          <Trans
            t={t}
            i18nKey={"company:details.verifyCompany.heading"}
            components={{
              emphasis: <span />,
            }}
          />
        </Heading>
      </Header>
      <Content>
        <Form
          button={t("company:details.verifyCompany.form.button")}
          onSubmit={handleCreateCompany}
          inputErrors={Object.keys(errorsObj) ? true : false}
          errors={getErrors("non_field_errors")}
          inputs={[
            { data: companyDetailsInputs1, display: "grid" },
            { data: companyDetailsInputs2, display: "grid" },
            { data: companyAddressInputs1 },
            { data: companyAddressInputs2 },
            { data: companyAddressInputs3, display: "grid" },
            { data: companyAddressInputs4 },
            { data: ecomInput },
          ]}
        />
      </Content>
    </Main>
  );
}
